import { VirtualElement } from '@popperjs/core';
import { clamp } from 'lodash';
import React, { useCallback, useState } from 'react';
import { UseTooltipConfig } from './types';
import useTooltip from './useTooltip';

const DEFAULT_CLIENT_RECT = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
} as unknown as ClientRect;

type UseTrackingTooltipConfg = Omit<UseTooltipConfig, 'target'>;

// tooltip that tracks the mouse
export default function useTrackingTooltip(config?: UseTrackingTooltipConfg) {
  const [virtualElement, setVirtualElement] = useState<VirtualElement>({
    getBoundingClientRect: () => DEFAULT_CLIENT_RECT,
  });

  const tooltip = useTooltip({
    target: virtualElement,
    ...config,
  });

  const handleMouseMove = useCallback(
    (
      { clientX }: React.MouseEvent<HTMLElement> | MouseEvent,
      targetRect: DOMRect,
    ) => {
      setVirtualElement({
        getBoundingClientRect() {
          return {
            left: clamp(clientX, targetRect.left, targetRect.right),
            right: clamp(clientX, targetRect.left, targetRect.right),
            bottom: targetRect.bottom,
            top: targetRect.top,
            width: 0,
            height: targetRect.height,
          } as unknown as ClientRect;
        },
      });
    },
    [],
  );

  return {
    ...tooltip,
    onMouseMove: handleMouseMove,
  };
}
