import {
  PODCAST_SERVICE_V1_URL,
  PODCAST_SERVICE_V2_URL,
} from 'utils/constants';

// podcast

export const GET_PODCAST_DETAIL_URL_PATTERN = `${PODCAST_SERVICE_V1_URL}/podcasts/:podcastId`;
export const GET_PODCAST_FEED_DETAIL_URL_PATTERN = `${PODCAST_SERVICE_V1_URL}/podcasts/feed/:podcastFeedId`;
export const GET_PODCAST_EPISODE_DETAIL_URL_PATTERN = `${PODCAST_SERVICE_V1_URL}/podcasts/:podcastId/episode/:episodeId`;
export const GET_FEED_FOLLOW_URL_PATTERN = `${PODCAST_SERVICE_V1_URL}/podcasts/follows/:feedId`;
export const GET_PODCAST_EPISODES_URL_PATTERN = `${PODCAST_SERVICE_V1_URL}/podcasts/:podcastId/episode`;

// ----- episode
export const GET_PODCAST_FEED_EPISODE_DETAIL_URL_PATTERN = `${PODCAST_SERVICE_V1_URL}/episode/:episodeId`;

// ----- rank
export const GET_BEST_PODCASTS_URL_PATTERN = `${PODCAST_SERVICE_V1_URL}/rank`;

// ----- search
export const SEARCH_PODCAST_URL_PATTERN = `${PODCAST_SERVICE_V2_URL}/search`;
export const SEARCH_EPISODE_URL_PATTERN = `${PODCAST_SERVICE_V2_URL}/episode/search`;

// ----- highlighted clips
export const GET_HIGHLIGHT_VIDEO_CLIPS_URL_PATTERN = `${PODCAST_SERVICE_V1_URL}/highlighted-video-clip/podcast/:feedId`;
export const GET_HOMEPAGE_HIGHLIGHT_VIDEO_CLIPS_URL_PATTERN = `${PODCAST_SERVICE_V1_URL}/highlighted-video-clip/play/home`;

// ----- queue
export const LISTENING_QUEUE_URL_PATTERN = `${PODCAST_SERVICE_V1_URL}/disco-play/queue`;

// ----- chapters
export const CHAPTER_ELEMENT_CLASS_NAME = 'inline-chapter-timecode';
export const CHAPTER_ELEMENT_TIME_ATTRIBUTE = 'data-chapter-seconds';
