import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import {
  saveEpisodeListeningProgress,
  deleteEpisodeFromListeningProgress,
} from 'pages/AudioPlayerPage/state/listeningProgress';
import { useCallback, useEffect } from 'react';

export default function usePersistListeningProgress() {
  const { controls, episodeId, events } = useAudioPlayer();
  const handleEpisodeEnded = useCallback(() => {
    if (episodeId) {
      deleteEpisodeFromListeningProgress(episodeId);
    }
  }, [episodeId]);

  useEffect(() => {
    const handleTimeupdate = () => {
      const currentTime = controls?.currentTime();
      if (episodeId && currentTime) {
        saveEpisodeListeningProgress(episodeId, currentTime);
      }
    };

    events.registerEventListener('timeupdate', handleTimeupdate);

    return () => {
      events.unregisterEventListener('timeupdate', handleTimeupdate);
    };
  }, [controls, episodeId, events]);

  useEffect(() => {
    events.registerEventListener('ended', handleEpisodeEnded);

    return () => {
      events.unregisterEventListener('ended', handleEpisodeEnded);
    };
  }, [events, handleEpisodeEnded]);
}
