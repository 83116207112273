import { useBreakpoints } from 'context/BreakpointsContext';
import { MotionProps } from 'framer-motion';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { useEffect, useState } from 'react';
import {
  ANIMATION_DURATION_MEDIUM_SEC,
  STYLE_CONSTANTS,
} from 'utils/constants';

enum AnimationVariant {
  HIDDEN = 'hidden',
  VISIBLE = 'visible',
}

export default function useStickyAudioPlayerAnimation(): MotionProps {
  const { paused } = useAudioPlayer();
  const { md } = useBreakpoints();
  const [played, setPlayed] = useState(!paused);

  useEffect(() => {
    if (!paused && !played) {
      setPlayed(true);
    }
  }, [paused, played]);

  const playerHeight = md
    ? STYLE_CONSTANTS.playerHeightMobile
    : STYLE_CONSTANTS.playerHeightDesktop;

  return {
    animate: played ? AnimationVariant.VISIBLE : AnimationVariant.HIDDEN,
    initial: AnimationVariant.HIDDEN,
    transition: { duration: ANIMATION_DURATION_MEDIUM_SEC },
    variants: {
      [AnimationVariant.HIDDEN]: { y: playerHeight },
      [AnimationVariant.VISIBLE]: { y: 0 },
    },
  };
}
