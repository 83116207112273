import { useIsBlockedEpisode } from 'api/hooks/useGetPodcastEpisodeDetail';
import { useEventTracking } from 'context/EventTrackingContext';
import { BlockedEpisodeModal, useModalControls } from 'context/ModalContext';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from 'utils/routes';

interface UseBlockedEpisodeConfig
  extends Partial<
    Pick<BlockedEpisodeModal['params'], 'podcastId' | 'episodeId'>
  > {
  onModalShown?: () => void;
  showModal?: boolean;
}

export default function useBlockedEpisode(props: UseBlockedEpisodeConfig) {
  const { podcastId, episodeId, onModalShown } = props;

  const { trackEpisodePageBlockedStateCTA } = useEventTracking();

  const { data: isBlocked } = useIsBlockedEpisode({ podcastId, episodeId });

  const blockedEpisodeModalShown = useRef<boolean | undefined>(undefined);

  const navigate = useNavigate();

  const { open } = useModalControls();

  const handleClose = useCallback(() => {
    navigate(routes.home);
    trackEpisodePageBlockedStateCTA();
  }, [navigate, trackEpisodePageBlockedStateCTA]);

  useEffect(() => {
    if (
      isBlocked &&
      podcastId &&
      episodeId &&
      !blockedEpisodeModalShown.current
    ) {
      onModalShown?.();
      blockedEpisodeModalShown.current = true;
      open({
        name: 'BlockedEpisode',
        params: {
          podcastId,
          episodeId,
        },
        onAfterClose: handleClose,
      });
    }
  }, [episodeId, podcastId, open, handleClose, isBlocked, onModalShown]);
}
