import cn from 'classnames';
import React from 'react';
import { OverridableComponentProps } from 'types/react';
import styles from './Page.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  fluid?: boolean;
}

export type PageProps<As extends React.ElementType = 'div'> =
  OverridableComponentProps<Props, As>;

function Page<As extends React.ElementType = 'div'>(props: PageProps<As>) {
  const { as, children, className, fluid, ...componentProps } = props;

  const Component = as ?? 'div';

  return (
    <Component
      className={cn(
        styles.root,
        {
          [styles.root__fluid]: fluid,
        },
        className,
      )}
      {...componentProps}
    >
      {children}
    </Component>
  );
}

export default Page;
