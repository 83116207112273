import { useEventTracking } from 'context/EventTrackingContext';
import { useEffect } from 'react';

export function useHomePage() {
  const { trackHomePageLoaded } = useEventTracking();

  useEffect(() => {
    trackHomePageLoaded();
  }, [trackHomePageLoaded]);
}
