import { useHighlightClipScroller } from 'components/HighlightClipScroller/HighlightClipScrollerContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSwiperSlide } from 'swiper/react';
import { VolumeButtonProps } from './VolumeButton';

const BUTTON_DISPLAY_DURATION_MILLIS = 500;

interface UseVolumeButtonVisibilityConfig {
  videoHovered: boolean;
}

type UseVolumeButtonVisibilityResult = Pick<
  VolumeButtonProps,
  'onAnimationComplete' | 'state' | 'visible'
>;

export default function useVolumeButtonVisibility({
  videoHovered,
}: UseVolumeButtonVisibilityConfig): UseVolumeButtonVisibilityResult {
  const [visible, setVisible] = useState(false);
  const { isActive } = useSwiperSlide();
  const { volume } = useHighlightClipScroller();
  const buttonState = volume === 0 ? 'muted' : 'unmuted';
  const [prevState, setPrevState] = useState(buttonState);
  const [prevIsActive, setPrevIsActive] = useState(isActive);
  const timerIdRef = useRef<number>();

  const scheduleHide = useCallback(() => {
    window.clearTimeout(timerIdRef.current);

    timerIdRef.current = window.setTimeout(() => {
      setVisible(false);
    }, BUTTON_DISPLAY_DURATION_MILLIS);
  }, []);

  // slide is entering or leaving viewport
  if (isActive !== prevIsActive) {
    setPrevIsActive(isActive);

    if (isActive) {
      // spec states that button should only be shown when a slide becomes active
      // if the audio is muted. if not muted, set `visible` to false to ensure
      // that it won't show
      if (volume === 0) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }
  // slide is active but action changed from mute <> unmute.  this is the same
  // as a muted slide entering the viewport - show the button
  else if (buttonState !== prevState) {
    setPrevState(buttonState);

    if (isActive) {
      setVisible(true);
      scheduleHide();
    }
  }

  const onAnimationComplete = useCallback(() => {
    if (visible) {
      scheduleHide();
    }
  }, [scheduleHide, visible]);

  useEffect(
    () => () => {
      window.clearTimeout(timerIdRef.current);
    },
    [],
  );

  return {
    visible: videoHovered || visible,
    onAnimationComplete,
    state: buttonState,
  };
}
