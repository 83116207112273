import { MotionProps, Transition } from 'framer-motion';

export enum HeaderState {
  EXPANDED = 'expanded',
  CONDENSED = 'condensed',
}

type AnimatableElements =
  | 'artwork'
  | 'subtitle'
  | 'header'
  | 'logo'
  | 'footer'
  | 'title'
  | 'topLeft'
  | 'topCenter'
  | 'topRight';
export interface AnimationProps
  extends Pick<MotionProps, 'animate' | 'initial' | 'variants'> {
  style?: React.CSSProperties;
  key?: string;
}

export interface AppHeaderAnimationContextType
  extends Record<AnimatableElements, AnimationProps> {
  activeVariant: HeaderState | undefined;
}

export interface AppHeaderAnimationProviderProps {
  children?: React.ReactNode;
}

export interface TransitionConfig {
  in: Record<string, Transition>;
  out: Record<string, Transition>;
}
