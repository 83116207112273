import {
  GetPodcastEpisodeDetailResponse,
  getPodcastEpisodeDetails,
} from 'api/podcast';
import {
  titlesSelector,
  isEpisodeBlockedSelector,
  publishedAtMillisSelector,
  episodeArtSelector,
} from 'api/selectors/podcast';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'types';
import { episodeQueryKeys } from './queries';

export type Options<TData = GetPodcastEpisodeDetailResponse> = UseQueryOptions<
  GetPodcastEpisodeDetailResponse,
  ReturnType<typeof episodeQueryKeys.details>,
  AxiosError | Error,
  TData
>;

export type UseGetPodcastEpisodeDetailConfig = {
  episodeId: string | undefined;
  podcastId: string | undefined;
};

export default function useGetPodcastEpisodeDetail<
  TData = GetPodcastEpisodeDetailResponse,
>(
  { episodeId, podcastId }: UseGetPodcastEpisodeDetailConfig,
  opts?: Options<TData>,
) {
  return useQuery(
    episodeQueryKeys.details(episodeId, podcastId),
    ({ queryKey: [key] }) =>
      getPodcastEpisodeDetails(
        key.episodeId as string,
        key.podcastId as string,
        {
          includeChapters: true,
          includeDescription: true,
        },
      ),
    {
      ...opts,
      enabled: (opts?.enabled ?? true) && !!podcastId && !!episodeId,
    },
  );
}

export function useGetTitles(props: UseGetPodcastEpisodeDetailConfig) {
  return useGetPodcastEpisodeDetail(props, { select: titlesSelector });
}

export function useIsBlockedEpisode(props: UseGetPodcastEpisodeDetailConfig) {
  return useGetPodcastEpisodeDetail(props, {
    select: isEpisodeBlockedSelector,
  });
}

export function useGetEpisodePublishedAtMillis(
  props: UseGetPodcastEpisodeDetailConfig,
) {
  return useGetPodcastEpisodeDetail(props, {
    select: publishedAtMillisSelector,
  });
}

export function useGetEpisodeArt(props: UseGetPodcastEpisodeDetailConfig) {
  return useGetPodcastEpisodeDetail(props, { select: episodeArtSelector });
}
