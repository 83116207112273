import { isExpired, UserAuthResult } from '@sparemin/auth';
import { authenticatedQueryKey } from 'api/hooks/queries';
import { useEventTracking } from 'context/EventTrackingContext';
import { useModalControls } from 'context/ModalContext';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { AuthContextType, AuthProviderProps, AuthState } from './types';
import {
  createAuthState,
  deleteToken,
  persistToken,
  rehydrateToken,
} from './utils';

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const { clearUserTrackingInfo } = useEventTracking();
  const [state, setState] = useState<AuthState | undefined>(() => {
    const storedToken = rehydrateToken();

    if (!storedToken) {
      return undefined;
    }

    if (isExpired(storedToken)) {
      deleteToken();
      return undefined;
    }

    return createAuthState(storedToken);
  });

  const [onAuthSuccess, setOnAuthSuccess] = useState<() => void>();

  const { open } = useModalControls();
  const handleReplaceToken = useCallback((newToken: string) => {
    setState(createAuthState(newToken));
    persistToken(newToken);
  }, []);

  const login = useCallback(
    (data: UserAuthResult) => {
      setState(createAuthState(data.spareminToken, data.userId));
      queryClient.invalidateQueries();
      persistToken(data.spareminToken);
    },
    [queryClient],
  );

  const handleLogout = useCallback(() => {
    setState(undefined);
    deleteToken();
    open({ name: 'Auth' });

    // on logout clear all queries associated with the authenticated user
    queryClient.invalidateQueries({ queryKey: [authenticatedQueryKey] });
    clearUserTrackingInfo();
  }, [clearUserTrackingInfo, queryClient, open]);

  return (
    <AuthContext.Provider
      value={useMemo(
        () => ({
          login,
          accessToken: state?.accessToken,
          isAuthenticated: state !== undefined,
          logout: handleLogout,
          userId: state?.userId,
          replaceToken: handleReplaceToken,
          onAuthSuccess,
          setOnAuthSuccess,
        }),
        [handleLogout, login, state, onAuthSuccess, handleReplaceToken],
      )}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}
