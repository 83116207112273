import { Heading } from '@sparemin/blockhead';
import LinkCard from 'components/LinkCard';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { getTextContentFromHtmlString } from 'utils/dom';

import styles from './EpisodeCardBody.module.scss';

interface EpisodeCardBodyProps {
  descriptionHtml?: string;
  isLoading?: boolean;
}

const EpisodeCardBody: React.FunctionComponent<EpisodeCardBodyProps> = (
  props,
) => {
  const { descriptionHtml, isLoading } = props;

  const description = React.useMemo(
    () => getTextContentFromHtmlString(descriptionHtml),
    [descriptionHtml],
  );

  return (
    <LinkCard.Body>
      <Heading className={styles.description} level={2} letterSpacing={0.5}>
        {isLoading ? <Skeleton count={2} /> : description}
      </Heading>
    </LinkCard.Body>
  );
};

export default EpisodeCardBody;
