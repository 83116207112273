import { useCallback, useState } from 'react';

export interface UseToggleResult {
  value: boolean;
  toggle: (nextValue?: boolean) => void;
  toggleOn: () => void;
  toggleOff: () => void;
}

export default function useToggle(
  defaultValue: boolean = false,
): UseToggleResult {
  const [value, setValue] = useState(defaultValue);

  const toggle = useCallback((nextValue?: boolean) => {
    setValue((currentValue) =>
      typeof nextValue === 'boolean' ? nextValue : !currentValue,
    );
  }, []);

  const toggleOn = useCallback(() => toggle(true), [toggle]);
  const toggleOff = useCallback(() => toggle(false), [toggle]);

  return {
    value,
    toggle,
    toggleOff,
    toggleOn,
  };
}
