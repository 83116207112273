export interface BaseAudioPlayer {
  duration: number;
  paused: boolean;
  ready: boolean;
  playbackRate: number;

  currentTime(): number;
  off<T extends keyof HTMLMediaElementEventMap>(
    type: T,
    listener: (this: HTMLMediaElement, ev: HTMLMediaElementEventMap[T]) => any,
    opts?: boolean | AddEventListenerOptions,
  ): void;
  on<T extends keyof HTMLMediaElementEventMap>(
    type: T,
    listener: (this: HTMLMediaElement, ev: HTMLMediaElementEventMap[T]) => any,
    opts?: boolean | AddEventListenerOptions,
  ): void;
  pause(): void;
  play(): Promise<void>;
  seek(seconds: number): void;
  togglePlay: (fromSec?: number) => Promise<void>;
  setPlaybackRate: (rate: number) => void;
}

export enum ReadyState {
  HAVE_NOTHING = 0,
  HAVE_METADATA = 1,
  HAVE_CURRENT_DATA = 2,
  HAVE_FUTURE_DATA = 3,
  HAVE_ENOUGH_DATA = 4,
}

export interface MediaElementAudioPlayerOptions {
  startTime?: number;
  playbackRate?: number;
}
