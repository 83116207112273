import useGetPodcastEpisodeDetail, {
  UseGetPodcastEpisodeDetailConfig,
} from 'api/hooks/useGetPodcastEpisodeDetail';
import { playerDetailsSelector, podcastArtworkSelector } from './selectors';

export function useGetPlayerDetails(props: UseGetPodcastEpisodeDetailConfig) {
  return useGetPodcastEpisodeDetail(props, { select: playerDetailsSelector });
}

export function useGetPlayerArtwork(props: UseGetPodcastEpisodeDetailConfig) {
  return useGetPodcastEpisodeDetail(props, { select: podcastArtworkSelector });
}
