import {
  AspectRatio,
  BlurredVideoBackground,
  ButtonBase,
} from '@sparemin/blockhead';
import { HighlightVideoClip } from 'api/podcast';
import cn from 'classnames';
import useSwiperSlideClick from 'components/HighlightClipScroller/hooks/useSwiperSlideClick';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useEventTracking } from 'context/EventTrackingContext';
import { HighlightClipSource } from 'context/EventTrackingContext/types';
import { useCallback, useState } from 'react';

import { useResizeDetector } from 'react-resize-detector';
import { useSwiperSlide } from 'swiper/react';
import { useHighlightClipScroller } from '../../HighlightClipScrollerContext';
import styles from './HighlightClipPlayer.module.scss';
import useVolumeButtonVisibility from './useVolumeButtonVisibility';
import VolumeButton from './VolumeButton';

const DESKTOP_FRAME_RATIO = 1;
const MOBILE_FRAME_RATIO = 9 / 16;

export interface HighlightClipPlayerProps {
  className?: string;
  clip?: HighlightVideoClip;
  source: HighlightClipSource;
}

const HighlightClipPlayer: React.FC<HighlightClipPlayerProps> = ({
  className,
  clip,
  source,
}) => {
  const { md } = useBreakpoints();
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const [hovered, setHovered] = useState(false);
  const {
    ref: frameRef,
    height: frameHeight,
    width: frameWidth,
  } = useResizeDetector();
  const { isActive } = useSwiperSlide();
  const { toggleMute, volume } = useHighlightClipScroller();
  const [prevIsActive, setPrevIsActive] = useState(isActive);
  const { trackToggleClipMute } = useEventTracking();
  const buttonProps = useVolumeButtonVisibility({ videoHovered: hovered });

  const handleToggleMute = useCallback(() => {
    const newVolume = toggleMute();

    if (clip) {
      trackToggleClipMute(newVolume, clip, source);
    }
  }, [clip, toggleMute, trackToggleClipMute, source]);

  const handleHoverChange = useCallback((isHovered) => {
    setHovered(isHovered);
  }, []);

  const swiperSlideTargetElementProps = useSwiperSlideClick({
    onPress: handleToggleMute,
  });

  if (isActive !== prevIsActive) {
    setPrevIsActive(isActive);

    if (isActive) {
      video?.play();
    } else {
      video?.pause();
    }
  }

  const clipRatio = !clip
    ? undefined
    : clip.video.frameWidth / clip.video.frameHeight;

  const frameRatio = md ? MOBILE_FRAME_RATIO : DESKTOP_FRAME_RATIO;

  const canRenderBlurredBackground =
    frameHeight !== undefined && frameWidth !== undefined;

  const shouldRenderBlurredBackground =
    clipRatio !== undefined && clipRatio !== frameRatio;

  const renderBlurredBackground =
    shouldRenderBlurredBackground && canRenderBlurredBackground;

  return (
    <ButtonBase
      as="div"
      className={cn(styles.player, className)}
      onHoverChange={handleHoverChange}
      ref={frameRef}
      tabIndex={isActive ? 0 : -1}
      {...swiperSlideTargetElementProps}
    >
      <AspectRatio
        contentsClassName={styles.inner}
        basis="height"
        ratio={frameRatio}
      >
        {renderBlurredBackground && (
          <BlurredVideoBackground
            height={frameHeight}
            video={video}
            width={frameWidth}
          />
        )}
        <video
          className={styles.video}
          loop
          muted={volume === 0}
          playsInline
          ref={setVideo}
          src={clip?.video.videoUrl}
        />
      </AspectRatio>
      <VolumeButton onPress={handleToggleMute} {...buttonProps} />
    </ButtonBase>
  );
};

export default HighlightClipPlayer;
