import { Space } from './types';

export function getSpaceMultiplier(space: Space): number | string {
  if (typeof space === 'number') {
    return space;
  }

  switch (space) {
    case 'small':
      return 1;

    case 'medium':
      return 2;

    case 'large':
      return 3;

    default:
      return space;
  }
}

export function getSpaceCssVars(multiplier: string | number): {
  [key: string]: string | number;
} {
  if (typeof multiplier === 'number') {
    return {
      '--space-multiplier': multiplier,
      '--space-value': 'initial',
    };
  }

  if (typeof multiplier === 'string') {
    return {
      '--space-multipler': 'initial',
      '--space-value': multiplier,
    };
  }

  return {};
}
