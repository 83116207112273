import { AnimatedRouteContainer } from 'components/AnimatedRoute';
import { PageContents } from 'components/Page';
import React from 'react';
import styles from './EpisodePage.module.scss';
import EpisodePageBody from './EpisodePageBody';
import { EpisodePageProvider } from './EpisodePageContext';
import EpisodePageMetadata from './EpisodePageMetadata';
import Header from './Header';
import useEpisodePage from './useEpisodePage';

type EpisodePageProps = {
  episodeId: string;
  podcastId: string;
  startSec?: number;
  internal: boolean;
  podcastName: string;
  episodeName: string;
  hasChapters?: boolean;
  handleInteractionModal?: (val: boolean) => void;
  source?: 'queue';
  sourceWidgetId?: string;
};

const EpisodePage: React.FC<EpisodePageProps> = (props) => {
  const {
    episodeId,
    podcastId,
    startSec,
    internal,
    hasChapters,
    podcastName,
    episodeName,
    handleInteractionModal,
    source,
    sourceWidgetId,
  } = props;

  useEpisodePage({
    episodeId,
    podcastId,
    startSec,
    internal,
    hasChapters,
    podcastName,
    episodeName,
    handleInteractionModal,
    source,
    sourceWidgetId,
  });

  return (
    <PageContents className={styles.root} as={AnimatedRouteContainer}>
      <EpisodePageProvider
        episodeId={episodeId}
        hasChapters={hasChapters}
        podcastId={podcastId}
        source={source}
      >
        <EpisodePageMetadata />
        <Header />
        <EpisodePageBody />
      </EpisodePageProvider>
    </PageContents>
  );
};
export default EpisodePage;
