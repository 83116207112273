import { MotionProps } from 'framer-motion';
import useListeningQueue from 'pages/AudioPlayerPage/state/ListeningQueueContext';
import { ANIMATION_DURATION_MEDIUM_SEC } from 'utils/constants';

export interface UseListeningQueueAnimationConfig {}

export default function useListeningQueueAnimation(): MotionProps {
  const { show } = useListeningQueue();

  const open = { x: 0 };
  const closed = { x: '100%' };

  return {
    animate: !show ? closed : open,
    initial: closed,
    exit: closed,
    transition: { duration: ANIMATION_DURATION_MEDIUM_SEC },
  };
}
