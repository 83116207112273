import 'swiper/css';
import 'swiper/css/keyboard';
import 'swiper/css/mousewheel';
import 'swiper/css/navigation';
import 'swiper/css/virtual';

import { useBreakpoints } from 'context/BreakpointsContext';
import { useEventTracking } from 'context/EventTrackingContext';
import { HighlightClipSource } from 'context/EventTrackingContext/types';
import { useCallback } from 'react';
import {
  Keyboard,
  Mousewheel,
  Navigation,
  SwiperOptions,
  Virtual,
} from 'swiper';
import { Swiper } from 'swiper/react';
import HighlightClipScrollerControls, {
  NEXT_BUTTON_CLASS_NAME,
  PREV_BUTTON_CLASS_NAME,
} from './HighlightClipScrollerControls';

export interface HighlightClipScrollerProps extends SwiperOptions {
  initialSlideIndex?: number;
  className?: string;
  children: React.ReactNode;
  source: HighlightClipSource;
  onSlideChange?: (activeIndex: number) => void;
}

const HighlightClipScroller: React.FC<HighlightClipScrollerProps> = ({
  initialSlideIndex = 0,
  children,
  source,
  className,
  onSlideChange,
  ...swiperProps
}) => {
  const { md } = useBreakpoints();
  const { trackChangeClip } = useEventTracking();

  const handleNext = useCallback(() => {
    trackChangeClip('Next', source);
  }, [trackChangeClip, source]);

  const handlePrev = useCallback(() => {
    trackChangeClip('Previous', source);
  }, [trackChangeClip, source]);

  return (
    <>
      {!md && <HighlightClipScrollerControls />}
      <Swiper
        // don't allow touch move on desktop. users will probably not prefer interacting
        // with the component in that way as it's primarily a mobile UX.  preventing on
        // desktop allows for highlighting text and more easily clicking slide elements
        allowTouchMove={md}
        autoHeight
        centeredSlides
        className={className}
        direction="vertical"
        initialSlide={initialSlideIndex}
        keyboard
        modules={[Keyboard, Mousewheel, Navigation, Virtual]}
        mousewheel={{
          forceToAxis: true,
        }}
        navigation={
          md
            ? false
            : {
                nextEl: `.${NEXT_BUTTON_CLASS_NAME}`,
                prevEl: `.${PREV_BUTTON_CLASS_NAME}`,
              }
        }
        onSlideNextTransitionStart={handleNext}
        onSlidePrevTransitionStart={handlePrev}
        // designs have 110px overflow for a slide that is 1000px tall
        // we need 110px + 110px = 220px overflow.
        // 220px / 1000px = .22, so the slidesPerView should be 1.22
        slidesPerView={md ? 1.31 : 1.22}
        slideToClickedSlide
        spaceBetween={20}
        virtual={!md}
        onSlideChange={(swiper) => onSlideChange?.(swiper.activeIndex)}
        {...swiperProps}
      >
        {children}
      </Swiper>
    </>
  );
};

export default HighlightClipScroller;
