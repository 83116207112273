import cn from 'classnames';
import BlurredBackgroundImage, {
  BlurredBackgroundImageProps,
} from 'components/BlurredBackgroundImage';
import styles from './BackgroundImage.module.scss';

export type BackgroundImageProps = BlurredBackgroundImageProps;

const BackgroundImage: React.FC<BackgroundImageProps> = ({
  className,
  src,
  ...props
}) =>
  !src ? null : (
    <BlurredBackgroundImage
      className={cn(styles.root, className)}
      src={src}
      {...props}
    />
  );

export default BackgroundImage;
