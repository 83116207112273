import { useParams } from 'react-router-dom';

interface UsePodcastPageLocationResult {
  podcastId: string;
}

export default function usePodcastPageLocation(): UsePodcastPageLocationResult {
  const { podcastId = '' } = useParams();

  return {
    podcastId,
  };
}
