import cn from 'classnames';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Chapter } from 'types';
import styles from './ProgressBar.module.scss';
import Slider from './Slider';
import { formatTooltip, resolveProgressValue } from './utils';

export interface ProgressBarProps {
  className?: string;
  chapters?: Chapter[];
  defaultDuration?: number;
  handleClassName?: string;
  onAdjustPlayback?: () => void;
  onAfterAdjustPlayback?: () => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  chapters,
  className,
  handleClassName,
  onAdjustPlayback,
  onAfterAdjustPlayback,
}) => {
  const { controls, duration, events, ready } = useAudioPlayer();
  const [, setTick] = useState(0);
  const [seekDraftTime, setSeekDraftTime] = useState<number | undefined>(
    undefined,
  );

  const segments = useMemo(() => {
    if (!chapters) {
      return undefined;
    }

    return chapters.map((chapter) => ({
      id: chapter.id,
      startValue: chapter.startSec,
      headline: chapter.headline,
    }));
  }, [chapters]);

  useEffect(() => {
    const handleTimeupdate = () => setTick((t) => t + 1);
    events.registerEventListener('timeupdate', handleTimeupdate);

    return () => {
      events.unregisterEventListener('timeupdate', handleTimeupdate);
    };
  }, [events]);

  const handleAfterChange = useCallback(
    (value) => {
      onAfterAdjustPlayback?.();

      if (seekDraftTime) {
        controls?.seek(seekDraftTime);
        setSeekDraftTime(undefined);
      } else {
        controls?.seek(value);
      }
    },
    [controls, seekDraftTime, onAfterAdjustPlayback],
  );

  return (
    <Slider
      className={cn(className, styles.root)}
      thumbClassName={handleClassName}
      disabled={!ready}
      enableTooltip={!!duration}
      formatTooltip={formatTooltip}
      label="audio playback progress"
      max={duration || 0}
      min={0}
      onAfterChange={handleAfterChange}
      onChange={setSeekDraftTime}
      segments={segments}
      value={resolveProgressValue(seekDraftTime, controls?.currentTime())}
      onBeforeChange={onAdjustPlayback}
    />
  );
};

export default ProgressBar;
