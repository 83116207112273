import { QueueEpisode } from 'pages/AudioPlayerPage/state';
import useListeningQueue from 'pages/AudioPlayerPage/state/ListeningQueueContext';
import { useCallback, useEffect, useRef } from 'react';

type UseEpisodeLoadedFromQueueConfig = {
  episodeId: string | undefined;
  podcastId: string | undefined;
  source?: 'queue';
};

export default function useSponsoredEpisodeFromQueue(
  props: UseEpisodeLoadedFromQueueConfig,
) {
  const { episodeId, podcastId, source } = props;

  const { queue } = useListeningQueue();

  // The queue might change between page load and the user clicking play, so
  // store the episode on page load with a ref.
  const queueRef = useRef(queue);

  const getSponsoredEpisode = useCallback(() => {
    if (source === 'queue') {
      return queue?.find(
        (episode) =>
          episode.remoteEpisodeId === episodeId &&
          episode.podcastId === podcastId &&
          episode.isSponsored,
      );
    }
    return undefined;
  }, [episodeId, podcastId, queue, source]);

  const sponsoredEpisodeRef = useRef<QueueEpisode | undefined>(
    getSponsoredEpisode(),
  );

  useEffect(() => {
    if (queueRef.current === undefined && queue) {
      queueRef.current = queue;
      sponsoredEpisodeRef.current = getSponsoredEpisode();
    }
  }, [getSponsoredEpisode, queue]);

  return {
    isSponsored: !!sponsoredEpisodeRef.current?.isSponsored,
    widgetInfo: sponsoredEpisodeRef.current?.widgetInfo,
  };
}
