import HighlightClip from './HighlightClip';
import HighlightClipScroller from './HighlightClipScroller';
import {
  HighlightClipScrollerProvider,
  useHighlightClipScroller,
} from './HighlightClipScrollerContext';
import HighlightClipScrollerControls, {
  NEXT_BUTTON_CLASS_NAME,
  PREV_BUTTON_CLASS_NAME,
} from './HighlightClipScrollerControls';

export default HighlightClipScroller;
export {
  HighlightClip,
  HighlightClipScrollerProvider,
  HighlightClipScrollerControls,
  NEXT_BUTTON_CLASS_NAME,
  PREV_BUTTON_CLASS_NAME,
  useHighlightClipScroller,
};
