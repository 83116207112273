import cn from 'classnames';
import React from 'react';
import styles from './VisuallyHidden.module.scss';

// given an intrinsic element, returns its type.  for example,
// GetElement<'span'> === HTMLSpanElement
type GetElement<E> = E extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[E] extends React.HTMLAttributes<infer U>
    ? U
    : never
  : never;

// given an intrinsic element, returns its props. for example,
// GetElementProps<'audio'> === AudioHTMLAttributes<HTMLAudioElement>
type GetElementProps<E> = E extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[E] extends React.DetailedHTMLProps<infer U, any>
    ? U
    : never
  : never;

export type VisuallyHiddenProps<
  E extends keyof JSX.IntrinsicElements = 'span',
> = GetElementProps<E> & {
  as?: E;
};

function VisuallyHiddenComponent<E extends keyof JSX.IntrinsicElements>(
  props: VisuallyHiddenProps<E>,
  ref: React.ForwardedRef<GetElement<E>>,
) {
  const { as: asComponent } = props;
  const Element: string = asComponent ?? 'span';
  const { className } = props;

  return (
    <Element
      {...props}
      className={cn(styles.visuallyHidden, className)}
      ref={ref}
    />
  );
}

const VisuallyHidden = React.forwardRef(VisuallyHiddenComponent);

export default VisuallyHidden;
