import {
  ANIMATION_DURATION_LONG_SEC,
  ANIMATION_DURATION_MEDIUM_SEC,
  ANIMATION_DURATION_SHORT_SEC,
} from 'utils/constants';
import { TransitionDescription } from './types';

const HOVER_ANIMATION_DURATION_SEC = 0.5;

export const HOVER_TRANSITIONS: TransitionDescription = {
  in: {
    layer1: {
      duration: HOVER_ANIMATION_DURATION_SEC,
    },
  },
  out: {
    layer1: {
      duration: HOVER_ANIMATION_DURATION_SEC,
    },
  },
};

export const EXPAND_TRANSITIONS: TransitionDescription = {
  in: {
    layer1: {
      delay: ANIMATION_DURATION_MEDIUM_SEC,
      duration: ANIMATION_DURATION_LONG_SEC,
    },
    layer2: {
      delay: ANIMATION_DURATION_LONG_SEC,
      duration: ANIMATION_DURATION_LONG_SEC,
    },
  },
  out: {
    layer1: {
      delay: ANIMATION_DURATION_SHORT_SEC,
      duration: ANIMATION_DURATION_SHORT_SEC,
    },
    layer2: {
      duration: ANIMATION_DURATION_SHORT_SEC,
    },
  },
};
