import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import useListeningQueue from 'pages/AudioPlayerPage/state/ListeningQueueContext';
import useNowPlayingCard from 'pages/AudioPlayerPage/state/NowPlayingCardContext';
import React, { useCallback } from 'react';
import styles from './Contents.module.scss';
import QueueContentsSkeleton from './QueueContentsSkeleton';
import QueueEpisodeCard from './QueueEpisodeCard';

type ContentsProps = {
  className?: string;
};

const Contents: React.FC<ContentsProps> = ({ className }) => {
  const { hide: hideNowPlayingCard, show: nowPlayingCard } =
    useNowPlayingCard();

  const { hide: hideListeningQueue, queue } = useListeningQueue();

  const handleClick = useCallback(() => {
    if (nowPlayingCard) {
      hideNowPlayingCard();
    }
    hideListeningQueue();
  }, [nowPlayingCard, hideNowPlayingCard, hideListeningQueue]);

  if (!queue) {
    return <QueueContentsSkeleton />;
  }

  return (
    <Spacer
      orientation="vertical"
      space="24px"
      className={cn(styles.root, className)}
    >
      {queue?.map((result) => (
        <QueueEpisodeCard
          key={result.remoteEpisodeId}
          episode={result}
          onClick={handleClick}
        />
      ))}
    </Spacer>
  );
};
export default Contents;
