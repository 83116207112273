import { useAuth } from 'context/AuthContext';
import { useEffect } from 'react';
import { configure } from '../http';

export interface AxiosGlobalConfigurationProps {}

// sets up the global configuration for the axios instance used by the app.  some
// configuration is set when the axios instance is created, but other parts need to
// be connected to react state.  this component mainly deals with checking the
// authorization token - renewing it when it's about to expire and logging users out
// if it is expired.
//
// this is a component as opposed to just a hook so that it can be used directly in
// the root App component without having to wrap it in a component in order to nest it
// within the AuthProvider
const AxiosGlobalConfiguration: React.FC<
  AxiosGlobalConfigurationProps
> = () => {
  const { logout, replaceToken } = useAuth();

  useEffect(() => {
    configure({
      logout,
      onTokenRenewed: replaceToken,
    });
  }, [logout, replaceToken]);

  return null;
};

export default AxiosGlobalConfiguration;
