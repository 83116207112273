import useGetPodcastEpisodeDetailFromFeed from 'api/hooks/useGetPodcastEpisodeDetailFromFeed';
import { is404 } from 'api/utils';
import useLegacyPageLocation from 'hooks/useLegacyPageLocation';
import { isUndefined, isFinite } from 'lodash';
import { useRoutingError } from 'pages/RoutingErrorPage';
import { useEffect } from 'react';
import { PageLocationOptions } from 'types';

type UseEpisodePageLegacyResult = PageLocationOptions & {
  episodeId: string | undefined;
  podcastId: string | undefined;
};

export default function useEpisodeLegacyPage(): UseEpisodePageLegacyResult {
  const { setRoutingError } = useRoutingError();
  const {
    id: feedEpisodeId,
    search,
    state,
  } = useLegacyPageLocation({
    pageType: 'episode',
  });

  const { data } = useGetPodcastEpisodeDetailFromFeed(feedEpisodeId, {
    onError: (err) => {
      if (is404(err)) {
        setRoutingError(404);
      }
    },
  });

  useEffect(() => {
    if (isUndefined(feedEpisodeId) || !isFinite(feedEpisodeId)) {
      setRoutingError(404);
    }
  }, [feedEpisodeId, setRoutingError]);

  const episodeId = data?.remoteEpisodeId;
  const podcastId = data?.podcast.remotePodcastId;

  return { episodeId, podcastId, search, state };
}
