import usePreviousRef from 'hooks/usePreviousRef';
import { Dispatch, useEffect, useReducer } from 'react';
import { MediaElementAudioPlayer } from 'utils/audio';
import reducer, { UseEpisodeAudioPlayerState, INITIAL_STATE } from './reducer';
import { UseEpisodeAudioPlayerAction } from './types';

interface UseEpisodeAudioPlayerStateConfig {
  audioElement: HTMLAudioElement | null;
}
export default function useEpisodeAudioPlayerState(
  config: UseEpisodeAudioPlayerStateConfig,
): [UseEpisodeAudioPlayerState, Dispatch<UseEpisodeAudioPlayerAction>] {
  const { audioElement } = config;

  const previousAudioEl = usePreviousRef<HTMLAudioElement | undefined>(
    undefined,
  );

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const { playerIds, playbackStartTime, playbackRate } = state;

  useEffect(() => {
    const hasNewAudioElement =
      audioElement && audioElement !== previousAudioEl.current;

    if (hasNewAudioElement && audioElement.src && playerIds?.episodeId) {
      const newAudioPlayer = new MediaElementAudioPlayer(audioElement, {
        startTime: playbackStartTime,
        playbackRate,
      });

      dispatch({
        type: 'loadPlayer',
        payload: {
          audioElement,
          audioPlayer: newAudioPlayer,
        },
      });
    }
  }, [
    audioElement,
    playbackRate,
    playbackStartTime,
    playerIds?.episodeId,
    previousAudioEl,
  ]);

  return [state, dispatch];
}
