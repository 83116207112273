import { useGetTitles } from 'api/hooks/useGetPodcastEpisodeDetail';

type UseGetTitlesFromIdsConfig = {
  podcastId: string | undefined;
  episodeId: string | undefined;
};

export default function useGetTitlesFromIds({
  podcastId,
  episodeId,
}: UseGetTitlesFromIdsConfig) {
  const { data } = useGetTitles({ episodeId, podcastId });

  const podcastName = data?.podcastTitle;
  const episodeName = data?.episodeTitle;

  return { podcastName, episodeName };
}
