import useStaticCallback from 'hooks/useStaticCallback';
import { useEffect, useRef } from 'react';
import { BaseAudioPlayer } from 'utils/audio/types';

const DEFAULT_THRESHOLD_MILLIS = 60000;

interface UseListenOptions {
  /**
   * This callback is called after accumulated listen time is equal to or greater
   * than thresholdMillis
   */
  onListen?: () => void;
  /**
   * player used to track listen.  Accumulated listening time resets when the
   * player reference changes
   */
  player?: BaseAudioPlayer;
  /**
   * The amount of accumulated listen time that needs to be reached before onListen
   * fires.  Accumulated listen time is counted anytime the audio is playing.  The
   * user can play, pause, seeek, etc.
   */
  thresholdMillis?: number;
}

/**
 * Fires a callback after a listen.
 *
 * A listen is defined as an event that occurs once the accumulated listening time
 * is greater than or equal to `thresholdMillis`.  Playback can start, stop, skip
 * around, etc., but as long as audio is playing, accumulated listening time is accrued.
 *
 * Listening time is accumulated by looking at timestamps between timeupdate calls
 * (datetime timestamps, not audio playback timecodes).
 *
 * Note that browsers may defer setTimeout and setInterval calls if a tab is not
 * focused, so it's not safe to use setTimeout, wait for the interval to elapse,
 * and then fire the event.
 */
export default function useListen({
  onListen,
  player,
  thresholdMillis = DEFAULT_THRESHOLD_MILLIS,
}: UseListenOptions) {
  // a useEffect hook is used to attach / detach event handlers.  the hook should
  // not re-run if the caller fails to memoize the onListen callback
  const staticOnListen = useStaticCallback(onListen);
  const thresholdMillisRef = useRef(thresholdMillis);

  // keep the thresholdMillisRef updated - we don't want the useEffect below to
  // run when the threshold millis changes
  if (thresholdMillisRef.current !== thresholdMillis) {
    thresholdMillisRef.current = thresholdMillis;
  }

  useEffect(() => {
    if (!player) {
      return undefined;
    }

    let lastTimeupdate: number | undefined;
    let accumulatedListenTime = 0;

    const handlePlay = () => {
      lastTimeupdate = Date.now();
    };

    const handleTimeupdate = () => {
      const now = Date.now();

      // it's possible that playback is already in progress by the time this hook
      // runs, in which case the "play" event might have been missed and
      // lastTimeupdate will be undefined.  In that case, use the first
      // timeupdate as a reference and don't start accumulating listen time until
      // subsequent timeupdate calls
      if (lastTimeupdate !== undefined) {
        const elapsedSinceLastTimeupdate = now - lastTimeupdate;

        accumulatedListenTime += elapsedSinceLastTimeupdate;

        if (accumulatedListenTime >= thresholdMillisRef.current) {
          staticOnListen?.();

          player.off('play', handlePlay);
          player.off('timeupdate', handleTimeupdate);
        }
      }

      lastTimeupdate = now;
    };

    player.on('play', handlePlay);
    player.on('timeupdate', handleTimeupdate);

    return () => {
      player.off('play', handlePlay);
      player.off('timeupdate', handleTimeupdate);
    };
  }, [player, staticOnListen]);
}
