import { useEventTracking } from 'context/EventTrackingContext';
import useGetTitles from 'hooks/useGetTitlesFromIds';
import useToggle from 'hooks/useToggle';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { useCallback } from 'react';
import { useEpisodeChapter, useEpisodeParts } from '../../state';
import useAnimation from './useAnimation';

interface UseEpisodePartConfig {
  partId: number;
}

export default function useEpisodePart({ partId }: UseEpisodePartConfig) {
  const {
    currentChapterIndex,
    controls: { playChapter } = {},
    podcastId,
    episodeId,
  } = useAudioPlayer();
  const { data } = useEpisodeParts();

  const partIndex = data?.chapters?.findIndex(({ id }) => id === partId);
  const expanded = partIndex === currentChapterIndex;

  const { trackPlayback } = useEventTracking();

  const { podcastName, episodeName } = useGetTitles({ podcastId, episodeId });

  const {
    value: isHovering,
    toggleOn: setHovering,
    toggleOff: unsetHovering,
  } = useToggle(false);

  const { data: part } = useEpisodeChapter(partId);

  const animation = useAnimation({
    disabled: !part,
    isExpanded: !!expanded,
    isHoveringContainer: isHovering && !expanded,
  });

  const handleClick = useCallback(() => {
    if (playChapter) {
      playChapter(partId, { scroll: true });

      if (podcastName && episodeName && podcastId && episodeId) {
        trackPlayback('play', {
          source: 'PartListItem',
          podcastName,
          episodeName,
          podcastId,
          remoteEpisodeId: episodeId,
        });
      }
    }
  }, [
    partId,
    playChapter,
    trackPlayback,
    podcastName,
    episodeName,
    podcastId,
    episodeId,
  ]);

  return {
    animation,
    expanded,
    part,
    setHovering,
    unsetHovering,
    onClick: expanded ? undefined : handleClick,
  };
}
