import cn from 'classnames';
import React from 'react';
import { OverridableComponentProps } from 'types/react';
import styles from './PageContents.module.scss';

interface Props {
  className?: string;
}

export type PageContentsProps<As extends React.ElementType = 'div'> =
  OverridableComponentProps<Props, As>;

/**
 * A component handling layout for pages.  This is similar to rendering
 * <Page fluid={false} /> however it avoids nesting Page compnoents in cases where
 * it might be necessary (e.g. a full-width header with padded contents)
 */
function PageContents<As extends React.ElementType = 'div'>({
  as,
  className,
  ...props
}: PageContentsProps<As>) {
  const Component = as ?? 'div';

  return <Component className={cn(styles.root, className)} {...props} />;
}

export default PageContents;
