import EpisodeCard from 'components/EpisodeCard';
import Spacer from 'components/Spacer';
import { Heading } from 'components/Typography';
import { useEpisodePage } from '../EpisodePageContext';
import { useCuratedEpisodes } from '../state';

const DEFAULT_EPISODE_COUNT = 3;

export interface EpisodeSuggestionsProps {
  count?: number;
}

const EpisodeSuggestions: React.FC<EpisodeSuggestionsProps> = ({
  count = DEFAULT_EPISODE_COUNT,
}) => {
  const { podcastId: pagePodcastId } = useEpisodePage();

  const { curatedEpisodes } = useCuratedEpisodes(count);

  return (
    <Spacer orientation="vertical" space={2}>
      <Heading level={3}>Other episodes of this podcast</Heading>
      {!curatedEpisodes
        ? [
            <EpisodeCard key={1} episode={undefined} podcastId={undefined} />,
            <EpisodeCard key={2} episode={undefined} podcastId={undefined} />,
            <EpisodeCard key={3} episode={undefined} podcastId={undefined} />,
          ]
        : curatedEpisodes.map((episode) => (
            <EpisodeCard
              key={episode.id}
              episode={episode}
              podcastId={pagePodcastId}
              pageType="EpisodePage"
            />
          ))}
    </Spacer>
  );
};

export default EpisodeSuggestions;
