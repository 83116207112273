import { Button, ButtonProps } from '@sparemin/blockhead';
import { HighlightVideoClip } from 'api/podcast';
import { useEventTracking } from 'context/EventTrackingContext';
import { TrackClipPlaybackOptions } from 'context/EventTrackingContext/types';
import { isUndefined } from 'lodash';
import { useRoutingError } from 'pages/RoutingErrorPage';
import { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createEpisodePath } from 'utils/routes';
import { useHighlightClipScroller } from '../../HighlightClipScrollerContext';

export interface ContinueListeningButtonProps
  extends Omit<ButtonProps<typeof Link>, 'to'> {
  clip?: HighlightVideoClip;
  source: TrackClipPlaybackOptions['source'];
}

const ContinueListeningButton: React.FC<ContinueListeningButtonProps> = ({
  clip,
  source,
  onPress,
  ...props
}) => {
  const remoteEpisodeId = clip?.episode.remoteEpisodeId;
  const remotePodcastId = clip?.podcast.podcastId;

  const { volume } = useHighlightClipScroller();
  const { trackPlayback } = useEventTracking();

  const navigate = useNavigate();
  const { setRoutingError } = useRoutingError();

  useEffect(() => {
    if (isUndefined(remoteEpisodeId) || isUndefined(remotePodcastId)) {
      setRoutingError(404);
    }
  }, [remoteEpisodeId, remotePodcastId, setRoutingError]);

  const [path = '', pathOptions] =
    !remoteEpisodeId || !remotePodcastId
      ? []
      : createEpisodePath(remoteEpisodeId, remotePodcastId, { startSec: 0 });

  const handlePress = useCallback(
    (e) => {
      if (clip && remotePodcastId && remoteEpisodeId) {
        trackPlayback('play', {
          clipId: clip.clipId,
          sound: volume,
          source,
          podcastName: clip.podcast.title,
          episodeName: clip.episode.title,
          podcastId: remotePodcastId,
          remoteEpisodeId,
        });
      }
      onPress?.(e);

      // HACK: for some reason I can't figure out, the Button (rendered as a link)
      // does not always redirect to the page.  This happens even when directly
      // using a Link element as opposed to passing it to the Button's "as" prop.
      // for now, force the navigate here to ensure that it happens
      navigate(path, pathOptions);
    },
    [
      clip,
      navigate,
      onPress,
      path,
      pathOptions,
      trackPlayback,
      volume,
      source,
      remoteEpisodeId,
      remotePodcastId,
    ],
  );

  return (
    <Button
      as={Link}
      onPress={handlePress}
      state={pathOptions?.state}
      to={path}
      {...props}
    >
      Listen to full episode
    </Button>
  );
};

export default ContinueListeningButton;
