import { useToggle } from '@sparemin/blockhead';
import React, { useContext, useMemo } from 'react';
import {
  ListeningQueueContextType,
  ListeningQueueProviderProps,
} from './types';
import useCreateListeningQueue from './useCreateListeningQueue';

const ListeningQueueContext = React.createContext<
  ListeningQueueContextType | undefined
>(undefined);

export const ListeningQueueProvider: React.FC<ListeningQueueProviderProps> = ({
  children,
}) => {
  const {
    value: showListeningQueue,
    toggleOn: openListeningQueue,
    toggleOff: hideListeningQueue,
  } = useToggle(false);

  const queueContents = useCreateListeningQueue();

  return (
    <ListeningQueueContext.Provider
      value={useMemo(
        () => ({
          show: showListeningQueue,
          open: openListeningQueue,
          hide: hideListeningQueue,
          queue: queueContents,
        }),
        [
          showListeningQueue,
          openListeningQueue,
          hideListeningQueue,
          queueContents,
        ],
      )}
    >
      {children}
    </ListeningQueueContext.Provider>
  );
};

export function useListeningQueue() {
  const context = useContext(ListeningQueueContext);

  if (context === undefined) {
    throw new Error(
      'useListeningQueue must be used within ListeningQueueProvider',
    );
  }

  return context;
}
