import { clamp } from 'utils/math';
import {
  BaseAudioPlayer,
  MediaElementAudioPlayerOptions,
  ReadyState,
} from './types';

// wrapper around an HTMLMediaElement for when we need to add more playback logic
export default class MediaElementAudioPlayer implements BaseAudioPlayer {
  constructor(
    public element: HTMLMediaElement,
    private opts: MediaElementAudioPlayerOptions,
  ) {
    this.seek(this.opts.startTime ?? 0);
    this.setPlaybackRate(this.opts.playbackRate ?? 1);
    this.play();
  }

  public on<T extends keyof HTMLMediaElementEventMap>(
    type: T,
    listener: (this: HTMLMediaElement, ev: HTMLMediaElementEventMap[T]) => any,
    opts?: boolean | AddEventListenerOptions,
  ) {
    this.element.addEventListener(type, listener, opts);
  }

  public off<T extends keyof HTMLMediaElementEventMap>(
    type: T,
    listener: (this: HTMLMediaElement, ev: HTMLMediaElementEventMap[T]) => any,
    opts?: boolean | AddEventListenerOptions,
  ) {
    this.element.removeEventListener(type, listener, opts);
  }

  public pause() {
    this.element.pause();
  }

  public play(): Promise<void> {
    return this.element.play();
  }

  public seek(seconds: number): void {
    this.element.currentTime = clamp(seconds, 0, this.duration);
  }

  public async togglePlay(fromSec?: number): Promise<void> {
    if (this.element.paused) {
      if (fromSec !== undefined) {
        this.seek(fromSec);
      }
      return this.element.play();
    }
    this.element.pause();
    return undefined;
  }

  public get paused(): boolean {
    return this.element.paused;
  }

  public currentTime(): number {
    return this.element.currentTime;
  }

  public get duration(): number {
    return this.element.duration;
  }

  public get ready(): boolean {
    return this.element.readyState >= ReadyState.HAVE_FUTURE_DATA;
  }

  public setPlaybackRate(rate: number): void {
    this.element.playbackRate = rate;
  }

  public get playbackRate(): number {
    return this.element.playbackRate;
  }
}
