import cn from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './ThumbnailSkeleton.module.scss';

type ThumbnailSkeletonProps = {
  className?: string;
};

const ThumbnailSkeleton: React.FC<ThumbnailSkeletonProps> = ({ className }) => (
  <Skeleton
    className={styles.skeleton}
    containerClassName={cn(styles.skeletonContainer, className)}
  />
);
export default ThumbnailSkeleton;
