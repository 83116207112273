import { LinkCardProps } from 'components/LinkCard';
import BaseSuggestionCard from 'components/SuggestionCard';
import { useEventTracking } from 'context/EventTrackingContext';
import useGetTitles from 'hooks/useGetTitlesFromIds';
import React, { useCallback } from 'react';
import { createEpisodePath } from 'utils/routes';
import { millisToSec } from 'utils/time';
import useSuggestion from './useSuggestion';

export interface SuggestionCardProps extends Pick<LinkCardProps, 'style'> {
  chapterId?: number;
  className?: string;
  suggestionId?: number;
}

const SuggestionCard: React.FC<SuggestionCardProps> = ({
  chapterId,
  className,
  style,
  suggestionId,
}) => {
  const { isFetched, data: suggestion } = useSuggestion(
    chapterId,
    suggestionId,
  );
  const [path, pathOptions] = !suggestion
    ? []
    : createEpisodePath(
        suggestion.episode.remoteEpisodeId,
        suggestion.episode.feed.podcastId,
        {
          startSec: millisToSec(suggestion.startMillis),
        },
      );

  const podcastId = suggestion?.episode.feed.podcastId;
  const episodeId = suggestion?.episode.remoteEpisodeId;

  const { trackPlayback } = useEventTracking();
  const { podcastName, episodeName } = useGetTitles({
    podcastId,
    episodeId,
  });

  const handleClick = useCallback(() => {
    if (podcastName && episodeName && podcastId && episodeId) {
      trackPlayback('play', {
        source: 'SimilarPartCard',
        podcastName,
        episodeName,
        podcastId,
        remoteEpisodeId: episodeId,
      });
    }
  }, [trackPlayback, podcastName, episodeName, episodeId, podcastId]);

  return (
    <BaseSuggestionCard
      className={className}
      description={suggestion?.headline}
      loading={!isFetched}
      onClick={handleClick}
      linkState={pathOptions?.state}
      linkTo={path}
      style={style}
      thumbnailSrc={suggestion?.episode.feed.thumbnailImageUrl}
      title={suggestion?.episode.feed.title}
    />
  );
};

export default SuggestionCard;
