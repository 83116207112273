import NonCriticalAsyncComponent from 'components/NonCriticalAsyncComponent';
import { isPreRendering } from 'utils/prerender';

export interface WhenNotPreRenderingProps {
  children?: () => React.ReactNode;
}

function WhenNotPreRendering({ children }: WhenNotPreRenderingProps) {
  return (
    <NonCriticalAsyncComponent>
      {isPreRendering ? null : children?.()}
    </NonCriticalAsyncComponent>
  );
}

export default WhenNotPreRendering;
