import { useCallback, useRef, useState } from 'react';

export default function useHeaderPodcastLink<T extends HTMLElement>() {
  const titleRef = useRef<T>(null);
  const [playButtonVisible, setPlayButtonVisible] = useState(false);

  const handleContentsMouseOver = useCallback((e: React.MouseEvent) => {
    if (e.target !== titleRef.current) {
      setPlayButtonVisible(true);
    } else {
      setPlayButtonVisible(false);
    }
  }, []);

  const handleContentsMouseOut = useCallback(() => {
    setPlayButtonVisible(false);
  }, []);

  const handleLinkClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return {
    playButtonVisible,
    titleRef,
    onContentsMouseOut: handleContentsMouseOut,
    onContentsMouseOver: handleContentsMouseOver,
    onLinkClick: handleLinkClick,
  };
}
