import cn from 'classnames';
import React from 'react';

import styles from './AspectRatio.module.scss';

export type AspectRatioBasis = 'height' | 'width';

export interface AspectRatioProps {
  children?: React.ReactElement<{ className: string }>;
  // if basis is "width", height will be adjusted to maintain AR.  if basis is
  // "height", width will be adjusted to maintain AR
  basis?: AspectRatioBasis;
  className?: string;
  ratio: number;
}

const AspectRatio: React.FC<AspectRatioProps> = ({
  basis = 'width',
  children,
  className,
  ratio,
}) => {
  const child = React.Children.only(children);

  return (
    <div
      className={cn(
        styles.aspectRatio,
        {
          [styles[`aspectRatio__${basis}`]]: true,
        },
        className,
      )}
      style={
        basis === 'width'
          ? { ['--aspect-ratio' as any]: `${ratio * 100}%` }
          : undefined
      }
    >
      {basis === 'height' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svg}
          width="1"
          height={ratio}
        />
      )}
      {React.isValidElement(child) &&
        React.cloneElement(child, {
          className: cn(styles.child, child.props.className),
        })}
    </div>
  );
};

export default AspectRatio;
