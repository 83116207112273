import { useQueryClient, useMutation, UseMutationOptions } from 'react-query';
import { removeFeedFollow } from '../podcast';
import { podcastFollowsQueryKeys } from './queries';

export default function useRemoveFeedFollows(
  config?: Omit<
    UseMutationOptions<void, unknown, number, unknown>,
    'mutationFn'
  >,
) {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, number>(
    (feedId: number) => removeFeedFollow({ feedId }),
    {
      ...config,
      onSuccess: (data, feedId, context) => {
        queryClient.invalidateQueries(podcastFollowsQueryKeys.follows(feedId));
        if (config?.onSuccess) {
          config.onSuccess(data, feedId, context);
        }
      },
    },
  );
}
