import qs from 'query-string';

export const MARKETING_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
] as const;

export type MarketingParam = typeof MARKETING_PARAMS[number];

export function getTextContentFromHtmlString(html: string | undefined) {
  if (!html) {
    return html;
  }

  const container = document.createElement('span');
  container.innerHTML = html;
  return container.textContent || container.innerText;
}

export function getMarketingParamsFromUrl(): Partial<
  Record<MarketingParam, string>
> {
  const { query } = qs.parseUrl(document.URL);

  return MARKETING_PARAMS.reduce((paramsObj, marketingKey: MarketingParam) => {
    const value = query[marketingKey];

    if (typeof value === 'string' && value.length > 0) {
      return { ...paramsObj, [marketingKey]: value };
    }

    return paramsObj;
  }, {} as Partial<Record<MarketingParam, string>>);
}
