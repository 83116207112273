import { is404 } from 'api/utils';
import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from 'react-query';

export interface QueryClientProviderProps {
  children?: React.ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (_, err) => !is404(err),
      staleTime: 10000,
    },
  },
});

const QueryClientProvider: React.FC<QueryClientProviderProps> = ({
  children,
}) => (
  <ReactQueryClientProvider client={queryClient}>
    {children}
  </ReactQueryClientProvider>
);

export default QueryClientProvider;
