import BlockedEpisodeBadge from 'components/BlockedEpisodeBadge';
import { CardVariant } from 'components/Card/types';
import DurationBadge from 'components/DurationBadge';
import LinkCard from 'components/LinkCard';
import { Text } from 'components/Typography';
import useDate from 'hooks/useDate';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { NavigateOptions, Path, useNavigate } from 'react-router-dom';
import { getClassNameWithVariant } from 'utils/styles';

import styles from './EpisodeCardFooter.module.scss';

interface EpisodeCardFooterProps {
  audioDurationMillis?: number;
  isLoading?: boolean;
  onClickBadge?: () => void;
  path?: string | Partial<Path> | undefined;
  pathOpts?: NavigateOptions;
  publishedAtMillis?: number;
  variant?: CardVariant;
  isBlocked?: boolean;
}

const EpisodeCardFooter: React.FunctionComponent<EpisodeCardFooterProps> = (
  props,
) => {
  const {
    audioDurationMillis,
    isLoading,
    onClickBadge,
    path,
    pathOpts,
    publishedAtMillis,
    variant = 'default',
    isBlocked = false,
  } = props;

  const navigate = useNavigate();

  const date = useDate(publishedAtMillis);

  const handleBadgeClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      onClickBadge?.();

      if (path) {
        navigate(path, pathOpts);
      }
    },
    [navigate, onClickBadge, path, pathOpts],
  );

  return (
    <LinkCard.Footer className={styles.footer}>
      {isLoading ? (
        <Skeleton width="100px" />
      ) : (
        <>
          {!isBlocked ? (
            <DurationBadge
              onClickCapture={handleBadgeClick}
              durationMillis={audioDurationMillis}
            />
          ) : (
            <BlockedEpisodeBadge />
          )}
          <Text
            className={getClassNameWithVariant(styles, 'date', variant)}
            type="details"
          >
            {date}
          </Text>
        </>
      )}
    </LinkCard.Footer>
  );
};

export default EpisodeCardFooter;
