import { useCallback, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { useResizeDetector } from 'react-resize-detector';
import { UseTooltipConfig } from './types';

export default function useTooltip({
  offset = 15,
  placement = 'top',
  strategy,
  target,
}: UseTooltipConfig) {
  const { height, width, ref: tooltipResizeRef } = useResizeDetector();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>();

  const { styles, attributes, update } = usePopper(target, popperElement, {
    placement,
    strategy,
    modifiers: [
      offset && { name: 'offset', options: { offset: [0, offset] } },
      { name: 'arrow', options: { element: arrowElement } },
    ].filter(Boolean) as any,
  });

  // update popper position when it resizes
  useEffect(() => {
    update?.();
  }, [height, update, width]);

  const popperRef = useCallback(
    (el: HTMLDivElement | null) => {
      tooltipResizeRef.current = el as any;
      setPopperElement(el);
    },
    [tooltipResizeRef],
  );

  return {
    attributes,
    popperRef,
    styles,
    arrowRef: setArrowElement,
  };
}
