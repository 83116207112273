import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProps } from './types';

export default function createFAIcon(
  iconProp: IconProp,
): React.ElementType<IconProps> {
  return ({ className, style }: IconProps) => (
    <FontAwesomeIcon icon={iconProp} {...{ className, style }} />
  );
}
