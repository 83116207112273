import { feedFollowIsFollowingSelector } from 'api/selectors/podcast';
import { AxiosError } from 'axios';
import { useAuth } from 'context/AuthContext';
import { isFinite } from 'lodash';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'types';
import { GetFeedFollowResponse, getFeedFollow } from '../podcast';
import { podcastFollowsQueryKeys } from './queries';

export type Options<TData = GetFeedFollowResponse> = UseQueryOptions<
  GetFeedFollowResponse,
  ReturnType<typeof podcastFollowsQueryKeys.follows>,
  AxiosError | Error,
  TData
>;

export default function useGetFeedFollow<TData = GetFeedFollowResponse>(
  id?: number,
  opts?: Options<TData>,
) {
  const { isAuthenticated } = useAuth();

  return useQuery(
    podcastFollowsQueryKeys.follows(id),

    ({ queryKey: [{ feedId }] }) => getFeedFollow(feedId as number),
    {
      ...opts,
      enabled: (opts?.enabled ?? true) && isFinite(id) && isAuthenticated,
    },
  );
}

export function useIsFollowingFeed(id?: number) {
  return useGetFeedFollow(id, {
    select: feedFollowIsFollowingSelector,
  });
}
