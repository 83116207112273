import React from 'react';

type MergeableRef<T> =
  | React.MutableRefObject<T>
  | Exclude<React.LegacyRef<T>, string>;

// https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx
export default function mergeRefs<T = any>(
  refs: Array<MergeableRef<T>>,
): React.RefCallback<T> {
  return (val: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(val);
      } else if (ref != null) {
        // eslint-disable-next-line no-param-reassign
        (ref as React.MutableRefObject<T | null>).current = val;
      }
    });
  };
}
