import { useToggle } from '@sparemin/blockhead';
import React, { useContext, useMemo } from 'react';
import {
  NowPlayingCardContextType,
  NowPlayingCardProviderProps,
} from './types';

const NowPlayingCardContext = React.createContext<
  NowPlayingCardContextType | undefined
>(undefined);

export const NowPlayingCardProvider: React.FC<NowPlayingCardProviderProps> = ({
  children,
}) => {
  const {
    value: showNowPlayingCard,
    toggleOn: openNowPlayingCard,
    toggleOff: hideNowPlayingCard,
  } = useToggle(false);

  return (
    <NowPlayingCardContext.Provider
      value={useMemo(
        () => ({
          show: showNowPlayingCard,
          open: openNowPlayingCard,
          hide: hideNowPlayingCard,
        }),
        [showNowPlayingCard, openNowPlayingCard, hideNowPlayingCard],
      )}
    >
      {children}
    </NowPlayingCardContext.Provider>
  );
};

export function useNowPlayingCard() {
  const context = useContext(NowPlayingCardContext);

  if (context === undefined) {
    throw new Error(
      'useNowPlayingCard must be used within NowPlayingCardProvider',
    );
  }

  return context;
}
