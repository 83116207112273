import SuppressingErrorBoundary from 'components/SuppressingErrorBoundary';
import React, { Suspense } from 'react';

export interface NonCriticalAsyncComponentProps {
  children?: React.ReactNode;
}

// If  component imported with React.lazy is rendered as a child, it will fail
// silently if there are errors
const NonCriticalAsyncComponent: React.FC<NonCriticalAsyncComponentProps> = ({
  children,
}) => (
  <SuppressingErrorBoundary>
    <Suspense fallback={null}>{children}</Suspense>
  </SuppressingErrorBoundary>
);

export default NonCriticalAsyncComponent;
