import { useIsPodcastOwner } from 'api/hooks/useGetOwnedPodcasts';
import { notifySuccess } from 'components/notification';
import { useEventTracking } from 'context/EventTrackingContext';
import usePagePodcastDetail from 'pages/PodcastPage/usePagePodcastDetail';
import { useEffect } from 'react';
import useClaimPodcastQueryParams from './useClaimPodcastQueryParams';

const useClaimPodcastFlow = () => {
  const {
    trackClaimPodcastRequest,
    trackClaimPodcastRequestGranted,
    trackOwnershipGrantedCta,
  } = useEventTracking();

  const { data: podcastDetailsData } = usePagePodcastDetail();
  const feedId = podcastDetailsData?.podcastFeedId;
  const { data: isPodcastOwner } = useIsPodcastOwner(feedId);

  const { claimStatus, owner } = useClaimPodcastQueryParams();

  useEffect(() => {
    if (claimStatus === 'requested') {
      notifySuccess({
        heading: 'We emailed the address in the feed',
        subHeading: 'We’ll notify you if they approve your request',
      });
      trackClaimPodcastRequest();
    }
    if (claimStatus === 'approved' && typeof owner === 'string') {
      notifySuccess({
        heading: 'Ownership granted!',
        subHeading: `You've successfully granted access of this podcast to ${owner}`,
      });
      trackClaimPodcastRequestGranted();
    }
  }, [
    claimStatus,
    owner,
    trackClaimPodcastRequest,
    trackClaimPodcastRequestGranted,
  ]);

  useEffect(() => {
    if (claimStatus === 'claimed' && isPodcastOwner) {
      notifySuccess({ heading: "You've claimed this podcast!" });
      trackOwnershipGrantedCta();
    }
  }, [claimStatus, isPodcastOwner, trackOwnershipGrantedCta]);
};

export default useClaimPodcastFlow;
