import { IconButton, Speed } from '@sparemin/blockhead';
import TextButton from 'components/TextButton';
import { TextButtonProps } from 'components/TextButton/TextButton';
import { useBreakpoints } from 'context/BreakpointsContext';
import React from 'react';
import styles from './PlaybackRateMenu.module.scss';

interface PlaybackRateMenuTriggerProps extends Omit<TextButtonProps, 'color'> {}

const PlaybackRateMenuTrigger = React.forwardRef<
  HTMLButtonElement,
  PlaybackRateMenuTriggerProps
>((props, ref) => {
  const { md } = useBreakpoints();
  if (md) {
    return (
      <TextButton className={styles.text} ref={ref} {...props}>
        <Speed className={styles.icon} />
        Speed
      </TextButton>
    );
  }
  return (
    <IconButton ref={ref} className={styles.icon} {...props}>
      <Speed />
    </IconButton>
  );
});
export default PlaybackRateMenuTrigger;
