import { usePageVideoClip } from 'api/hooks/useHighlightVideoClips';
import { HighlightClip } from 'components/HighlightClipScroller';
import { HighlightClipProps } from 'components/HighlightClipScroller/HighlightClip';
import React from 'react';

interface PodcastHighlightClipProps
  extends Omit<HighlightClipProps, 'clip' | 'source'> {
  clipId: number;
}

const PodcastHighlightClip: React.FC<PodcastHighlightClipProps> = ({
  clipId,
  onContinueListening,
  className,
}) => {
  const { data: clip } = usePageVideoClip(clipId);
  return (
    <HighlightClip
      clip={clip}
      source="PodcastPage"
      onContinueListening={onContinueListening}
      className={className}
    />
  );
};
export default PodcastHighlightClip;
