import useChapterSuggestions from 'api/hooks/useChapterSuggestions';
import { ChapterSuggestion } from 'api/play';

export default function useSuggestion(
  chapterId?: number,
  suggestionId?: number,
) {
  return useChapterSuggestions<ChapterSuggestion | undefined>(chapterId, {
    select: (response) => response.data.find((s) => s.id === suggestionId),
    // it's not uncommon for suggestions to change every query.  setting the stale
    // time to a few seconds prevents react-query from performing infinite queries.
    staleTime: 5000,
  });
}
