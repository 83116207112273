import queryString from 'query-string';

export function prepareShareUrl(baseUrl: string, timestamp?: number) {
  const utmSource = 'ShareButton';
  if (timestamp !== undefined) {
    return queryString.stringifyUrl({
      url: baseUrl,
      query: { utm_source: utmSource, t: timestamp },
    });
  }
  return queryString.stringifyUrl({
    url: baseUrl,
    query: { utm_source: utmSource },
  });
}
