import { createUrl } from 'api/utils';
import { request } from '../http';
import { GET_EXPERIMENTS_URL_PATTERN } from './constants';
import { ExperimentResponse } from './types';

export async function getExperimentVariants(
  experimentName: string[],
  anonymousUserToken: string,
): Promise<ExperimentResponse> {
  const { data } = await request.get(
    createUrl(GET_EXPERIMENTS_URL_PATTERN, {
      queryParams: { anonymousUserToken, experimentName },
    }),
  );

  return data;
}
