import { Chapter } from 'types';

export function isTimeWithinChapter(
  time: number | undefined,
  chapter: Chapter | undefined,
): boolean {
  if (time === undefined || chapter === undefined) {
    return false;
  }

  return time >= chapter.startSec && time <= (chapter.endSec ?? Infinity);
}

export function indexOfChapterByTime(
  currentTime: number | undefined,
  chapters: Chapter[] | undefined,
): number {
  if (currentTime === undefined || chapters === undefined) {
    return -1;
  }

  return chapters.findIndex((chapter) =>
    isTimeWithinChapter(currentTime, chapter),
  );
}

export function indexOfChapterById(
  chapterId: string | number | undefined | null,
  chapters: Chapter[] | undefined,
): number {
  if (chapterId === undefined || chapterId === null || chapters === undefined) {
    return -1;
  }

  return chapters.findIndex((chapter) => chapter.id === chapterId);
}

export function findChapterByTime(
  currentTime: number | undefined,
  chapters: Chapter[] | undefined,
): Chapter | undefined {
  const index = indexOfChapterByTime(currentTime, chapters);

  if (index >= 0 && chapters !== undefined) {
    return chapters[index];
  }

  return undefined;
}
