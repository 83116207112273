import { Heading, Button, Spacer } from '@sparemin/blockhead';
import headyGif from 'assets/gifs/headySpinner.gif';
import { useHighlightClipScroller } from 'components/HighlightClipScroller';
import useActiveSlide from 'components/HighlightClipScroller/hooks/useActiveSlide';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useEventTracking } from 'context/EventTrackingContext';
import React, { useEffect } from 'react';
import { useSwiper, useSwiperSlide } from 'swiper/react';
import styles from './infoSlides.module.scss';

type IntroSlideProps = {
  onView?: (val: number) => void;
};

const IntroSlide: React.FC<IntroSlideProps> = ({ onView }) => {
  const { trackHomeFeedStartDiscovering } = useEventTracking();
  const { unmuteOnInteraction, toggleOffUnmuteOnInteraction, unmute } =
    useHighlightClipScroller();

  const { md } = useBreakpoints();

  const swiper = useSwiper();

  useEffect(() => {
    swiper.once('scroll', () => {
      trackHomeFeedStartDiscovering('scroll');
    });
  }, [trackHomeFeedStartDiscovering, swiper]);

  const { isActive } = useSwiperSlide();

  useActiveSlide({ onView: () => onView?.(swiper.activeIndex) });

  const handlePress = () => {
    swiper.slideNext();
    trackHomeFeedStartDiscovering('button');
    if (unmuteOnInteraction) {
      toggleOffUnmuteOnInteraction();
      unmute();
    }
  };

  // The intro slide is only rendered when it is the active slide so that it disappears from view instead
  // of overlapping the page when the user scrolls to the first Highlight Clip Slide
  if (isActive)
    return (
      <div className={styles.root}>
        <Spacer
          orientation="vertical"
          align="center"
          justify="center"
          className={styles.container}
        >
          {!md && <img src={headyGif} alt="" className={styles.img} />}
          <Heading className={styles.title} level={1}>
            Play by Headliner
          </Heading>
          <Heading level={2} className={styles.subtitle}>
            Welcome to the podcast player that makes it easy to discover new
            shows. Effortlessly explore episodes with our snackable podcast
            previews.
          </Heading>

          <Button onPress={handlePress}>start discovering</Button>
        </Spacer>
      </div>
    );
  return null;
};

export default IntroSlide;
