import Chip from 'components/Chip';
import { HeadlinerTextLogo, NotFoundImage } from 'components/icons';
import Spacer from 'components/Spacer';
import { Heading } from 'components/Typography';
import { Link } from 'react-router-dom';
import routes from 'utils/routes';
import styles from './NotFoundPage.module.scss';

export interface NotFoundPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = () => (
  <main className={styles.root}>
    <Spacer
      className={styles.content}
      align="center"
      orientation="vertical"
      space={5}
    >
      <HeadlinerTextLogo className={styles.logo} />
      <NotFoundImage width="300px" />
      <Spacer align="center" orientation="vertical" space="13px">
        <Heading level={1}>We can’t find that page…</Heading>
        <Heading level={2}>
          Please check the link and try loading it again.
        </Heading>
      </Spacer>
      <Link className={styles.link} to={routes.home}>
        <Chip theme="primary">visit our home page</Chip>
      </Link>
    </Spacer>
  </main>
);

export default NotFoundPage;
