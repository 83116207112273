import { ResetPasswordCard } from '@sparemin/auth';
import useProfile from 'api/hooks/useProfile';
import { AnimatedRouteLayout } from 'components/AnimatedRoute';
import { useAuth } from 'context/AuthContext';
import { useEventTracking } from 'context/EventTrackingContext';
import { useRegisterExperiments } from 'experiments';
import AudioPlayerPage from 'pages/AudioPlayerPage';
import EpisodeLegacyPage from 'pages/EpisodeLegacyPage';
import EpisodePage from 'pages/EpisodePage';
import HomePage from 'pages/HomePage';
import NotFoundPage from 'pages/NotFoundPage';
import PodcastLegacyPage from 'pages/PodcastLegacyPage';
import PodcastPage from 'pages/PodcastPage';
import RoutingErrorPage from 'pages/RoutingErrorPage';
import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router-dom';
import routes from 'utils/routes';
import UserAuthenticationRoute from './UserAuthenticationRoute';

export interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
  useRegisterExperiments();

  const location = useLocation();
  const { setUserTrackingInfo } = useEventTracking();

  useEffect(() => {
    const page = `${location.pathname}${location.search}`;
    ReactGA.send({ hitType: 'pageview', page });
  }, [location]);

  const { userId } = useAuth();

  const { data: userData } = useProfile();

  const ref = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (userId && userData?.email && ref.current !== userId) {
      ref.current = userId;

      setUserTrackingInfo(userData.email, userId);
    }
  }, [userId, userData?.email, setUserTrackingInfo]);

  return (
    <Routes>
      <Route element={<RoutingErrorPage />}>
        <Route element={<AudioPlayerPage />}>
          <Route element={<AnimatedRouteLayout />}>
            <Route path={routes.home} element={<HomePage />} />
            <Route path={routes.podcastDetails} element={<PodcastPage />} />
            <Route path={routes.episodeDetails} element={<EpisodePage />} />
            <Route
              path={routes.podcastLegacy}
              element={<PodcastLegacyPage />}
            />
            <Route
              path={routes.episodeLegacy}
              element={<EpisodeLegacyPage />}
            />
          </Route>
        </Route>
      </Route>
      <Route element={<UserAuthenticationRoute />}>
        <Route path={routes.resetPassword} element={<ResetPasswordCard />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRouter;
