import Thumbnail, { ThumbnailProps } from 'components/Thumbnail';
import { motion } from 'framer-motion';
import { useAppHeaderAnimation } from '../AppHeaderAnimationContext';

export interface AppHeaderArtworkProps extends Pick<ThumbnailProps, 'src'> {
  children?: React.ReactNode;
}

const AppHeaderArtwork: React.FC<AppHeaderArtworkProps> = ({
  children,
  src,
}) => {
  const animation = useAppHeaderAnimation();

  return (
    <Thumbnail
      as={motion.div}
      alt="header spotlight"
      size="large"
      src={src}
      {...animation.artwork}
    >
      {children}
    </Thumbnail>
  );
};

export default AppHeaderArtwork;
