import { AxiosError } from 'axios';
import { isFinite } from 'lodash';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'types';
import {
  GetPodcastDetailsResponse,
  getPodcastDetailsFromFeed,
} from '../podcast';
import { podcastQueryKeys } from './queries';

export type Options<TData = GetPodcastDetailsResponse> = UseQueryOptions<
  GetPodcastDetailsResponse,
  ReturnType<typeof podcastQueryKeys.feedDetails>,
  AxiosError | Error,
  TData
>;

export default function useGetPodcastDetailFromFeed<
  TData = GetPodcastDetailsResponse,
>(feedPodcastId?: number, opts?: Options<TData>) {
  return useQuery(
    podcastQueryKeys.feedDetails(feedPodcastId),
    ({ queryKey: [key] }) =>
      getPodcastDetailsFromFeed(key.feedPodcastId as number, false),
    {
      ...opts,
      enabled: (opts?.enabled ?? true) && isFinite(feedPodcastId),
    },
  );
}
