import { getUserProfile, UserProfile } from 'api/user';
import { AxiosError } from 'axios';
import { useAuth } from 'context/AuthContext';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'types';
import { userProfileQueryKeys } from './queries';

export type Options<TData = UserProfile> = UseQueryOptions<
  UserProfile,
  ReturnType<typeof userProfileQueryKeys.profile>,
  AxiosError | Error,
  TData
>;

export default function useProfile<TData = UserProfile>(opts?: Options<TData>) {
  const { userId: currUserId, accessToken: currAccessToken } = useAuth();

  return useQuery(
    userProfileQueryKeys.profile(currUserId, currAccessToken),
    ({ queryKey: [{ userId, accessToken }] }) =>
      getUserProfile({
        userId: userId as number,
        token: accessToken as string,
      }),

    {
      ...opts,
      enabled: (opts?.enabled ?? true) && !!(currUserId && currAccessToken),
    },
  );
}
