import cn from 'classnames';
import { Heading } from 'components/Typography';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { useGetEpisodeTitle } from 'pages/AudioPlayerPage/state/useGetEpisodeDetails';
import useTrackPlayerLinkClick from 'pages/AudioPlayerPage/state/useTrackPlayerLinkClick';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { createEpisodePath } from 'utils/routes';
import styles from './PodcastData.module.scss';

type EpisodeTitleProps = {};

const EpisodeTitle: React.FC<EpisodeTitleProps> = () => {
  const { episodeId, podcastId } = useAudioPlayer();
  const { md } = useBreakpoints();

  const { data: episodeTitle } = useGetEpisodeTitle({ podcastId, episodeId });

  const trackLinkClick = useTrackPlayerLinkClick('EpisodeTitle');

  const [path, pathOpts] =
    !episodeId || !podcastId ? [] : createEpisodePath(episodeId, podcastId);

  const location = useLocation();

  const onEpisodePage = location.pathname === path?.pathname;

  const titleText = (
    <Heading
      className={cn(styles.episode, { [styles.episode__link]: !onEpisodePage })}
      level={2}
    >
      {episodeTitle}
    </Heading>
  );

  if (!onEpisodePage && !md) {
    return (
      <Link
        className={styles.link}
        to={path ?? ''}
        state={pathOpts}
        onClick={trackLinkClick}
      >
        {titleText}
      </Link>
    );
  }
  return titleText;
};
export default EpisodeTitle;
