import useGetPodcastEpisodeDetail from 'api/hooks/useGetPodcastEpisodeDetail';
import { podcastEpisodeFeedIdSelector } from 'api/selectors/podcast';
import { useEpisodeAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext/EpisodeAudioPlayerContext';

const useGetPodcastInformation = () => {
  const { episodeId, podcastId } = useEpisodeAudioPlayer();
  const { data: feedId } = useGetPodcastEpisodeDetail(
    { episodeId, podcastId },
    {
      select: podcastEpisodeFeedIdSelector,
    },
  );

  return { episodeId, feedId, podcastId };
};

export default useGetPodcastInformation;
