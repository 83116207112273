import { Tooltip } from '@sparemin/blockhead';
import React from 'react';
import PodcatcherLink from './PodcatcherLink';
import { PodcatcherLinkProps } from './types';

interface PodcatcherLinkWithTooltipProps extends PodcatcherLinkProps {}

const PodcatcherLinkWithTooltip: React.FC<PodcatcherLinkWithTooltipProps> = (
  props,
) => {
  const { url } = props;
  const PodcastLink = <PodcatcherLink {...props} />;

  return url ? (
    PodcastLink
  ) : (
    <Tooltip
      contents="Not available for this podcast"
      placement="bottom"
      strategy="absolute"
    >
      <div>{PodcastLink}</div>
    </Tooltip>
  );
};
export default PodcatcherLinkWithTooltip;
