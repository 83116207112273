import cn from 'classnames';
import BigPlayButton from 'components/BigPlayButton';
import { IconButtonProps } from 'components/IconButton';
import ControlButton from '../ControlButton';
import styles from './PlayButton.module.scss';
import usePlayButton from './usePlayButton';

export interface PlayButtonProps {
  className?: string;
  style?: React.CSSProperties;
  size?: IconButtonProps['size'];
}

const PlayButton: React.FC<PlayButtonProps> = ({ className, style, size }) => {
  const { onClick, paused, showSpinner } = usePlayButton();
  const playing = !paused;

  return (
    <div
      className={cn(
        styles.root,
        {
          [styles.root__loading]: showSpinner,
        },
        className,
      )}
      style={style}
    >
      <ControlButton
        iconButton={
          <BigPlayButton
            aria-label={playing ? 'pause' : 'play'}
            disabled={showSpinner}
            onClick={onClick}
            playing={playing}
            size={size}
            variant="outlined"
          />
        }
      />
    </div>
  );
};

export default PlayButton;
