import { SegmentConfiguration } from './types';

export function indexOfSegment(
  value: number,
  segments: SegmentConfiguration[],
) {
  return segments.findIndex((seg, index) => {
    const endValue = segments[index + 1]?.startValue ?? Infinity;
    return value >= seg.startValue && value <= endValue;
  });
}

export function indexOfActiveSegment(
  value: number,
  segments: SegmentConfiguration[],
): number {
  const index = indexOfSegment(value, segments);
  return Math.max(0, index);
}
