import { Tooltip, IconButton } from '@sparemin/blockhead';
import { QueueIcon } from 'components/icons';
import TextButton from 'components/TextButton';
import { useBreakpoints } from 'context/BreakpointsContext';
import useListeningQueue from 'pages/AudioPlayerPage/state/ListeningQueueContext';
import styles from './QueueIcon.module.scss';

export interface QueueButtonProps {}

const QueueButton: React.FC<QueueButtonProps> = () => {
  const { md } = useBreakpoints();
  const { open } = useListeningQueue();

  if (md) {
    return (
      <TextButton onPress={open} className={styles.text}>
        <QueueIcon className={styles.icon} />
        queue
      </TextButton>
    );
  }

  return (
    <Tooltip contents="Queue" placement="top" strategy="absolute" arrow>
      <IconButton onPress={open}>
        <QueueIcon className={styles.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default QueueButton;
