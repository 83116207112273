import { Link, UnstyledButton, UnstyledButtonProps } from '@sparemin/blockhead';
import React from 'react';

export interface TextButtonProps extends UnstyledButtonProps {
  children?: React.ReactNode;
  onPress?: () => void;
  className?: string;
}

const TextButton = React.forwardRef<HTMLButtonElement, TextButtonProps>(
  (
    { children, onPress, className, ...props }: TextButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => (
    <Link
      ref={ref}
      as={UnstyledButton}
      level={2}
      typography="heading"
      href="undefined"
      onPress={onPress}
      className={className}
      {...props}
    >
      {children}
    </Link>
  ),
);
export default TextButton;
