import { useCallback, useEffect, useRef } from 'react';
import useStaticCallback from './useStaticCallback';

interface UseTimeoutConfig<T extends any[]> {
  callback: (...args: T) => void;
  delay: number;
}

export default function useTimeout<T extends any[]>({
  callback,
  delay,
}: UseTimeoutConfig<T>) {
  const timerIdRef = useRef<number | undefined>(undefined);
  const staticCb = useStaticCallback(callback);

  const cancel = useCallback(() => {
    if (timerIdRef.current) {
      window.clearTimeout(timerIdRef.current);
      timerIdRef.current = undefined;
    }
  }, []);

  const start = useCallback(
    (...args: T) => {
      cancel();
      timerIdRef.current = window.setTimeout(() => staticCb(...args), delay);
    },
    [cancel, delay, staticCb],
  );

  useEffect(() => cancel, [cancel]);

  return [start, cancel] as const;
}
