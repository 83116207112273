import { Heading, Spacer, Text, Thumbnail } from '@sparemin/blockhead';
import { HighlightVideoClip } from 'api/podcast';
import { TrackClipPlaybackOptions } from 'context/EventTrackingContext/types';
import ContinueListeningButton from '../ContinueListeningButton';
import styles from './HighlightClipInfoOverlay.module.scss';

export interface HighlightClipInfoOverlayProps {
  clip?: HighlightVideoClip;
  onContinueListening?: () => void;
  source: TrackClipPlaybackOptions['source'];
}

const HighlightClipInfoOverlay: React.FC<HighlightClipInfoOverlayProps> = ({
  clip,
  onContinueListening,
  source,
}) => (
  <Spacer
    className={styles.root}
    justify="space-between"
    orientation="vertical"
    space="0px"
  >
    <Spacer align="center" orientation="horizontal" space="12px">
      <Thumbnail
        alt={`artwork for "${clip?.podcast.title}"`}
        className={styles.thumbnail}
        ratio={1}
        src={clip?.podcast.thumbnailImageUrl}
      />
      <Text className={styles.title} variant="p">
        {clip?.podcast.title}
      </Text>
    </Spacer>
    <Spacer className={styles.footer} orientation="vertical" space="16px">
      <Heading className={styles.episode} level={3} variant="mobile">
        {clip?.episode.title}
      </Heading>
      <ContinueListeningButton
        className={styles.continueListening}
        clip={clip}
        fluid
        color="light"
        onPress={onContinueListening}
        size="small"
        variant="frosted"
        source={source}
      />
    </Spacer>
  </Spacer>
);

export default HighlightClipInfoOverlay;
