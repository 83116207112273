import { Link } from '@sparemin/blockhead';
import { useGetPodcastTitle } from 'api/hooks/useGetPodcastDetail';
import cn from 'classnames';
import FollowButton, { FollowButtonProps } from 'components/FollowButton';
import { Heading, Text } from 'components/Typography';
import { useEventTracking } from 'context/EventTrackingContext';
import { useFollowPodcastPrompt } from 'context/FollowPodcastContext';
import React, { useEffect, useRef } from 'react';
import styles from './FollowPodcastPrompt.module.scss';

export interface FollowPodcastPromptProps
  extends Omit<FollowButtonProps, 'source' | 'podcastName'> {
  podcastId: string | undefined;
}

const FollowPodcastPrompt: React.FC<FollowPodcastPromptProps> = ({
  feedId,
  podcastId,
}) => {
  const { data: podcastTitle } = useGetPodcastTitle(podcastId);
  const { trackDisplayFollowPrompt } = useEventTracking();

  const { close, isOpen } = useFollowPodcastPrompt();
  const promptStatusRef = useRef<boolean | undefined>(isOpen);

  useEffect(() => {
    if (promptStatusRef.current) {
      trackDisplayFollowPrompt();
    }
  }, [trackDisplayFollowPrompt]);

  return feedId && podcastTitle ? (
    <div className={cn(styles.root)}>
      <Heading align="center" level={3} className={styles.title}>
        Enjoying this podcast?
      </Heading>
      <Text align="center" type="paragraph" className={styles.subtitle}>
        Get notified when new episodes are released
      </Text>
      <FollowButton
        feedId={feedId}
        podcastName={podcastTitle}
        source="FollowPrompt"
        size="large"
        color="primary"
        variant="solid"
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        as="button"
        elementType="button"
        onPress={close}
        typography="text"
        variant="button"
        className={styles.dismiss}
      >
        no thanks
      </Link>
    </div>
  ) : null;
};
export default FollowPodcastPrompt;
