import {
  CHAPTER_ELEMENT_CLASS_NAME,
  CHAPTER_ELEMENT_TIME_ATTRIBUTE,
} from 'api/podcast';
import Chip from 'components/Chip';
import Divider from 'components/Divider';
import { parseInt } from 'lodash';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import React, { useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { createPodcastPath } from 'utils/routes';
import EpisodeSuggestions from '../EpisodeSuggestions';
import { useEpisodeDetails } from '../state/usePagePodcastEpisodeDetail';

import styles from './EpisodeDescription.module.scss';

export interface EpisodeDescriptionProps {}

const EpisodeDescription: React.FC<EpisodeDescriptionProps> = () => {
  const { episodeId, controls, loadPlayerAudio } = useAudioPlayer();
  const { data } = useEpisodeDetails();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const targetElement = e.target as HTMLElement;

      if (targetElement instanceof HTMLAnchorElement) {
        e.preventDefault();
        window.open(targetElement.href, '_blank');
      }

      if (targetElement?.classList.contains(CHAPTER_ELEMENT_CLASS_NAME)) {
        const chapterSeconds = targetElement.getAttribute(
          CHAPTER_ELEMENT_TIME_ATTRIBUTE,
        );

        if (!chapterSeconds) {
          return;
        }

        const startTime = parseInt(chapterSeconds);

        if (data?.episodeId !== episodeId) {
          loadPlayerAudio({
            episodeId: data?.episodeId,
            podcastId: data?.remotePodcastId,
            startTime,
          });

          return;
        }

        controls?.seek(startTime);
      }
    },
    [
      controls,
      data?.episodeId,
      data?.remotePodcastId,
      episodeId,
      loadPlayerAudio,
    ],
  );

  if (data && !data.descriptionHtml) {
    return null;
  }

  if (!data) {
    return (
      <div className={styles.root}>
        <div className={styles.description}>
          <Skeleton width="100%" height="125px" />
        </div>
      </div>
    );
  }

  const [path, pathOpts] = createPodcastPath(data.remotePodcastId);

  return (
    <div className={styles.root}>
      {/* eslint-disable
      jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/no-danger
      */}
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: data.descriptionHtml }}
        onClick={handleClick}
      />
      {/* eslint-enable */}
      <Divider className={styles.divider} />
      <EpisodeSuggestions />
      <Link className={styles.link} state={pathOpts} to={path}>
        <Chip className={styles.cta}>see all episodes from this podcast</Chip>
      </Link>
    </div>
  );
};

export default EpisodeDescription;
