import lodashMerge from 'lodash/merge';
import { PartialDeep } from 'type-fest';
import { BaseAudioPlayer } from 'utils/audio/types';
import { PlaybackRate } from '../useAudioPlayerControls/types';
import {
  PlayerIds,
  SponsoredEpisodeState,
  UseEpisodeAudioPlayerAction,
} from './types';

export interface UseEpisodeAudioPlayerState {
  player: BaseAudioPlayer | undefined;
  playerIds: PlayerIds | undefined;
  sponsoredEpisode: SponsoredEpisodeState;
  playedFromQueueOverride: boolean | undefined;
  sourceWidgetId: string | undefined;
  playbackStartTime: number;
  playbackRate: PlaybackRate;
}

export const INITIAL_STATE: UseEpisodeAudioPlayerState = {
  player: undefined,
  playerIds: undefined,
  sponsoredEpisode: {
    isEpisodeSponsored: false,
    sponsoredEpisodeWidgetInfo: undefined,
  },
  playedFromQueueOverride: undefined,
  sourceWidgetId: undefined,
  playbackStartTime: 0,
  playbackRate: 1,
};

export default function reducer(
  state: UseEpisodeAudioPlayerState,
  action: UseEpisodeAudioPlayerAction,
): UseEpisodeAudioPlayerState {
  const update = (overrides: PartialDeep<UseEpisodeAudioPlayerState>) =>
    lodashMerge({}, state, overrides);

  switch (action.type) {
    case 'loadPlayer': {
      const { audioPlayer } = action.payload;

      return update({ player: audioPlayer });
    }

    case 'initiatePlayback': {
      const {
        episodeId,
        podcastId,
        startTime,
        sponsoredEpisode,
        isPlayedFromQueue,
      } = action.payload;

      return update({
        playerIds: { episodeId, podcastId },
        player: undefined,
        playbackStartTime: startTime ?? 0,
        sponsoredEpisode,
        playedFromQueueOverride: !!isPlayedFromQueue,
        playbackRate: (state.player?.playbackRate as PlaybackRate) ?? 1,
      });
    }

    case 'setSourceWidget': {
      const { sourceWidgetId } = action.payload;
      return update({
        sourceWidgetId,
      });
    }

    default:
      return state;
  }
}
