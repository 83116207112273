import React, { useCallback, useContext, useMemo } from 'react';

import { millisToSec } from 'utils/time';
import { useGetAudioDetails } from '../../useGetEpisodeDetails';
import { AudioPlayerProvider, useAudioPlayer } from '../AudioPlayerContext';
import {
  EpisodeAudioPlayerContextType,
  EpisodeAudioPlayerProviderProps,
  LoadPlayerAudio,
  UseEpisodeAudioPlayerResult,
} from './types';
import useEpisodeAudioPlayerState from './useEpisodeAudioPlayerState';

const EpisodeAudioPlayerContext = React.createContext<
  EpisodeAudioPlayerContextType | undefined
>(undefined);

// this component wraps an AudioPlayerProvider and initializes it with audio data from
// a podcast episode
export const EpisodeAudioPlayerProvider: React.FC<
  EpisodeAudioPlayerProviderProps
> = ({ audioElement, children }) => {
  const [state, dispatch] = useEpisodeAudioPlayerState({ audioElement });

  const {
    playerIds,
    player,
    sponsoredEpisode,
    playedFromQueueOverride,
    sourceWidgetId,
  } = state;
  const { data } = useGetAudioDetails({
    episodeId: playerIds?.episodeId,
    podcastId: playerIds?.podcastId,
  });
  // chapters reference has to be stable and not change unnecessarily since that
  // will cause some issues with context consumers
  const chapters = useMemo(() => data?.chapters, [data?.chapters]);

  const handleLoadPlayerAudio = useCallback(
    ({
      episodeId,
      podcastId,
      startTime = 0,
      isSponsored = false,
      sponsoredWidgetInfo,
      isPlayedFromQueue,
    }: LoadPlayerAudio): void => {
      dispatch({
        type: 'initiatePlayback',
        payload: {
          podcastId,
          episodeId,
          startTime,
          sponsoredEpisode: {
            isEpisodeSponsored: isSponsored,
            sponsoredEpisodeWidgetInfo: sponsoredWidgetInfo,
          },
          isPlayedFromQueue,
        },
      });
    },
    [dispatch],
  );

  const handleSourceWidgetId = useCallback(
    (id: string | undefined) => {
      dispatch({ type: 'setSourceWidget', payload: { sourceWidgetId: id } });
    },
    [dispatch],
  );

  return (
    <EpisodeAudioPlayerContext.Provider
      value={useMemo(
        () => ({
          loadPlayerAudio: handleLoadPlayerAudio,
          setSourceWidgetId: handleSourceWidgetId,
        }),
        [handleLoadPlayerAudio, handleSourceWidgetId],
      )}
    >
      <AudioPlayerProvider
        chapters={chapters}
        defaultDuration={millisToSec(data?.audioDurationMillis)}
        episodeId={playerIds?.episodeId}
        player={player}
        podcastId={playerIds?.podcastId}
        isEpisodeSponsored={sponsoredEpisode.isEpisodeSponsored}
        sponsoredEpisodeWidgetInfo={sponsoredEpisode.sponsoredEpisodeWidgetInfo}
        playedFromQueueOverride={playedFromQueueOverride}
        sourceWidgetId={sourceWidgetId}
      >
        {children}
      </AudioPlayerProvider>
    </EpisodeAudioPlayerContext.Provider>
  );
};

export function useEpisodeAudioPlayer(): UseEpisodeAudioPlayerResult {
  const context = useContext(EpisodeAudioPlayerContext);
  const audioPlayerContext = useAudioPlayer();

  if (context === undefined) {
    throw new Error(
      'useEpisodeAudioPlayer must be used within EpisodeAudioPlayerProvider',
    );
  }

  return {
    ...context,
    ...audioPlayerContext,
  };
}
