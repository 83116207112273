import Page from 'components/Page';
import styles from './HomePage.module.scss';
import HomepageContents from './HomepageContents';
import useCheckFeedFollowUnsubscribeStatus from './useCheckFeedFollowUnsubscribeStatus';
import { useHomePage } from './useHomePage';

export interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = () => {
  useCheckFeedFollowUnsubscribeStatus();
  useHomePage();

  return (
    <Page fluid className={styles.root}>
      <HomepageContents />
    </Page>
  );
};

export default HomePage;
