import { podcastTitleSelector } from 'api/selectors/podcast';
import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryOptions } from 'types';
import { GetPodcastDetailsResponse, getPodcastDetails } from '../podcast';
import { podcastQueryKeys } from './queries';

export type Options<TData = GetPodcastDetailsResponse> =
  UseInfiniteQueryOptions<
    GetPodcastDetailsResponse,
    ReturnType<typeof podcastQueryKeys.details>,
    AxiosError | Error,
    TData
  >;

export default function useGetPodcastDetail<TData = GetPodcastDetailsResponse>(
  podcastId: string = '',
  opts?: Options<TData>,
) {
  return useInfiniteQuery(
    podcastQueryKeys.details(podcastId),
    ({ pageParam, queryKey: [key] }) =>
      getPodcastDetails(String(key.podcastId), false, true, {
        nextEpisodePubAtMillis: pageParam,
      }),
    {
      ...opts,
      getNextPageParam: (lastPage) =>
        lastPage?.nextEpisodePubAtMillis ?? undefined,
      enabled: (opts?.enabled ?? true) && !!podcastId,
    },
  );
}

export function useGetPodcastTitle(podcastId: string | undefined) {
  const { data, ...rest } = useGetPodcastDetail(podcastId, {
    select: podcastTitleSelector,
  });
  return { data: data?.pages[0], ...rest };
}
