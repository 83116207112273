import BlurredBackgroundImage, {
  BlurredBackgroundImageProps,
} from 'components/BlurredBackgroundImage';
import Spacer from 'components/Spacer';
import { useBreakpoints } from 'context/BreakpointsContext';
import { motion } from 'framer-motion';
import React from 'react';
import AppHeaderTopbar from '../AppHeaderTopbar';
import styles from './AppHeader.module.scss';
import { useAppHeaderAnimation } from './AppHeaderAnimationContext';
import Logo from './Logo';

export interface AppHeaderProps {
  backgroundImageUrl: BlurredBackgroundImageProps['src'];
  children?: React.ReactNode;
  topCenterElement?: React.ReactNode;
  topRightElement?: React.ReactNode;
}

const AppHeader: React.FC<AppHeaderProps> = ({
  backgroundImageUrl,
  children,
  topCenterElement,
  topRightElement,
}) => {
  const { md } = useBreakpoints();
  const animation = useAppHeaderAnimation();

  return (
    <div className={styles.root}>
      <motion.div className={styles.contents} {...animation.header}>
        <BlurredBackgroundImage
          alt="header background"
          className={styles.background}
          src={backgroundImageUrl}
        />

        <AppHeaderTopbar
          topLeftElement={<Logo />}
          topCenterElement={
            <motion.div {...animation.topCenter}>{topCenterElement}</motion.div>
          }
          topRightElement={
            <motion.div {...animation.topRight}>{topRightElement}</motion.div>
          }
        />

        <Spacer
          className={styles.contentsWrapper}
          orientation={md ? 'vertical' : 'horizontal'}
          space={2}
        >
          {children}
        </Spacer>
      </motion.div>
      <div className={styles.fader} />
    </div>
  );
};

export default AppHeader;
