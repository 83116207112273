function boolean(val: string | undefined, fallback: boolean) {
  if (val === undefined) {
    return fallback;
  }

  const lowercaseVal = val.toLowerCase();
  return lowercaseVal === 'true';
}

// api
export const PLAY_SERVICE_URL = process.env.REACT_APP_PLAY_SERVICE_URL;
export const PODCAST_SERVICE_URL = process.env.REACT_APP_PODCAST_SERVICE_URL;
export const HEADLINER_USER_SERVICE_URL =
  process.env.REACT_APP_HEADLINER_USER_SERVICE_URL;
export const AUTH_USER_SERVICE_URL = process.env.REACT_APP_USER_SERVICE_URL;

// Apple
export const APPLE_AUTH_REDIRECT_URI =
  process.env.REACT_APP_APPLE_AUTH_REDIRECT_URI;

// mixpanel
export const MIXPANEL_API_HOST = process.env.REACT_APP_MIXPANEL_API_HOST;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const WEBAPP_MIXPANEL_TOKEN =
  process.env.REACT_APP_WEBAPP_MIXPANEL_TOKEN;
export const MIXPANEL_DEBUG = boolean(
  process.env.REACT_APP_MIXPANEL_DEBUG,
  false,
);
// misc
export const RUNTIME_ENV = process.env.REACT_APP_RUNTIME_ENV;

// ga
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

// app
export const { PUBLIC_URL } = process.env;

// headliner
export const HEADLINER_URL = process.env.REACT_APP_HEADLINER_URL;

// partners
export const DASHBOARD_PLAY_URL = process.env.REACT_APP_DASHBOARD_PLAY_URL;
