import { ExperimentResponse } from 'api/headlinerUser';
import useExperimentVariants, {
  Options,
} from 'api/hooks/useExperimentVariants';
import { keyBy } from 'lodash';
import UNIQUE_SESSION_ID from 'utils/user';
import { EXPERIMENTS } from './constants';
import { ActiveExperimentsByName } from './types';

function select(data: ExperimentResponse): ActiveExperimentsByName {
  // lodash won't get this type right.  for one, the return type of keyBy is a
  // Dictionary, which specifies keys as string, which is wider than what we want.
  return keyBy(data, 'experimentName') as unknown as any;
}

export function useExperiments(
  options?: Omit<Options<ActiveExperimentsByName>, 'select'>,
) {
  return useExperimentVariants<ActiveExperimentsByName>(
    EXPERIMENTS,
    UNIQUE_SESSION_ID,
    {
      select,
      enabled: Boolean(EXPERIMENTS.length),
      ...options,
    },
  );
}
