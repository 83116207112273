import { Back15OutlinedIcon } from 'components/icons';
import { useEventTracking } from 'context/EventTrackingContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import React from 'react';
import { PLAYER_SKIP_SECONDS } from 'utils/constants';
import ControlButton from '../ControlButton';

type SkipBackButtonProps = {
  className?: string;
};

const SkipBackButton: React.FC<SkipBackButtonProps> = ({ className }) => {
  const { controls, ready } = useAudioPlayer();
  const { trackSeek } = useEventTracking();

  return (
    <ControlButton
      aria-label="back 15 seconds"
      className={className}
      disabled={!ready}
      onClick={() => {
        if (controls) {
          controls.seekDelta(-PLAYER_SKIP_SECONDS);
          trackSeek('Back15Sec');
        }
      }}
      tooltip="Skip backwards 15 seconds"
    >
      <Back15OutlinedIcon style={{ width: '100%' }} />
    </ControlButton>
  );
};
export default SkipBackButton;
