import { Button, ButtonProps, Plus } from '@sparemin/blockhead';
import useAddFeedFollow from 'api/hooks/useAddFeedFollow';
import { useIsFollowingFeed } from 'api/hooks/useGetFeedFollow';
import useRemoveFeedFollows from 'api/hooks/useRemoveFeedFollow';
import { notifyError, notifySuccess } from 'components/notification';
import { useAuth } from 'context/AuthContext';
import { useEventTracking } from 'context/EventTrackingContext';
import { FollowPodcastSource } from 'context/EventTrackingContext/types';
import { useFollowPodcastPrompt } from 'context/FollowPodcastContext';
import { useWithAuthentication } from 'hooks/useWithAuthentication';
import { over } from 'lodash';
import { GENERIC_ERROR_MESSAGE } from 'utils/constants';
import {
  FOLLOW_FEED_NOTIFICATION_TIMING,
  FOLLOW_FEED_SUCCESS_SUBHEADING,
} from './constants';

export interface FollowButtonProps extends ButtonProps {
  source: FollowPodcastSource;
  feedId: number;
  podcastName: string;
  className?: string;
}

const FollowButton: React.FC<FollowButtonProps> = ({
  source,
  feedId,
  podcastName,
  color = 'dark',
  variant = 'frosted',
  ...props
}) => {
  const { close } = useFollowPodcastPrompt();
  const { data: isFollowing } = useIsFollowingFeed(feedId);

  const { trackFollowPodcast, trackUnfollowPodcast } = useEventTracking();
  const { isAuthenticated } = useAuth();
  const { createAuthenticatedHandler } = useWithAuthentication();

  const getFollowSuccessText = () => `You're now following ${podcastName}!`;

  const getUnfollowSuccessText = () =>
    `You're no longer following ${podcastName}.`;

  const followFeed = useAddFeedFollow({
    onSuccess: () =>
      notifySuccess({
        heading: getFollowSuccessText(),
        subHeading: FOLLOW_FEED_SUCCESS_SUBHEADING,

        autoClose: FOLLOW_FEED_NOTIFICATION_TIMING,
      }),
    onError: () => notifyError({ heading: GENERIC_ERROR_MESSAGE }),
  });
  const unfollowFeed = useRemoveFeedFollows();

  const handleFollow = () => {
    followFeed.mutate(feedId);
    trackFollowPodcast(podcastName, source);
  };

  const handleUnfollow = () => {
    unfollowFeed.mutate(feedId, {
      onSuccess: () => notifySuccess({ heading: getUnfollowSuccessText() }),
      onError: () => notifyError({ heading: GENERIC_ERROR_MESSAGE }),
    });
    trackUnfollowPodcast(podcastName, source);
  };
  const handleClick = () => {
    if (!isFollowing) handleFollow();
    else handleUnfollow();
  };

  return (
    <Button
      size="large"
      color={color}
      variant={variant}
      onPress={over([createAuthenticatedHandler(handleClick), close])}
      startIcon={!isFollowing || !isAuthenticated ? <Plus /> : null}
      {...props}
    >
      {isFollowing && isAuthenticated ? 'following' : 'follow'}
    </Button>
  );
};
export default FollowButton;
