import { useEffect, useState } from 'react';
import { formatLocalDate } from 'utils/time';

export default function useDate(millis: number | undefined) {
  const [formattedDate, setFormattetdDate] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    let active = true;

    async function formatDate() {
      if (!millis) {
        setFormattetdDate(undefined);
      } else {
        const date = await formatLocalDate(millis);

        if (active) {
          setFormattetdDate(date);
        }
      }
    }

    formatDate();

    return () => {
      active = false;
    };
  }, [millis]);

  return formattedDate;
}
