import { IconButton, Tooltip } from '@sparemin/blockhead';
import LightningGradientCircle from 'components/icons/LightningGradientCircle';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useModalControls } from 'context/ModalContext';
import React from 'react';

type OpenSponsoredEpisodeModalButtonProps = {
  onOpen?: () => void;
  onClose?: () => void;
};

const OpenSponsoredEpisodeModalButton: React.FC<
  OpenSponsoredEpisodeModalButtonProps
> = ({ onOpen, onClose }) => {
  const { open } = useModalControls();
  const { md } = useBreakpoints();

  const handleOpen = () => {
    onOpen?.();
    open({ name: 'SponsoredEpisode', onAfterClose: () => onClose?.() });
  };

  return (
    <Tooltip contents="Sponsored episode" isDisabled={md} arrow>
      <IconButton size="small" onPress={handleOpen}>
        <LightningGradientCircle />
      </IconButton>
    </Tooltip>
  );
};
export default OpenSponsoredEpisodeModalButton;
