import { HeadlinerLogo, HeadySolid } from 'components/icons';
import { useBreakpoints } from 'context/BreakpointsContext';
import styles from './Logo.module.scss';

export interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  const { md } = useBreakpoints();
  return (
    <div className={className}>
      {md ? (
        <HeadySolid className={styles.icon} />
      ) : (
        <HeadlinerLogo className={styles.icon} />
      )}
    </div>
  );
};

export default Logo;
