import React, { useContext } from 'react';
import { AudioPlayerContextType, AudioPlayerProviderProps } from './types';
import useAudioPlayerContextValue from './useAudioPlayerContextValue';

const AudioPlayerContext = React.createContext<
  AudioPlayerContextType | undefined
>(undefined);

// The AudioPlayerContext should not have any specialized knowlege of "episodes"
// or anything else from our API, so a few props are passed in that could
// theoretically be read from various other hoooks that know about episode information
export const AudioPlayerProvider: React.FC<AudioPlayerProviderProps> = (
  props,
) => {
  const { children } = props;

  return (
    <AudioPlayerContext.Provider value={useAudioPlayerContextValue(props)}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export function useAudioPlayer() {
  const context = useContext(AudioPlayerContext);

  if (context === undefined) {
    throw new Error('useAudioPlayer must be used within AudioPlayerProvider');
  }

  return context;
}
