import 'simplebar-react/dist/simplebar.min.css';

import { Container, Dialog, useToggle } from '@sparemin/blockhead';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import useListeningQueue from 'pages/AudioPlayerPage/state/ListeningQueueContext';
import React from 'react';
import SimpleBar from 'simplebar-react';
import Contents from './Contents';
import Header from './Header';
import styles from './ListeningQueue.module.scss';
import useListeningQueueAnimation from './useListeningQueueAnimation';

export type ListeningQueueProps = {
  className?: string;
};

const ListeningQueue: React.FC<ListeningQueueProps> = ({ className }) => {
  const animation = useListeningQueueAnimation();
  const { show, hide } = useListeningQueue();

  const {
    value: showDialog,
    toggleOn: openDialog,
    toggleOff: closeDialog,
  } = useToggle(false);

  if (show && !showDialog) {
    openDialog();
  }

  return (
    <Dialog isOpen={showDialog} isDismissable onClose={hide}>
      <AnimatePresence onExitComplete={closeDialog}>
        {show && (
          <motion.div
            className={cn(styles.root, className)}
            {...animation}
            key="queue"
          >
            <Header className={styles.header}>Up next</Header>
            <Container as={SimpleBar} className={styles.container} autoHide>
              <Contents />
            </Container>
          </motion.div>
        )}
      </AnimatePresence>
    </Dialog>
  );
};

export default ListeningQueue;
