import lodashMerge from 'lodash/merge';
import { PartialDeep } from 'type-fest';
import { ANIMATION_DURATION_LONG_SEC } from 'utils/constants';
import {
  ScrollConfig,
  UseAudioPlayerAction,
  UseAudioPlayerState,
} from './types';

export const DEFAULT_SCROLL_CONFIG: ScrollConfig = {
  scroll: false,
  delay: ANIMATION_DURATION_LONG_SEC,
  duration: ANIMATION_DURATION_LONG_SEC,
};

export const INITIAL_STATE: UseAudioPlayerState = {
  currentChapterIndex: null,
  duration: undefined,
  loading: false,
  paused: true,
  ready: false,
  scrollChapterIndex: null,
  scrollOptions: DEFAULT_SCROLL_CONFIG,
  hasNextChapter: false,
  hasPrevChapter: false,
};

function getScrollOptions(
  state: UseAudioPlayerState,
  overrides?: Partial<ScrollConfig>,
): ScrollConfig {
  if (!overrides) {
    return state.scrollOptions;
  }

  return lodashMerge({}, DEFAULT_SCROLL_CONFIG, overrides);
}

export default function reducer(
  state: UseAudioPlayerState,
  action: UseAudioPlayerAction,
): UseAudioPlayerState {
  const update = (overrides: PartialDeep<UseAudioPlayerState>) =>
    lodashMerge({}, state, overrides);

  switch (action.type) {
    case 'canplay':
      return update({ ready: true });

    case 'play': {
      const { chapterIndex, scroll } = action.payload;
      return update({
        loading: true,
        paused: false,
        currentChapterIndex: chapterIndex,
        scrollChapterIndex: scroll?.scroll ? chapterIndex : undefined,
        scrollOptions: getScrollOptions(state, scroll),
      });
    }

    case 'playchapter': {
      const { chapterIndex, scroll, chapters } = action.payload;

      return update({
        currentChapterIndex: chapterIndex,
        scrollChapterIndex: scroll?.scroll ? chapterIndex : undefined,
        scrollOptions: getScrollOptions(state, scroll),
        hasNextChapter: chapterIndex + 1 < (chapters?.length ?? 0),
        hasPrevChapter: chapterIndex - 1 >= 0,
      });
    }

    case 'pause':
      return update({ scrollChapterIndex: null, paused: true });

    case 'playing':
      return update({ loading: false });

    case 'partiallyloaded': {
      const { defaultDuration } = action.payload;
      return update({ duration: defaultDuration });
    }

    case 'loaded': {
      const { duration } = action.payload;
      return update({ duration });
    }

    case 'resetplayback':
      return update({
        currentChapterIndex: null,
        scrollChapterIndex: null,
        loading: false,
        paused: true,
        ready: false,
      });

    case 'scrollchapter': {
      const { chapterIndex, ...scroll } = action.payload;
      return update({
        scrollChapterIndex: chapterIndex,
        scrollOptions: getScrollOptions(state, {
          scroll: true,
          ...scroll,
        }),
      });
    }

    default:
      return state;
  }
}
