import { animate, AnimationOptions, motionValue } from 'framer-motion';
import scrollIntoView, {
  SmoothBehaviorOptions,
} from 'smooth-scroll-into-view-if-needed';

/**
 * smooth scrolling is achieved by a ponyfill called smooth-scroll-into-view-if-needed.
 * this library supports customizing the scroll animation, which is done using
 * framer-motion in this app since framer-motion is already included for other
 * UI animations and transitions
 *
 * @see https://github.com/stipsan/scroll-into-view-if-needed/tree/v2.2.29#function
 */
export function smoothScrollIntoView(
  target: Element,
  opts?: Omit<SmoothBehaviorOptions, 'behavior' | 'duration' | 'ease'> & {
    delay?: number;
    duration?: number;
    offsetX?: number;
    offsetY?: number;
  },
) {
  const {
    delay,
    duration,
    offsetX = 0,
    offsetY = 0,
    ...scrollLibOpts
  } = opts || {};

  const animateOpts: AnimationOptions<number> = {
    delay,
    duration,
    type: 'tween',
    ease: 'easeOut',
  };

  scrollIntoView(target, {
    behavior: (actions) => {
      actions.forEach(({ el, left, top }) => {
        const leftMotionValue = motionValue(el.scrollLeft);
        const topMotionValue = motionValue(el.scrollTop);

        animate(topMotionValue, top + offsetY, {
          ...animateOpts,
          onUpdate: (current) => {
            // eslint-disable-next-line no-param-reassign
            el.scrollTop = current;
          },
        });

        animate(leftMotionValue, left + offsetX, {
          ...animateOpts,
          onUpdate: (current) => {
            // eslint-disable-next-line no-param-reassign
            el.scrollLeft = current;
          },
        });
      });
    },
    ...scrollLibOpts,
  });
}
