import React, { Suspense } from 'react';
import { isPreRendering } from 'utils/prerender';

const EventTrackingProvider = React.lazy(
  () => import('./EventTrackingProvider'),
);
const NoopEventTrackingProvider = React.lazy(
  () => import('./NoopEventTrackingProvider'),
);

export type EventTrackingProviderContainerProps = any;

const EventTrackingProviderContainer: React.FC<
  EventTrackingProviderContainerProps
> = (props) => {
  const Wrapper = isPreRendering
    ? NoopEventTrackingProvider
    : EventTrackingProvider;

  return (
    <Suspense fallback={null}>
      <Wrapper {...props} />
    </Suspense>
  );
};

export default EventTrackingProviderContainer;
