import useMediaQuery from 'hooks/useMediaQuery';
import React, { useContext, useMemo } from 'react';
import { Breakpoint } from 'types';
import { MD_SCREEN_MEDIA_QUERY, XS_SCREEN_MEDIA_QUERY } from 'utils/constants';
import { BreakpointsContextType, BreakpointsProviderProps } from './types';

const BreakpointsContext = React.createContext<
  BreakpointsContextType | undefined
>(undefined);

export const BreakpointsProvider: React.FC<BreakpointsProviderProps> = ({
  children,
}) => {
  const xs = useMediaQuery(XS_SCREEN_MEDIA_QUERY);
  const md = useMediaQuery(MD_SCREEN_MEDIA_QUERY);

  const activeBreakpoints = useMemo(
    () => [md && 'md', xs && 'xs'].filter(Boolean),
    [md, xs],
  ) as Breakpoint[];

  const value = useMemo(
    () => ({
      activeBreakpoints,
      md: activeBreakpoints.includes('md'),
      xs: activeBreakpoints.includes('xs'),
    }),
    [activeBreakpoints],
  );

  return (
    <BreakpointsContext.Provider value={value}>
      {children}
    </BreakpointsContext.Provider>
  );
};

export function useBreakpoints() {
  const context = useContext(BreakpointsContext);

  if (context === undefined) {
    throw new Error('useBreakpoints must be used within BreakpointsProvider');
  }

  return context;
}
