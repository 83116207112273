import { BackStepOutlinedIcon } from 'components/icons';
import { useEventTracking } from 'context/EventTrackingContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import React from 'react';
import ControlButton from '../ControlButton';

type PreviousChapterButtonProps = {
  className?: string;
};

const PreviousChapterButton: React.FC<PreviousChapterButtonProps> = ({
  className,
}) => {
  const { controls, ready, hasPrevChapter } = useAudioPlayer();
  const { trackSeek } = useEventTracking();

  return (
    <ControlButton
      aria-label="previous chapter"
      className={className}
      disabled={!ready || !hasPrevChapter}
      onClick={() => {
        if (controls) {
          controls.prevChapter({ scroll: true });
          trackSeek('PreviousPart');
        }
      }}
      tooltip="Previous chapter"
    >
      <BackStepOutlinedIcon style={{ width: '100%' }} />
    </ControlButton>
  );
};
export default PreviousChapterButton;
