import { ApplePodcast, Spotify } from '@sparemin/blockhead';
import styles from './LinkIcon.module.scss';

export const linkInfo = {
  Apple: { label: 'itunes', icon: <ApplePodcast className={styles.apple} /> },
  Spotify: {
    label: 'spotify',
    icon: <Spotify className={styles.spotify} />,
  },
};
