import { useEffect, useState } from 'react';

export default function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mqList = window.matchMedia(query);

    const handleChange = () => {
      setMatches(mqList.matches);
    };

    setMatches((currentMatches) =>
      currentMatches === mqList.matches ? currentMatches : mqList.matches,
    );

    mqList.addEventListener('change', handleChange);

    return () => {
      mqList.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
}
