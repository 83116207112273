import { useCallback, useMemo } from 'react';
import {
  AudioPlayerControls,
  PlaybackRate,
  UseAudioPlayerControlsConfig,
} from './types';

/*
 * This hook exposes all of the controls needed to interact with the audio player
 * so that components can control playback without interacting directly with
 * the BaseAudioPlayer.
 */
export default function useAudioPlayerControls({
  player,
}: UseAudioPlayerControlsConfig): AudioPlayerControls | undefined {
  const handleCurrentTime = useCallback(() => player?.currentTime(), [player]);
  const handlePlay = useCallback(async () => player?.play(), [player]);
  const handlePause = useCallback(() => player?.pause(), [player]);
  const handleSeek = useCallback(
    (sec: number) => {
      player?.seek(sec);
    },
    [player],
  );
  const handleTogglePlay = useCallback(
    async () => player?.togglePlay(),
    [player],
  );

  const handleSeekDelta = useCallback(
    (deltaSec: number) => {
      const time = handleCurrentTime();

      if (time !== undefined) {
        handleSeek(time + deltaSec);
      }
    },
    [handleCurrentTime, handleSeek],
  );

  const handleSetPlaybackRate = useCallback(
    (val: PlaybackRate) => {
      player?.setPlaybackRate(val);
    },
    [player],
  );

  const controls = useMemo(
    () => ({
      currentTime: handleCurrentTime,
      pause: handlePause,
      play: handlePlay,
      seek: handleSeek,
      seekDelta: handleSeekDelta,
      togglePlay: handleTogglePlay,
      setPlaybackRate: handleSetPlaybackRate,
    }),
    [
      handleCurrentTime,
      handlePause,
      handlePlay,
      handleSeek,
      handleSeekDelta,
      handleTogglePlay,
      handleSetPlaybackRate,
    ],
  );

  return !player ? undefined : controls;
}
