export function setValue(key: string, value: string): void {
  localStorage.setItem(key, value);
}

export function getValue(key: string): string | undefined {
  return localStorage.getItem(key) ?? undefined;
}

export function setObject(key: string, value: unknown): void {
  setValue(key, JSON.stringify(value));
}

export function getObject<T>(key: string): T | undefined {
  const value = getValue(key);
  if (value) {
    return JSON.parse(value);
  }
  return undefined;
}
