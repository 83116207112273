import IconButton, { IconButtonProps } from 'components/IconButton';
import { PauseIcon, PlayIcon } from 'components/icons';
import React from 'react';
import { OverridableComponentProps } from 'types/react';

type Props<As extends React.ElementType = 'button'> = IconButtonProps<As> & {
  playing?: boolean;
};

export type BigPlayButtonProps<As extends React.ElementType = 'button'> =
  OverridableComponentProps<Props<As>, As>;

function BigPlayButton<As extends React.ElementType = 'button'>(
  props: BigPlayButtonProps<As>,
) {
  const { playing, ...rest } = props;
  return (
    <IconButton color="dark" type="button" variant="contained" {...rest}>
      {playing ? (
        <PauseIcon style={{ width: 16 }} />
      ) : (
        <PlayIcon style={{ marginLeft: '0.2em' }} />
      )}
    </IconButton>
  );
}

export default BigPlayButton;
