import Popover from 'components/Popover';
import { Arrow, useTrackingTooltip } from 'components/Tooltip';
import { Text } from 'components/Typography';
import useHoverable from 'hooks/useHoverable';

export interface UseTooltipContents {
  contents: React.ReactNode;
  show: boolean;
}

export default function useTooltip({ show, contents }: UseTooltipContents) {
  const { arrowRef, attributes, onMouseMove, styles, popperRef } =
    useTrackingTooltip();
  const hoverable = useHoverable();

  const tooltip = (
    <Popover
      attributes={attributes.popper}
      ref={popperRef}
      show={show && hoverable}
      style={styles.popper}
      transitionDurationMillis={50}
    >
      <Text type="label">{contents}</Text>
      <Arrow ref={arrowRef} style={styles.arrow} />
    </Popover>
  );

  return {
    onMouseMove,
    tooltip,
  };
}
