import { Spacer } from '@sparemin/blockhead';
import AppHeader, {
  AppHeaderArtwork,
  AppHeaderContents,
  AppHeaderDescription,
  AppHeaderFooter,
  AppHeaderSubtitle,
  AppHeaderTitle,
  AppHeaderUserButtons,
} from 'components/AppHeader';
import SearchButton from 'components/SearchButton';
import UserAccountControl from 'components/UserAccountControl';
import { useBreakpoints } from 'context/BreakpointsContext';
import { motion } from 'framer-motion';
import PodcastShareButton from '../PodcastShareButton';
import usePagePodcastDetail from '../usePagePodcastDetail';
import { useAnimation } from './AnimationContext';
import styles from './Header.module.scss';

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { data: podcastDetailsData, isLoading } = usePagePodcastDetail();
  const hasData = !!podcastDetailsData;
  const nEpisodes = podcastDetailsData?.totalEpisodes;
  const podcastTitle = podcastDetailsData?.title;
  const feedId = podcastDetailsData?.podcastFeedId;
  const { md } = useBreakpoints();
  const animation = useAnimation();

  const userButtons = feedId ? (
    <AppHeaderUserButtons
      source="PodcastPage"
      feedId={feedId}
      itunesUrl={podcastDetailsData?.itunesUrl ?? undefined}
      podcastName={podcastTitle as string}
      spotifyUrl={podcastDetailsData.spotifyUrl ?? undefined}
      shareButton={<PodcastShareButton />}
    />
  ) : null;

  return (
    <AppHeader
      backgroundImageUrl={podcastDetailsData?.thumbnailUrl}
      topCenterElement={<SearchButton />}
      topRightElement={<UserAccountControl />}
    >
      <AppHeaderContents>
        <AppHeaderArtwork src={podcastDetailsData?.thumbnailUrl} />
        <Spacer
          className={styles.content}
          justify="space-between"
          align="center"
        >
          <AppHeaderDescription
            isLoading={isLoading}
            footer={
              <AppHeaderFooter
                parts={
                  !hasData
                    ? undefined
                    : [
                        podcastDetailsData?.topCategory,
                        nEpisodes === 1 ? '1 episode' : `${nEpisodes} episodes`,
                      ]
                }
              />
            }
            subtitle={
              <AppHeaderSubtitle>{podcastDetailsData?.title}</AppHeaderSubtitle>
            }
            title={
              <AppHeaderTitle>{podcastDetailsData?.publisher}</AppHeaderTitle>
            }
          />
          {!md ? (
            <motion.div {...animation.buttonBox}>{userButtons}</motion.div>
          ) : null}
        </Spacer>
      </AppHeaderContents>
      {md ? userButtons : null}
    </AppHeader>
  );
};

export default Header;
