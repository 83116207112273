import { getPodcastEpisodes, GetPodcastEpisodesResponse } from 'api/podcast';
import { useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryOptions } from 'types';
import { podcastQueryKeys } from './queries';

export type UseGetPodcastEpisodesOptions<TData = GetPodcastEpisodesResponse> =
  UseInfiniteQueryOptions<
    GetPodcastEpisodesResponse,
    ReturnType<typeof podcastQueryKeys.episodes>,
    unknown,
    TData
  >;

export function useGetPodcastEpisodes<TData = GetPodcastEpisodesResponse>(
  podcastId?: string | number,
  opts?: UseGetPodcastEpisodesOptions<TData>,
) {
  return useInfiniteQuery(
    podcastQueryKeys.episodes(podcastId),
    ({ pageParam, queryKey: [key] }) =>
      getPodcastEpisodes(key.podcastId as string | number, {
        includeDescription: true,
        nextEpisodePubAtMillis: pageParam,
      }),
    {
      ...opts,
      getNextPageParam: (lastPage) =>
        lastPage?.nextEpisodePubAtMillis ?? undefined,
      enabled: (opts?.enabled ?? true) && !!podcastId,
    },
  );
}
