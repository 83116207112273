import useGetPodcastEpisodeDetail, {
  UseGetPodcastEpisodeDetailConfig,
} from 'api/hooks/useGetPodcastEpisodeDetail';
import {
  audioDetailsSelector,
  episodeDetailSelector,
  episodeTitleSelector,
} from './selectors';

export function useGetEpisodeDetails(props: UseGetPodcastEpisodeDetailConfig) {
  return useGetPodcastEpisodeDetail(props, {
    select: episodeDetailSelector,
  });
}

export function useGetEpisodeTitle(props: UseGetPodcastEpisodeDetailConfig) {
  return useGetPodcastEpisodeDetail(props, { select: episodeTitleSelector });
}

export function useGetAudioDetails(props: UseGetPodcastEpisodeDetailConfig) {
  return useGetPodcastEpisodeDetail(props, {
    select: audioDetailsSelector,
  });
}
