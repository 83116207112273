import { Text } from 'components/Typography';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import { useAppHeaderAnimation } from '../AppHeaderAnimationContext';
import { useAppHeaderDescription } from '../AppHeaderDescriptionContext';
import styles from './AppHeaderFooter.module.scss';

export interface AppHeaderFooterProps {
  parts: [string | undefined, string | undefined] | [] | undefined;
}

const AppHeaderFooter: React.FC<AppHeaderFooterProps> = ({ parts }) => {
  const animation = useAppHeaderAnimation();
  const { isLoading } = useAppHeaderDescription();

  const filteredFooter = (parts?.filter(Boolean) ?? []) as
    | []
    | [string, string];

  return !isLoading && filteredFooter.length === 0 ? null : (
    <Text
      as={motion.span}
      className={styles.root}
      type="details"
      {...animation.footer}
    >
      {isLoading ? (
        <Skeleton width={100} />
      ) : (
        <>
          {filteredFooter[0]}&nbsp;&nbsp; • &nbsp;&nbsp;{filteredFooter[1]}
        </>
      )}
    </Text>
  );
};

export default AppHeaderFooter;
