import React, { useContext, useMemo, useReducer } from 'react';
import { DEFAULT_STATE } from './constants';
import reducer from './reducer';
import { ModalContextType, ModalProviderProps } from './types';

const ModalContext = React.createContext<ModalContextType | undefined>(
  undefined,
);

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  return (
    <ModalContext.Provider
      value={useMemo(() => ({ dispatch, state }), [state])}
    >
      {children}
    </ModalContext.Provider>
  );
};

export function useModal() {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModal must be used within ModalProvider');
  }

  return context;
}
