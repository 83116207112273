import React from 'react';
import { Navigate } from 'react-router-dom';
import { createPodcastPath, createRedirectPath } from 'utils/routes';
import usePodcastLegacyPage from './usePodcastLegacyPage';

const PodcastLegacyPage: React.FunctionComponent = () => {
  const { podcastId, search, state } = usePodcastLegacyPage();
  const hasData = !!podcastId;

  if (!hasData) {
    return null;
  }

  const [path, { state: newState, replace }] = createRedirectPath({
    destination: createPodcastPath(podcastId),
    search,
    state,
  });

  return <Navigate to={path} state={newState} replace={replace} />;
};

export default PodcastLegacyPage;
