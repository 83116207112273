import cn from 'classnames';
import useKeyHandler from 'hooks/useKeyHandler';
import React from 'react';

import BackgroundImage from './BackgroundImage';
import Body from './Body';
import styles from './Card.module.scss';
import Footer from './Footer';
import Header from './Header';
import { CardVariant } from './types';

export interface CardProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  style?: React.CSSProperties;
  variant?: CardVariant;
}

interface CardComponent extends React.FC<CardProps> {
  BackgroundImage: typeof BackgroundImage;
  Body: typeof Body;
  Footer: typeof Footer;
  Header: typeof Header;
}

const Card: CardComponent = ({
  children,
  className,
  onClick,
  style,
  variant = 'default',
}) => {
  const handleKeyDown = useKeyHandler({
    handledCodes: ['Space'],
    callback: (e) => onClick?.(e),
  });

  const interactionProps = !onClick
    ? undefined
    : {
        onClick,
        onKeyDown: handleKeyDown,
        role: 'button',
        tabIndex: -1,
      };

  return (
    <div
      className={cn(styles.root, className, styles[`root__${variant}`])}
      style={style}
      {...interactionProps}
    >
      {children}
    </div>
  );
};

Card.BackgroundImage = BackgroundImage;
Card.Body = Body;
Card.Footer = Footer;
Card.Header = Header;

export default Card;
