import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'types';
import { ExperimentResponse, getExperimentVariants } from '../headlinerUser';
import { experimentQueryKeys } from './queries';

export type Options<TData = ExperimentResponse> = UseQueryOptions<
  ExperimentResponse,
  ReturnType<typeof experimentQueryKeys.experiments>,
  AxiosError | Error,
  TData
>;

export default function useExperimentVariants<TData = ExperimentResponse>(
  experimentName: string[],
  anonymousUserToken: string,
  opts?: Options<TData>,
) {
  return useQuery(
    experimentQueryKeys.experiments(experimentName),
    () => getExperimentVariants(experimentName, anonymousUserToken),
    {
      enabled: !!(experimentName && anonymousUserToken),
      ...opts,
    },
  );
}
