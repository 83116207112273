import useGetPodcastDetail, { Options } from 'api/hooks/useGetPodcastDetail';
import { GetPodcastDetailsResponse } from 'api/podcast';

import { usePodcastPage } from './PodcastPageContext';

export default function usePagePodcastDetail<TData = GetPodcastDetailsResponse>(
  opts?: Options<TData>,
) {
  const { podcastId } = usePodcastPage();
  const { data, ...rest } = useGetPodcastDetail<TData>(podcastId, opts);

  return {
    data: data?.pages[0],
    ...rest,
  };
}
