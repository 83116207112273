import { useGetPodcastTitle } from 'api/hooks/useGetPodcastDetail';
import cn from 'classnames';
import { Text } from 'components/Typography';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import useTrackPlayerLinkClick from 'pages/AudioPlayerPage/state/useTrackPlayerLinkClick';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { createPodcastPath } from 'utils/routes';
import styles from './PodcastData.module.scss';

type PodcastTitleProps = {};

const PodcastTitle: React.FC<PodcastTitleProps> = () => {
  const { podcastId } = useAudioPlayer();
  const { md } = useBreakpoints();

  const { data: podcastTitle } = useGetPodcastTitle(podcastId);

  const [path, pathOpts] = !podcastId ? [] : createPodcastPath(podcastId);

  const trackLinkClick = useTrackPlayerLinkClick('PodcastTitle');

  const location = useLocation();

  const onPodcastPage = location.pathname === path?.pathname;

  const titleText = (
    <Text
      className={cn(styles.podcast, {
        [styles.podcast__link]: !onPodcastPage,
      })}
      type="details"
    >
      {podcastTitle}
    </Text>
  );

  if (!onPodcastPage && !md) {
    return (
      <Link
        className={styles.link}
        to={path ?? ''}
        state={pathOpts}
        onClick={trackLinkClick}
      >
        {titleText}
      </Link>
    );
  }
  return titleText;
};
export default PodcastTitle;
