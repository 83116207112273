import usePrevious from 'hooks/usePrevious';
import { PALETTE } from 'utils/constants';
import { HOVER_TRANSITIONS, EXPAND_TRANSITIONS } from './constants';
import {
  AnimationConfig,
  AnimationConfigWithPresence,
  AnimationVariant,
  UseAnimationConfig,
  UseAnimationResult,
} from './types';
import { getAnimationVariant } from './utils';

export default function useAnimation(
  config: UseAnimationConfig,
): UseAnimationResult {
  const { disabled, isExpanded, isHoveringContainer } = config;
  const activeVariant = getAnimationVariant(config);
  const prevActiveVariant = usePrevious(activeVariant);

  const container: AnimationConfig = {
    props: {
      animate: disabled ? AnimationVariant.DEFAULT : activeVariant,
      variants: {
        [AnimationVariant.EXPAND]: {},
        [AnimationVariant.DEFAULT]: {},
        [AnimationVariant.HOVER]: {},
      },
    },
  };

  const label: AnimationConfig = {
    props: {
      variants: {
        [AnimationVariant.DEFAULT]: {
          x: 0,
          transition:
            prevActiveVariant === AnimationVariant.EXPAND
              ? EXPAND_TRANSITIONS.out.layer1
              : HOVER_TRANSITIONS.out.layer1,
        },
        [AnimationVariant.EXPAND]: {
          x: 0,
          color: PALETTE.white,
          transition: EXPAND_TRANSITIONS.in.layer1,
        },
        [AnimationVariant.HOVER]: {
          color: PALETTE.white,
          x: 17,
          transition: HOVER_TRANSITIONS.in.layer1,
        },
      },
    },
  };

  const headline: AnimationConfig = {
    props: {
      variants: {
        [AnimationVariant.DEFAULT]: {
          transition:
            prevActiveVariant === AnimationVariant.EXPAND
              ? EXPAND_TRANSITIONS.out.layer1
              : HOVER_TRANSITIONS.out.layer1,
        },
        [AnimationVariant.EXPAND]: {
          color: PALETTE.white,
          transition: EXPAND_TRANSITIONS.in.layer1,
        },
        [AnimationVariant.HOVER]: {
          color: PALETTE.white,
          transition: HOVER_TRANSITIONS.in.layer1,
        },
      },
    },
  };

  const playButton: AnimationConfigWithPresence = {
    props: {
      initial: { opacity: 0 },
      animate: {
        opacity: 1,
        transition: HOVER_TRANSITIONS.in.layer1,
      },
      exit: {
        opacity: 0,
        transition: HOVER_TRANSITIONS.out.layer1,
      },
    },
    visible: !disabled && isHoveringContainer,
  };

  const suggestionsContainer: AnimationConfigWithPresence = {
    props: {
      animate: {
        height: 'auto',
        transition: EXPAND_TRANSITIONS.in.layer1,
      },
      exit: {
        height: 0,
        transition: EXPAND_TRANSITIONS.out.layer1,
      },
      initial: { height: 0 },
    },
    visible: isExpanded,
  };

  const suggestions: AnimationConfigWithPresence = {
    props: {
      animate: {
        opacity: 1,
        transition: EXPAND_TRANSITIONS.in.layer2,
      },
      exit: {
        opacity: 0,
        transition: EXPAND_TRANSITIONS.out.layer2,
      },
      initial: { opacity: 0 },
    },
    visible: isExpanded,
  };

  return {
    container,
    headline,
    label,
    playButton,
    suggestions,
    suggestionsContainer,
  };
}
