import cn from 'classnames';
import React from 'react';
import { Orientation } from '../types';
import { getSpaceCssVars } from '../utils';
import styles from './SpacerChild.module.scss';

export interface SpacerChildProps {
  children?: React.ReactNode;
  orientation: Orientation;
  spaceMultiplier: string | number;
}

const SpacerChild: React.FC<SpacerChildProps> = ({
  children,
  orientation,
  spaceMultiplier,
}) => {
  if (!React.isValidElement(children)) {
    return null;
  }

  return React.cloneElement(children, {
    className: cn(
      styles.root,
      styles[`root__${orientation}`],
      children.props.className,
    ),
    style: {
      ...children.props.style,
      ...getSpaceCssVars(spaceMultiplier),
    },
  });
};

export default SpacerChild;
