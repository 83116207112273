import EpisodeCard from '../EpisodeCard';
import styles from './PodcastEpisodes.module.scss';

export interface PodcastEpisodesSkeletonProps {
  count?: number;
}

const PodcastEpisodesSkeleton: React.FC<PodcastEpisodesSkeletonProps> = ({
  count = 3,
}) => (
  <>
    {Array(count)
      .fill(0)
      .map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <EpisodeCard className={styles.card} key={`placeholder-${i}`} />
      ))}
  </>
);

export default PodcastEpisodesSkeleton;
