import { usePageHighlightVideoClips } from 'api/hooks/useHighlightVideoClips';
import cn from 'classnames';

import PodcastEpisodes, { PodcastEpisodesSkeleton } from '../PodcastEpisodes';
import PodcastMediaTabs from '../PodcastMediaTabs';
import usePagePodcastEpisodes from '../usePagePodcastEpisodes';
import styles from './PodcastMedia.module.scss';

export interface PodcastMediaProps {
  className?: string;
  style?: React.CSSProperties;
}

const PodcastMedia: React.FC<PodcastMediaProps> = ({ className, style }) => {
  const { isFetched: areEpisodesFetched } = usePagePodcastEpisodes();
  const { isFetched: areClipsFetched, data: clipsData } =
    usePageHighlightVideoClips();

  const isLoading = !areEpisodesFetched && !areClipsFetched;
  const clipsCount =
    clipsData?.pages
      .map((p) => p.videoClips.length)
      .reduce((acc, val) => acc + val, 0) ?? 0;

  return (
    <div className={cn(styles.root, className)} style={style}>
      {(() => {
        if (isLoading) {
          return <PodcastEpisodesSkeleton />;
        }

        if (clipsCount === 0) {
          return <PodcastEpisodes />;
        }

        return <PodcastMediaTabs />;
      })()}
    </div>
  );
};

export default PodcastMedia;
