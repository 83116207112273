import { useAuth } from 'context/AuthContext';
import { useModalControls } from 'context/ModalContext';

export const useWithAuthentication = () => {
  const { isAuthenticated, setOnAuthSuccess } = useAuth();
  const modalName = 'Auth';
  const { open } = useModalControls();

  function createAuthenticatedHandler<TArgs extends any[]>(
    fn: (...args: TArgs) => void,
  ) {
    return (...args: TArgs) => {
      if (!isAuthenticated) {
        setOnAuthSuccess(() => () => fn(...args));
        open({ name: modalName });
      } else {
        fn(...args);
      }
    };
  }

  return {
    createAuthenticatedHandler,
  };
};
