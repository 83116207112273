import {
  AnimationProps,
  HeaderState,
  useAppHeaderAnimation,
  TRANSITIONS,
} from 'components/AppHeader';
import { useBreakpoints } from 'context/BreakpointsContext';
import React, { useContext, useMemo } from 'react';
import { AnimationContextType, AnimationProviderProps } from './types';

const AnimationContext = React.createContext<AnimationContextType | undefined>(
  undefined,
);

export const AnimationProvider: React.FC<AnimationProviderProps> = ({
  children,
}) => {
  const appHeaderAnimation = useAppHeaderAnimation();
  const { md } = useBreakpoints();

  const playButton: AnimationProps = useMemo(
    () =>
      !md
        ? {}
        : {
            variants: {
              [HeaderState.EXPANDED]: {
                top: 'auto',
                transition: TRANSITIONS.in.layer1,
              },
              [HeaderState.CONDENSED]: {
                top: md ? -175 : -22,
                transition: TRANSITIONS.out.layer1,
              },
            },
            style: { transition: 'none' },
          },
    [md],
  );
  const buttonBox: AnimationProps = useMemo(
    () =>
      !md
        ? {
            variants: {
              [HeaderState.EXPANDED]: {
                opacity: 1,
                transition: TRANSITIONS.in.layer2,
              },
              [HeaderState.CONDENSED]: {
                opacity: 0,
                transition: TRANSITIONS.out.layer2,
              },
            },
          }
        : {},
    [md],
  );

  return (
    <AnimationContext.Provider
      value={useMemo(
        () => ({
          playButton,
          buttonBox,
          ...appHeaderAnimation,
        }),
        [appHeaderAnimation, playButton, buttonBox],
      )}
    >
      {children}
    </AnimationContext.Provider>
  );
};

export function useAnimation() {
  const context = useContext(AnimationContext);

  if (context === undefined) {
    throw new Error('useAnimation must be used within AnimationProvider');
  }

  return context;
}
