import ogImage from 'assets/images/main-og-image/image.webp';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import {
  DESCRIPTION,
  OG_TYPE,
  SITE_NAME,
  TITLE,
  TWITTER_CARD_TYPE,
  TWITTER_HANDLE,
} from './constants';

export interface MetadataProps {}

const Metadata: React.FC<MetadataProps> = () => {
  const location = useLocation();

  const url = [window.location.origin, location.pathname]
    .join('')
    .replace(/\/$/i, '');

  return (
    <Helmet>
      <title>Headliner</title>
      <meta name="description" content={DESCRIPTION} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={SITE_NAME} />
      <meta property="og:title" content={TITLE} />
      <meta property="og:description" content={DESCRIPTION} />
      <meta property="og:type" content={OG_TYPE} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:alt" content={TITLE} />

      <meta name="twitter:card" content={TWITTER_CARD_TYPE} />
      <meta name="twitter:site" content={TWITTER_HANDLE} />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <meta name="twitter:image" content={ogImage} />
    </Helmet>
  );
};

export default Metadata;
