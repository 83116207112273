import cn from 'classnames';
import Spacer from 'components/Spacer';
import { useBreakpoints } from 'context/BreakpointsContext';
import React from 'react';
import styles from './AppHeaderContents.module.scss';

export interface AppHeaderContentsProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  onMouseOut?: (e: React.MouseEvent) => void;
  onMouseOver?: (e: React.MouseEvent) => void;
  style?: React.CSSProperties;
}

const AppHeaderContents: React.FC<AppHeaderContentsProps> = ({
  children,
  className,
  onClick,
  onMouseOut,
  onMouseOver,
  style,
}) => {
  const { md } = useBreakpoints();

  return (
    <Spacer
      align="center"
      className={cn(styles.root, className)}
      orientation={md ? 'vertical' : 'horizontal'}
      space={md ? 'auto' : 'large'}
      {...{ onClick, onMouseOut, onMouseOver, style }}
    >
      {children}
    </Spacer>
  );
};

export default AppHeaderContents;
