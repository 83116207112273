import cn from 'classnames';
import LinkCard, { LinkCardProps } from 'components/LinkCard';
import { Heading } from 'components/Typography';
import { MouseEventHandler } from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './SuggestionCard.module.scss';
import SuggestionCardHeader from './SuggestionCardHeader';

export interface SuggestionCardProps {
  className?: string;
  description?: string;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  linkState?: any;
  linkTo?: LinkCardProps['to'];
  style?: React.CSSProperties;
  thumbnailSrc?: string;
  title?: string;
}

const SuggestionCard: React.FC<SuggestionCardProps> = ({
  className,
  description,
  linkState,
  linkTo = '',
  loading,
  onClick,
  style,
  thumbnailSrc,
  title,
}) => (
  <LinkCard
    className={cn(
      {
        [styles.root__loading]: loading,
      },
      className,
    )}
    cardClassName={styles.suggestion}
    disabled={loading}
    onClick={!loading ? onClick : undefined}
    to={linkTo}
    state={linkState}
    style={style}
  >
    <LinkCard.BackgroundImage src={thumbnailSrc} />
    <SuggestionCardHeader {...{ loading, thumbnailSrc, title }} />
    <LinkCard.Body>
      <Heading className={styles.description} level={5}>
        {!loading ? description : <Skeleton count={4} />}
      </Heading>
    </LinkCard.Body>
  </LinkCard>
);

export default SuggestionCard;
