import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import {
  authHeaderRequestInterceptor,
  autoLogoutResponseInterceptor,
  tokenRenewalRequestInterceptor,
} from './interceptors';

let authHeaderInterceptor: number;
let autoLogoutInterceptor: number;
let tokenRenewalInterceptor: number;

const axiosInstance = axios.create();

export type PlayAxiosInstanceConfig = {
  onTokenRenewed: (token: string) => void;
  logout: () => void;
};

export function configure({ logout, onTokenRenewed }: PlayAxiosInstanceConfig) {
  // remove response interceptors
  if (autoLogoutInterceptor !== undefined) {
    axiosInstance.interceptors.response.eject(autoLogoutInterceptor);
  }

  // remove request interceptors
  [authHeaderInterceptor, tokenRenewalInterceptor].forEach((interceptor) => {
    if (interceptor !== undefined) {
      axiosInstance.interceptors.request.eject(interceptor);
    }
  });

  // add new interceptors
  autoLogoutInterceptor = axiosInstance.interceptors.response.use(
    ...autoLogoutResponseInterceptor(logout),
  );

  tokenRenewalInterceptor = axiosInstance.interceptors.request.use(
    ...tokenRenewalRequestInterceptor({ onTokenRenewed }),
  );

  authHeaderInterceptor = axiosInstance.interceptors.request.use(
    ...authHeaderRequestInterceptor(),
  );
}

export { axiosInstance as request };
