import { Button, ButtonProps } from '@sparemin/blockhead';
import cn from 'classnames';
import { PlayIcon } from 'components/icons';
import { isNumber } from 'lodash';
import { formatFriendlyDuration } from 'utils/time';
import styles from './DurationBadge.module.scss';

export interface DurationBadgeProps extends ButtonProps {
  durationMillis?: number;
}

const DurationBadge: React.FC<DurationBadgeProps> = ({
  className,
  durationMillis,
  ...props
}) => (
  <Button
    className={cn(styles.root, className)}
    size="small"
    fluid={false}
    startIcon={
      <div className={styles.playContainer}>
        <PlayIcon className={styles.playIcon} />
      </div>
    }
    {...props}
  >
    {isNumber(durationMillis) ? formatFriendlyDuration(durationMillis) : 'Play'}
  </Button>
);

export default DurationBadge;
