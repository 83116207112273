import { Lock } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import styles from './LockCircle.module.scss';

type LockCircleProps = {
  size?: 'small' | 'large';
};

const LockCircle: React.FC<LockCircleProps> = ({ size }) => (
  <div
    className={cn(styles.container, { [styles[`container__${size}`]]: true })}
  >
    <Lock className={cn(styles.icon, { [styles[`icon__${size}`]]: true })} />
  </div>
);
export default LockCircle;
