import {
  GetPodcastEpisodeDetailResponse,
  getPodcastEpisodeDetailsFromFeed,
} from 'api/podcast';
import { AxiosError } from 'axios';
import { isFinite } from 'lodash';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'types';
import { episodeQueryKeys } from './queries';

export type Options<TData = GetPodcastEpisodeDetailResponse> = UseQueryOptions<
  GetPodcastEpisodeDetailResponse,
  ReturnType<typeof episodeQueryKeys.feedDetails>,
  AxiosError | Error,
  TData
>;

export default function useGetPodcastEpisodeDetailFromFeed<
  TData = GetPodcastEpisodeDetailResponse,
>(id?: number, opts?: Options<TData>) {
  return useQuery(
    episodeQueryKeys.feedDetails(id),
    ({ queryKey: [key] }) =>
      getPodcastEpisodeDetailsFromFeed(key.feedEpisodeId as number, {
        includeChapters: false,
        includeDescription: true,
      }),
    {
      ...opts,
      enabled: (opts?.enabled ?? true) && isFinite(id),
    },
  );
}
