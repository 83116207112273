import { ButtonProps } from '@sparemin/blockhead';
import useStaticCallback from 'hooks/useStaticCallback';
import { useCallback, useEffect, useRef } from 'react';
import { useSwiper, useSwiperSlide } from 'swiper/react';

type UseSwiperSlideClickOptions = Pick<
  ButtonProps,
  'onPress' | 'onPressStart' | 'onPressEnd'
>;

type UseSwiperSlideClickResult = Pick<
  ButtonProps,
  'onPressStart' | 'onPressEnd'
>;

/**
 * A hook that only fires a click handler on an element in a swiper slide if the
 * slide wasn't dragged after "mousedown" (or touch start, press start, etc.).
 *
 * Designed to work with @sparemin/blockhead Button component
 */
export default function useSwiperSlideClick({
  onPress,
  onPressEnd,
  onPressStart,
}: UseSwiperSlideClickOptions): UseSwiperSlideClickResult {
  const swiper = useSwiper();
  const { isActive } = useSwiperSlide();
  const movedRef = useRef(false);

  const handleSliderMove = useStaticCallback(() => {
    if (isActive) {
      movedRef.current = true;
    }
  });

  useEffect(() => {
    swiper.on('sliderMove', handleSliderMove);

    return () => {
      swiper.off('sliderMove', handleSliderMove);
    };
  }, [handleSliderMove, swiper]);

  const handlePressStart = useCallback(
    (e) => {
      movedRef.current = false;
      onPressStart?.(e);
    },
    [onPressStart],
  );

  const handlePressEnd = useCallback(
    (e) => {
      if (!movedRef.current) {
        onPress?.(e);
      }
      onPressEnd?.(e);
    },
    [onPress, onPressEnd],
  );

  return {
    onPressEnd: handlePressEnd,
    onPressStart: handlePressStart,
  };
}
