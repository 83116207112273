import { Mic, PlayCircle, Tabs } from '@sparemin/blockhead';
import PodcastClipThumbnails from '../PodcastClipThumbnails';
import PodcastEpisodes from '../PodcastEpisodes';

export interface PodcastMediaTabsProps {}

const PodcastMediaTabs: React.FC<PodcastMediaTabsProps> = () => (
  <Tabs>
    <Tabs.Item icon={<PlayCircle />} iconPosition="left" title="clips">
      <PodcastClipThumbnails />
    </Tabs.Item>
    <Tabs.Item icon={<Mic />} iconPosition="left" title="episodes">
      <PodcastEpisodes />
    </Tabs.Item>
  </Tabs>
);

export default PodcastMediaTabs;
