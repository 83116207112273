import {
  GetPodcastEpisodeDetailResponse,
  ListeningQueueResponse,
} from 'api/podcast';
import { millisToSec, secToMillis } from 'utils/time';

export const episodeQueueSelector = (d: ListeningQueueResponse) => {
  const episodes = d.content;
  return episodes.map((episode) => ({
    podcastId: episode.podcast.podcastId,
    remoteEpisodeId: episode.episodeId,
    thumbnailImageUrl: episode.thumbnailUrl,
    isBlocked: episode.isBlocked,
    description: episode.description,
    publishedAtMillis: episode.publishedAtMillis,
    audioDurationMillis: secToMillis(episode.durationSec),
    title: episode.title,
    isSponsored: episode.isSponsored,
    widgetInfo: episode.widgetInfo || undefined,
  }));
};

export const nextEpisodeIdsSelector = (d: ListeningQueueResponse) => ({
  nextEpisodeId: d.content[0].episodeId,
  nextPodcastId: d.content[0].podcast.podcastId,
});

export const episodeDetailSelector = (d: GetPodcastEpisodeDetailResponse) => ({
  podcastTitle: d.podcast.title,
  episodeTitle: d.title,
  thumbnailUrl: d.thumbnailImageUrl,
});

export const nextEpisodeSelector = (d: ListeningQueueResponse) => ({
  nextEpisodeId: d.content[0].episodeId,
  nextPodcastId: d.content[0].podcast.podcastId,
  nextPodcastName: d.content[0].podcast.title,
  nextEpisodeName: d.content[0].title,
  isSponsored: d.content[0].isSponsored,
  widgetInfo: d.content[0].widgetInfo ?? undefined,
});

export const sponsoredEpisodeSelector = (d: ListeningQueueResponse) => {
  if (d.content[0].isSponsored) {
    return {
      isSponsored: d.content[0].isSponsored,
      widgetInfo: d.content[0].widgetInfo,
    };
  }
  return undefined;
};

export const episodeTitleSelector = (d: GetPodcastEpisodeDetailResponse) =>
  d.title;

export const audioDetailsSelector = (d: GetPodcastEpisodeDetailResponse) => ({
  chapters: d.chapters?.map((chapter, index, ary) => ({
    endSec: millisToSec(ary[index + 1]?.startMillis),
    id: chapter.id,
    startSec: millisToSec(chapter.startMillis),
    headline: chapter.headline,
  })),
  audioDurationMillis: d.audioDurationMillis,
});
