import backgroundImage from 'assets/images/podcast-background/image.webp';
import backgroundImage2x from 'assets/images/podcast-background/image@2x.webp';
import backgroundImage3x from 'assets/images/podcast-background/image@3x.webp';
import cn from 'classnames';
import styles from './BannerImage.module.scss';

export interface BannerImageProps
  extends React.HTMLAttributes<HTMLImageElement> {
  className?: string;
}

const BannerImage: React.FC<BannerImageProps> = ({
  className,
  ...imageProps
}) => (
  <img
    alt=""
    className={cn(styles.root, className)}
    src={backgroundImage}
    srcSet={`${backgroundImage} 1x, ${backgroundImage2x} 2x, ${backgroundImage3x} 3x`}
    {...imageProps}
  />
);

export default BannerImage;
