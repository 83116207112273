import { DialogCloseButton, DialogTitle, Heading } from '@sparemin/blockhead';
import cn from 'classnames';
import useListeningQueue from 'pages/AudioPlayerPage/state/ListeningQueueContext';
import React from 'react';
import styles from './Header.module.scss';

type HeaderProps = {
  children: React.ReactNode;
  className?: string;
};

const Header: React.FC<HeaderProps> = ({ children, className }) => {
  const { hide } = useListeningQueue();

  return (
    <DialogTitle
      as={Heading}
      level={1}
      className={cn(styles.text, styles.root, className)}
    >
      {children}
      <DialogCloseButton onPress={() => hide()} />
    </DialogTitle>
  );
};
export default Header;
