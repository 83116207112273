import cn from 'classnames';
import styles from './Article.module.scss';

export interface ArticleProps {
  children?: React.ReactNode;
  className?: string;
}

const Article: React.FC<ArticleProps> = ({ children, className }) => (
  <div className={cn(styles.root, className)}>{children}</div>
);

export default Article;
