import BigPlayButton from 'components/BigPlayButton';
import LinkCard from 'components/LinkCard';
import Thumbnail from 'components/Thumbnail';
import { Text } from 'components/Typography';
import Skeleton from 'react-loading-skeleton';
import styles from './SuggestionCardHeader.module.scss';

export interface SuggestionCardHeaderProps {
  loading?: boolean;
  title: string | undefined;
  thumbnailSrc: string | undefined;
}

const SuggestionCardHeader: React.FC<SuggestionCardHeaderProps> = ({
  loading,
  title,
  thumbnailSrc,
}) => (
  <LinkCard.Header
    action={
      loading ? (
        <Skeleton
          inline
          containerClassName={styles.playButton__skeleton}
          height="100%"
          circle
        />
      ) : (
        <BigPlayButton className={styles.playButton} />
      )
    }
    className={styles.root}
    icon={
      <Thumbnail
        alt="podcast artwork"
        className={styles.thumbnail}
        ratio={1}
        src={thumbnailSrc}
      />
    }
    title={
      <Text className={styles.title} type="paragraph">
        {loading ? <Skeleton width="100px" /> : title}
      </Text>
    }
  />
);

export default SuggestionCardHeader;
