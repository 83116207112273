import cn from 'classnames';
import React from 'react';
import { OverridableComponentProps } from 'types/react';
import TypographyBase, { TypographyBaseProps } from '../TypographyBase';
import styles from './Text.module.scss';

interface Props extends Omit<TypographyBaseProps, 'children'> {
  children?: React.ReactNode;
  className?: string;
  type: 'details' | 'label' | 'label2' | 'mono' | 'paragraph';
}

export type TextProps<As extends React.ElementType = 'span'> =
  OverridableComponentProps<Props, As>;

function Text<As extends React.ElementType = 'span'>(props: TextProps<As>) {
  const {
    align,
    as,
    children,
    className,
    letterSpacing,
    style,
    transform,
    type,
    ...rest
  } = props;
  const Component = as ?? 'span';

  return (
    <TypographyBase {...{ align, letterSpacing, style, transform }}>
      <Component
        className={cn(
          styles.root,
          {
            [styles[`root__${type}`]]: !!type,
          },
          className,
        )}
        {...rest}
      >
        {children}
      </Component>
    </TypographyBase>
  );
}

export default Text;
