import api, { GetOwnedPodcastsResult } from '@sparemin/api-sdk';
import { isPodcastOwnerSelector } from 'api/selectors/podcast';
import { useAuth } from 'context/AuthContext';
import { useCallback } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { ownedPodcastQueryKeys } from './queries';

type UseGetOwnedPodcastsOpts<TData = GetOwnedPodcastsResult> = Pick<
  UseQueryOptions<GetOwnedPodcastsResult, unknown, TData>,
  'select'
>;

function useGetOwnedPodcasts<TData = GetOwnedPodcastsResult>(
  opts?: UseGetOwnedPodcastsOpts<TData>,
) {
  const { isAuthenticated } = useAuth();

  return useQuery(
    ownedPodcastQueryKeys.podcasts(),

    () => api.getOwnedPodcasts(),
    {
      ...opts,
      enabled: isAuthenticated,
    },
  );
}

export function useIsPodcastOwner(feedId: number | undefined) {
  return useGetOwnedPodcasts({
    select: useCallback(
      (d: GetOwnedPodcastsResult) => isPodcastOwnerSelector(d, feedId),
      [feedId],
    ),
  });
}
