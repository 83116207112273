import Page from 'components/Page';
import useTooltip from 'components/Tooltip/useTooltip';
import FollowPodcastProvider from 'context/FollowPodcastContext';
import MobileNowPlayingCard from 'pages/AudioPlayerPage/MobileNowPlayingCard';
import { EpisodeAudioPlayerProvider } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import StickyAudioPlayer from 'pages/AudioPlayerPage/StickyAudioPlayer';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { STYLE_CONSTANTS } from 'utils/constants';
import FollowPodcastPopper from './FollowPodcastPopper';
import ListeningQueue from './ListeningQueue';
import { ListeningQueueProvider } from './state/ListeningQueueContext';
import { NowPlayingCardProvider } from './state/NowPlayingCardContext';

export interface AudioPlayerPageProps {}

const AudioPlayerPage: React.FC<AudioPlayerPageProps> = () => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [target, setTarget] = useState<Element | null>(null);

  const { styles, attributes, popperRef } = useTooltip({
    offset: STYLE_CONSTANTS.followPodcastPopperOffset,
    strategy: STYLE_CONSTANTS.playerPositionType,
    target,
  });

  return (
    <Page fluid>
      <EpisodeAudioPlayerProvider audioElement={audio}>
        <NowPlayingCardProvider>
          <ListeningQueueProvider>
            <FollowPodcastProvider>
              <Outlet />
              <FollowPodcastPopper
                styles={styles.popper}
                attributes={attributes.popper}
                ref={popperRef}
              />
              <ListeningQueue />
              <MobileNowPlayingCard />
              <StickyAudioPlayer ref={setAudio} containerRef={setTarget} />
            </FollowPodcastProvider>
          </ListeningQueueProvider>
        </NowPlayingCardProvider>
      </EpisodeAudioPlayerProvider>
    </Page>
  );
};

export default AudioPlayerPage;
