import Spacer from 'components/Spacer';
import { range } from 'lodash';
import React from 'react';
import { OverridableComponentProps } from 'types/react';
import SuggestionCard from '../SuggestionCard';
import styles from './SuggestionList.module.scss';

interface Props {
  children?: React.ReactNode;
  loading?: boolean;
  placeholderCount?: number;
}

export type SuggestionListProps<As extends React.ElementType> =
  OverridableComponentProps<Props, As>;

function SuggestionList<As extends React.ElementType>(
  props: SuggestionListProps<As>,
) {
  const {
    as,
    children,
    loading,
    placeholderCount = 3,
    ...componentProps
  } = props;

  return (
    // this spacer has space=0 to leverage the flex layout that comes along with the
    // Spacer without relying on Spacer for inserting space between items.  There is an
    // issue with horizontal scrolling on safari where the right padding on the container
    // is ignored and the last element is aligned with the edge of the container.  to simulate
    // padding on the container, all elements in the list are given a padding-right value.  since
    // padding-right on the card makes the card itself bigger, safari leaves space for it.
    <Spacer<any>
      as={as}
      className={styles.root}
      space={0}
      orientation="horizontal"
      {...componentProps}
    >
      {loading
        ? range(placeholderCount).map((value) => (
            <div className={styles.cardWrapper} key={`placeholder-${value}`}>
              <SuggestionCard loading />
            </div>
          ))
        : React.Children.map(children, (child) =>
            !React.isValidElement(child) ? null : (
              <div className={styles.cardWrapper} key={child.key}>
                {child}
              </div>
            ),
          )}
    </Spacer>
  );
}

export default SuggestionList;
