import EpisodeCard from 'components/EpisodeCard';
import React from 'react';
import styles from './Contents.module.scss';

type QueueContentsSkeletonProps = {
  count?: number;
};

const QueueContentsSkeleton: React.FC<QueueContentsSkeletonProps> = ({
  count = 5,
}) => (
  <>
    {Array(count)
      .fill(0)
      .map((_, i) => (
        <EpisodeCard
          episode={undefined}
          className={styles.card}
          // eslint-disable-next-line react/no-array-index-key
          key={`placeholder-${i}`}
        />
      ))}
  </>
);
export default QueueContentsSkeleton;
