import {
  AuthProvider as AuthRoutesProvider,
  AuthPageLayout,
} from '@sparemin/auth';
import AuthLink from 'components/AuthLink';
import { Outlet, createSearchParams } from 'react-router-dom';
import routes, { authModalParams } from 'utils/routes';

type UserAuthenticationRouteProps = {};

const UserAuthenticationRoute: React.FC<UserAuthenticationRouteProps> = () => {
  const loginRoute = `${routes.home}?${createSearchParams(authModalParams)}`;

  return (
    <AuthRoutesProvider
      navigation={{
        routes: {
          login: loginRoute,
        },
        linkComponent: AuthLink,
      }}
    >
      <AuthPageLayout>
        <Outlet />
      </AuthPageLayout>
    </AuthRoutesProvider>
  );
};

export default UserAuthenticationRoute;
