import cn from 'classnames';
import React from 'react';
import styles from './TypographyBase.module.scss';

interface RequiredChildrenProps {
  className: string;
  style: React.CSSProperties;
}

export interface TypographyBaseProps {
  align?: React.CSSProperties['textAlign'];
  children: React.FunctionComponentElement<RequiredChildrenProps>;
  letterSpacing?: React.CSSProperties['letterSpacing'];
  style?: React.CSSProperties;
  transform?: React.CSSProperties['textTransform'];
}

const TypographyBase = ({
  align,
  children,
  letterSpacing,
  style,
  transform,
}: TypographyBaseProps) =>
  React.cloneElement(children, {
    className: cn(styles.root, children.props.className),
    style: {
      ...style,
      ...children.props.style,
      ['--letter-spacing' as any]: letterSpacing,
      ['--text-align' as any]: align,
      ['--text-transform' as any]: transform,
    },
  });

export default TypographyBase;
