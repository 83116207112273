import useClaimPodcastFlow from 'hooks/useClaimPodcastFlow';
import React from 'react';

interface ClaimPodcastFlowContainerProps {}

const ClaimPodcastFlowContainer: React.FC<
  ClaimPodcastFlowContainerProps
> = () => {
  useClaimPodcastFlow();

  return null;
};
export default ClaimPodcastFlowContainer;
