import cn from 'classnames';
import styles from './Divider.module.scss';

export interface DividerProps {
  className?: string;
  style?: React.CSSProperties;
}

const Divider: React.FC<DividerProps> = ({ className, style }) => (
  <hr className={cn(styles.root, className)} style={style} />
);

export default Divider;
