import { useEffect } from 'react';
import { useSwiperSlide } from 'swiper/react';

interface UseActiveSlideConfig {
  onView?: () => void;
}

export default function useActiveSlide({ onView }: UseActiveSlideConfig) {
  const { isActive } = useSwiperSlide();
  useEffect(() => {
    if (isActive) {
      onView?.();
    }
  }, [onView, isActive]);
}
