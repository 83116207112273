import cn from 'classnames';
import React from 'react';
import { OverridableComponentProps } from 'types/react';
import TypographyBase, { TypographyBaseProps } from '../TypographyBase';
import styles from './Heading.module.scss';

type Level = 1 | 2 | 3 | 4 | 5;

interface Props extends Omit<TypographyBaseProps, 'children'> {
  children?: React.ReactNode;
  className?: string;
  level: Level;
  style?: React.CSSProperties;
}

export type HeadingProps<As extends React.ElementType = 'h1'> =
  OverridableComponentProps<Props, As>;

const LETTER_SPACING_BY_LEVEL: Record<Level, string> = {
  1: 'normal',
  2: '0.5px',
  3: '0.5px',
  4: '0.5px',
  5: '0.5px',
};

const ForwardedComponent = <As extends React.ElementType>(
  props: HeadingProps<As>,
  ref: any,
) => {
  const {
    align,
    as,
    children,
    className,
    level,
    letterSpacing = LETTER_SPACING_BY_LEVEL[level],
    style,
    transform = 'none',
    ...restProps
  } = props;

  const Element = as ?? (`h${level}` as const);

  return (
    <TypographyBase {...{ align, letterSpacing, transform }}>
      <Element
        className={cn(styles.root, styles[`root__level${level}`], className)}
        ref={ref}
        style={style}
        {...restProps}
      >
        {children}
      </Element>
    </TypographyBase>
  );
};

const Heading: typeof ForwardedComponent = React.forwardRef(
  ForwardedComponent,
) as any;

export default Heading;
