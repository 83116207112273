import { ChapterSuggestionsResponse, getChapterSuggestions } from 'api/play';
import isFinite from 'lodash/isFinite';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'types';
import { chapterSuggestionQueryKeys } from './queries';

export type Options<TData = ChapterSuggestionsResponse> = UseQueryOptions<
  ChapterSuggestionsResponse,
  ReturnType<typeof chapterSuggestionQueryKeys.chapter>,
  unknown,
  TData
>;

export default function useChapterSuggestions<
  TData = ChapterSuggestionsResponse,
>(id?: number, opts?: Options<TData>) {
  return useQuery(
    chapterSuggestionQueryKeys.chapter(id),
    ({ queryKey: [{ chapterId }] }) =>
      getChapterSuggestions(chapterId as number),
    {
      ...opts,
      enabled: (opts?.enabled ?? true) && isFinite(id),
    },
  );
}
