import IconLink from 'components/IconLink';
import { useEventTracking } from 'context/EventTrackingContext';
import React from 'react';
import { PodcatcherLinkProps } from './types';
import { getLinkPropsFromSource } from './utils';

const PodcatcherLink: React.FC<PodcatcherLinkProps> = ({
  url,
  source,
  podcastName,
}) => {
  const { trackVisitPodcatcher } = useEventTracking();
  const { label, icon } = getLinkPropsFromSource(source);
  const handleClick = () => {
    trackVisitPodcatcher({ podcatcherSource: source, podcastName });
  };
  return (
    <IconLink
      url={url}
      variant="frosted"
      color="dark"
      size="large"
      onClick={handleClick}
      icon={icon}
      label={label}
    />
  );
};
export default PodcatcherLink;
