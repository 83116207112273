import { ThumbnailButton } from '@sparemin/blockhead';
import { HighlightVideoClipsResponse } from 'api/podcast';
import { useHighlightClipScroller } from 'components/HighlightClipScroller';
import { useCallback, useMemo } from 'react';
import { InfiniteData } from 'react-query';

export interface PodcastClipThumbnailProps {
  clipId: number;
  onPress?: (clipId: number) => void;
  data?: InfiniteData<HighlightVideoClipsResponse>;
}

const PodcastClipThumbnail: React.FC<PodcastClipThumbnailProps> = ({
  clipId,
  onPress,
  data,
}) => {
  const clip = useMemo(
    () =>
      data?.pages.flatMap((p) => p.videoClips).find((c) => c.clipId === clipId),
    [clipId, data?.pages],
  );

  const { unmute, toggleOffUnmuteOnInteraction } = useHighlightClipScroller();

  const handlePress = useCallback(() => {
    toggleOffUnmuteOnInteraction();
    unmute();
    onPress?.(clipId);
  }, [clipId, onPress, unmute, toggleOffUnmuteOnInteraction]);

  return (
    <ThumbnailButton
      alt="podcast clip poster"
      onPress={handlePress}
      ratio={1}
      src={clip?.video.previewThumbnailImageUrl}
      variant="media"
    />
  );
};

export default PodcastClipThumbnail;
