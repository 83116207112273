import { Forward15OutlinedIcon } from 'components/icons';
import { useEventTracking } from 'context/EventTrackingContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import React from 'react';
import { PLAYER_SKIP_SECONDS } from 'utils/constants';
import ControlButton from '../ControlButton';

type SkipForwardButtonProps = {
  className?: string;
};

const SkipForwardButton: React.FC<SkipForwardButtonProps> = ({ className }) => {
  const { ready, controls } = useAudioPlayer();
  const { trackSeek } = useEventTracking();

  return (
    <ControlButton
      aria-label="forward 15 seconds"
      className={className}
      disabled={!ready}
      onClick={() => {
        if (controls) {
          controls.seekDelta(PLAYER_SKIP_SECONDS);
          trackSeek('Forward15Sec');
        }
      }}
      tooltip="Skip forwards 15 seconds"
    >
      <Forward15OutlinedIcon style={{ width: '100%' }} />
    </ControlButton>
  );
};
export default SkipForwardButton;
