import { AuthLinkProps } from '@sparemin/auth';
import { Link } from 'react-router-dom';

export function AuthLink(props: AuthLinkProps) {
  const { children, className, href, onPress } = props;

  return (
    <Link className={className} to={href} onClick={onPress}>
      {children}
    </Link>
  );
}
