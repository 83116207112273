import cn from 'classnames';
import { AppHeaderContents } from 'components/AppHeader';
import AppTopbar from 'components/AppHeaderTopbar';
import { HighlightClipScrollerProvider } from 'components/HighlightClipScroller';
import SearchButton from 'components/SearchButton';
import UserAccountControl from 'components/UserAccountControl';
import { useState } from 'react';
import BannerImage from './BannerImage';
import styles from './HomepageContents.module.scss';
import HomepageHighlightClipScroller from './HomepageHighlightClipScroller';
import Logo from './Logo';
import { HomepageSliderPosition } from './types';

type HomepageContentsProps = {};

const HomepageContents: React.FC<HomepageContentsProps> = () => {
  const [sliderPosition, setSliderPosition] =
    useState<HomepageSliderPosition>('intro');

  const isEnded = sliderPosition === 'outro';
  const isBody = typeof sliderPosition === 'number';
  const isStarted = sliderPosition !== 'intro';

  return (
    <>
      <div
        className={cn(styles.overlay, {
          [styles.overlay__ending]: isEnded,
        })}
      >
        <BannerImage
          className={cn(
            styles.banner,
            {
              [styles.banner__hidden]: isBody,
            },
            { [styles.banner__ending]: isEnded },
          )}
        />
      </div>
      <AppTopbar
        className={cn(styles.header, {
          [styles.header__fade]: isStarted,
        })}
        topLeftElement={<Logo />}
        topCenterElement={<SearchButton />}
        topRightElement={<UserAccountControl />}
      />
      <AppHeaderContents className={styles.contents}>
        <HighlightClipScrollerProvider>
          <HomepageHighlightClipScroller onSlideChange={setSliderPosition} />
        </HighlightClipScrollerProvider>
      </AppHeaderContents>
    </>
  );
};
export default HomepageContents;
