import cn from 'classnames';
import styles from './Footer.module.scss';

export interface FooterProps {
  children?: React.ReactNode;
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ children, className }) => (
  <div className={cn(styles.root, className)}>{children}</div>
);

export default Footer;
