import React, { useContext, useMemo } from 'react';
import { EpisodePageContextType, EpisodePageProviderProps } from './types';

const EpisodePageContext = React.createContext<
  EpisodePageContextType | undefined
>(undefined);

export const EpisodePageProvider: React.FC<EpisodePageProviderProps> = ({
  episodeId,
  podcastId,
  hasChapters,
  children,
  source,
}) => (
  <EpisodePageContext.Provider
    value={useMemo(
      () => ({ episodeId, hasChapters, podcastId, source }),
      [episodeId, hasChapters, podcastId, source],
    )}
  >
    {children}
  </EpisodePageContext.Provider>
);

export function useEpisodePage() {
  const context = useContext(EpisodePageContext);

  if (context === undefined) {
    throw new Error('useEpisodePage must be used within EpisodePageProvider');
  }

  return context;
}
