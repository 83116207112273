import React from 'react';
import styles from './Arrow.module.scss';

export interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
}

const Arrow = React.forwardRef<HTMLDivElement, ArrowProps>(({ style }, ref) => (
  <div className={styles.root} {...{ ref, style }} />
));

export default Arrow;
