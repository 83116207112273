import cn from 'classnames';
import { HeadlinerLogo, HeadySolid } from 'components/icons';
import { useBreakpoints } from 'context/BreakpointsContext';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import routes from 'utils/routes';
import { useAppHeaderAnimation } from '../AppHeaderAnimationContext/AppHeaderAnimationContext';
import styles from './Logo.module.scss';

export interface LogoProps {
  className?: string;
}

const MotionLink = motion(Link);

const Logo: React.FC<LogoProps> = ({ className }) => {
  const { md } = useBreakpoints();
  const { logo } = useAppHeaderAnimation();

  return (
    <MotionLink
      className={cn(styles.root, className)}
      to={routes.home}
      {...logo}
    >
      {md ? (
        <HeadySolid className={styles.icon} />
      ) : (
        <HeadlinerLogo className={styles.icon} />
      )}
    </MotionLink>
  );
};

export default Logo;
