import HeaderTextLink from 'components/HeaderTextLink';
import useTrackPlayerLinkClick from 'pages/AudioPlayerPage/state/useTrackPlayerLinkClick';
import React, { useCallback } from 'react';
import { createEpisodePath } from 'utils/routes';
import { useAudioPlayer } from '../../state/AudioPlayerContext';
import useNowPlayingCard from '../../state/NowPlayingCardContext';
import { useGetEpisodeTitle } from '../../state/useGetEpisodeDetails';
import styles from './EpisodeTitle.module.scss';

interface EpisodeTitleProps {}

const EpisodeTitle: React.FC<EpisodeTitleProps> = () => {
  const { episodeId, podcastId } = useAudioPlayer();
  const { data: episodeTitle } = useGetEpisodeTitle({ episodeId, podcastId });

  const trackLinkClick = useTrackPlayerLinkClick('EpisodeTitle');
  const { hide } = useNowPlayingCard();

  const [path = '', pathOpts] =
    !episodeId || !podcastId ? [] : createEpisodePath(episodeId, podcastId);

  const handleEpisodeLinkClick = useCallback(() => {
    hide();
    trackLinkClick();
  }, [hide, trackLinkClick]);

  return (
    <HeaderTextLink
      className={styles.root}
      linkClassName={styles.link}
      level={3}
      path={path}
      pathOpts={pathOpts}
      onPress={handleEpisodeLinkClick}
      title={episodeTitle ?? ''}
    />
  );
};
export default EpisodeTitle;
