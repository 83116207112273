import { AnimationVariant, UseAnimationConfig } from './types';

export function getAnimationVariant({
  isExpanded,
  isHoveringContainer,
}: UseAnimationConfig): AnimationVariant {
  if (isExpanded) {
    return AnimationVariant.EXPAND;
  }

  return isHoveringContainer
    ? AnimationVariant.HOVER
    : AnimationVariant.DEFAULT;
}
