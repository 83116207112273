import { Heading, Spacer, Text, Thumbnail } from '@sparemin/blockhead';
import { HighlightVideoClip } from 'api/podcast';
import { TrackClipPlaybackOptions } from 'context/EventTrackingContext/types';
import { getTextContentFromHtmlString } from 'utils/dom';
import ContinueListeningButton from '../ContinueListeningButton';
import styles from './HighlightClipInfo.module.scss';

export interface HighlightClipInfoProps {
  clip?: HighlightVideoClip;
  onContinueListening?: () => void;
  source: TrackClipPlaybackOptions['source'];
}

const HighlightClipInfo: React.FC<HighlightClipInfoProps> = ({
  clip,
  source,
  onContinueListening,
}) => (
  <Spacer
    className={styles.root}
    orientation="vertical"
    space="20px"
    tabIndex={-1}
  >
    <Spacer align="center" space="15px">
      <Thumbnail
        alt={`artwork for "${clip?.podcast.title}`}
        className={styles.thumbnail}
        ratio={1}
        src={clip?.podcast.thumbnailImageUrl}
      />
      <Heading className={styles.podcastName} level={5}>
        {clip?.podcast.title}
      </Heading>
    </Spacer>
    <Text className={styles.episodeTitle} variant="jumbo">
      {clip?.episode.title}
    </Text>
    <Heading className={styles.episodeDescription} level={2}>
      {getTextContentFromHtmlString(clip?.episode.descriptionHtml)}
    </Heading>
    <ContinueListeningButton
      className={styles.button}
      clip={clip}
      onPress={onContinueListening}
      source={source}
    />
  </Spacer>
);

export default HighlightClipInfo;
