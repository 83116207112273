import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styles from './Popover.module.scss';

export interface PopoverProps {
  attributes: { [key: string]: string } | undefined;
  children?: React.ReactNode;
  show?: boolean;
  transitionDurationMillis?: number;
  style?: React.CSSProperties;
  className?: string;
}

const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      attributes,
      children,
      show,
      style,
      className,
      transitionDurationMillis = 100,
    },
    ref,
  ) => (
    <AnimatePresence>
      {show && (
        <motion.div
          {...{ ref, ...attributes }}
          animate={{
            opacity: 1,
            transition: { duration: transitionDurationMillis / 1000 },
          }}
          className={cn(styles.root, className)}
          exit={{
            opacity: 0,
            transition: { duration: transitionDurationMillis / 1000 },
          }}
          initial={{ opacity: 0.0 }}
          role="dialog"
          style={style}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  ),
);

export default Popover;
