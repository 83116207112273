import UnloadingAudio from 'components/UnloadingAudio';
import { useEventTracking } from 'context/EventTrackingContext';
import { motion } from 'framer-motion';
import useEpisodeComplete from 'hooks/useEpisodeComplete';
import useListen from 'hooks/useListen';
import {
  useAudioPlayer,
  usePersistListeningProgress,
} from 'pages/AudioPlayerPage/state';
import React, { useCallback, useEffect, useState } from 'react';
import { useGetPlayerDetails } from './state';
import styles from './StickyAudioPlayer.module.scss';
import StickyAudioPlayerUi from './StickyAudioPlayerUi';
import useStickyAudioPlayerAnimation from './useStickyAudioPlayerAnimation';

export interface StickyAudioPlayerProps {
  containerRef?: (val: Element | null) => void;
}

const StickyAudioPlayer = React.forwardRef<
  HTMLAudioElement,
  StickyAudioPlayerProps
>(({ containerRef }, ref) => {
  const { player, podcastId, episodeId } = useAudioPlayer();

  const { data } = useGetPlayerDetails({ podcastId, episodeId });
  const { podcastTitle, episodeTitle, src } = data ?? {};

  const { trackEpisodeComplete, trackEpisodeListen } = useEventTracking();

  const [initialized, setInitialized] = useState(false);

  const animation = useStickyAudioPlayerAnimation();
  useEffect(() => {
    if (src && !initialized) {
      setInitialized(true);
    }
  }, [initialized, src]);

  function handleEpisodeComplete() {
    if (podcastTitle && episodeTitle) {
      trackEpisodeComplete(podcastTitle, episodeTitle);
    }
  }

  useEpisodeComplete({ onComplete: handleEpisodeComplete, player });
  usePersistListeningProgress();

  const handleTrackListen = useCallback(() => {
    if (podcastTitle && episodeTitle) {
      trackEpisodeListen(podcastTitle, episodeTitle);
    }
  }, [trackEpisodeListen, podcastTitle, episodeTitle]);

  useListen({ player, onListen: handleTrackListen });

  return (
    <>
      <motion.div
        className={styles.container}
        {...animation}
        ref={containerRef}
      >
        <StickyAudioPlayerUi />
      </motion.div>
      {src && <UnloadingAudio key={src} preload="none" ref={ref} src={src} />}
    </>
  );
});

export default StickyAudioPlayer;
