import { useHomepageHighlightClip } from 'api/hooks/useGetHomePageHighlightVideoClips';
import { HighlightClip } from 'components/HighlightClipScroller';
import { HighlightClipProps } from 'components/HighlightClipScroller/HighlightClip';
import React from 'react';

interface HomepageHighlightClipProps
  extends Omit<HighlightClipProps, 'clip' | 'source'> {
  clipId: number;
}

const HomepageHighlightClip: React.FC<HomepageHighlightClipProps> = ({
  clipId,
  className,
}) => {
  const { data: clip } = useHomepageHighlightClip(clipId);
  return <HighlightClip clip={clip} source="HomePage" className={className} />;
};
export default HomepageHighlightClip;
