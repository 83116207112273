import React, { useContext, useMemo } from 'react';
import {
  AppHeaderDescriptionContextType,
  AppHeaderDescriptionProviderProps,
} from './types';

const AppHeaderDescriptionContext = React.createContext<
  AppHeaderDescriptionContextType | undefined
>(undefined);

export const AppHeaderDescriptionProvider: React.FC<
  AppHeaderDescriptionProviderProps
> = ({ children, isLoading }) => (
  <AppHeaderDescriptionContext.Provider
    value={useMemo(() => ({ isLoading }), [isLoading])}
  >
    {children}
  </AppHeaderDescriptionContext.Provider>
);

export function useAppHeaderDescription() {
  const context = useContext(AppHeaderDescriptionContext);

  if (context === undefined) {
    throw new Error(
      'useAppHeaderDescription must be used within AppHeaderDescriptionProvider',
    );
  }

  return context;
}
