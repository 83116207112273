import Controls from 'pages/AudioPlayerPage/Controls';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import styles from './NowPlayingCardControls.module.scss';

export interface NowPlayingCardControlsProps {
  className?: string;
}

const NowPlayingCardControls: React.FC<NowPlayingCardControlsProps> = () => {
  const { chapters } = useAudioPlayer();

  const hasChapters = Boolean(chapters?.length);

  return (
    <Controls>
      <div className={styles.controls}>
        {hasChapters && <Controls.PreviousChapterButton />}
        <Controls.SkipBackButton />
        <Controls.PlayButton size="x-large" />
        <Controls.SkipForwardButton />
        {hasChapters && <Controls.NextChapterButton />}
      </div>
    </Controls>
  );
};

export default NowPlayingCardControls;
