import React, { useCallback, useMemo, useRef } from 'react';
import mergeRefs from 'utils/mergeRefs';

export type UnloadingAudioProps = React.HTMLProps<HTMLAudioElement>;

// Some browsers (Chrome) hang when loading a lot of different audio sources.
// This will unload them from memory and free up any resources they're holding onto.
const UnloadingAudio = React.forwardRef<HTMLAudioElement, UnloadingAudioProps>(
  (props, ref) => {
    const element = useRef<HTMLAudioElement>();

    const handleElement = useCallback((el: HTMLAudioElement) => {
      // this will be called with el == null when audio component unmounts
      if (!el && element.current) {
        element.current.src = '';
        element.current.load();
      }
      element.current = el;
    }, []);

    const combinedRef = useMemo(
      () => mergeRefs([handleElement, ref]),
      [handleElement, ref],
    );

    return <audio {...props} ref={combinedRef} />;
  },
);

export default UnloadingAudio;
