import { ForwardStepOutlinedIcon } from 'components/icons';
import { useEventTracking } from 'context/EventTrackingContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import React from 'react';
import ControlButton from '../ControlButton';

type NextChapterButtonProps = {
  className?: string;
};

const NextChapterButton: React.FC<NextChapterButtonProps> = ({ className }) => {
  const { controls, ready, hasNextChapter } = useAudioPlayer();
  const { trackSeek } = useEventTracking();

  return (
    <ControlButton
      aria-label="next chapter"
      className={className}
      disabled={!ready || !hasNextChapter}
      onClick={() => {
        if (controls) {
          controls.nextChapter({ scroll: true });
          trackSeek('NextPart');
        }
      }}
      tooltip="Next chapter"
    >
      <ForwardStepOutlinedIcon style={{ width: '100%' }} />
    </ControlButton>
  );
};
export default NextChapterButton;
