import { useBreakpoints } from 'context/BreakpointsContext';
import { useEventTracking } from 'context/EventTrackingContext';
import useGetTitles from 'hooks/useGetTitlesFromIds';
import useTimeout from 'hooks/useTimeout';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { useEffect, useState } from 'react';

interface UsePlayButtonResult {
  md: boolean;
  onClick: () => void;
  paused: boolean;
  showSpinner: boolean;
}

export default function usePlayButton(): UsePlayButtonResult {
  const { md } = useBreakpoints();
  const { controls, loading, paused, podcastId, episodeId } = useAudioPlayer();
  const { trackPlayback } = useEventTracking();
  const { episodeName, podcastName } = useGetTitles({ podcastId, episodeId });

  const [showSpinner, setShowSpinner] = useState(false);
  const [startSpinnerDelayTimer, cancelSpinnerDelayTimer] = useTimeout({
    callback: () => setShowSpinner(true),
    delay: 2000,
  });

  useEffect(() => {
    if (loading) {
      startSpinnerDelayTimer();
    } else {
      setShowSpinner(false);
    }

    return cancelSpinnerDelayTimer;
  }, [cancelSpinnerDelayTimer, loading, startSpinnerDelayTimer]);

  const onClick = () => {
    if (controls) {
      controls.togglePlay({ scroll: true });

      if (podcastName && episodeName && podcastId && episodeId) {
        trackPlayback(paused ? 'play' : 'pause', {
          source: 'PersistentPlayer',
          podcastName,
          episodeName,
          podcastId,
          remoteEpisodeId: episodeId,
        });
      }
    }
  };

  return {
    md,
    onClick,
    paused,
    showSpinner,
  };
}
