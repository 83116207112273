import { GetPodcastEpisodeDetailResponse } from 'api/podcast';

export const episodeDetailsSelector = (d: GetPodcastEpisodeDetailResponse) => ({
  publishedAtMillis: d.publishedAtMillis,
  durationMillis: d.audioDurationMillis,
  podcastTitle: d.podcast.title,
  feedId: d.podcast.podcastFeedId,
  itunesUrl: d.podcast.itunesUrl,
  spotifyUrl: d.podcast.spotifyUrl,
  thumbnailImageUrl: d.thumbnailImageUrl,
  episodeTitle: d.title,
  remotePodcastId: d.podcast.remotePodcastId,
  episodeId: d.remoteEpisodeId,
  descriptionHtml: d.descriptionHtml,
  discoBackdropImageUrl: d.discoBackdropImageUrl,
  originalAudioUrl: d.originalAudioUrl,
});

export const episodePartsSelector = (d: GetPodcastEpisodeDetailResponse) => ({
  chapters: d.chapters,
});

export const episodePartSelector = (
  d: GetPodcastEpisodeDetailResponse,
  chapterId: number,
) => {
  const index = d.chapters.findIndex((chapter) => chapter.id === chapterId);
  if (index === -1) {
    return undefined;
  }
  return {
    index,
    ...d.chapters[index],
  };
};
