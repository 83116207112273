import cn from 'classnames';
import styles from './Anchor.module.scss';

export interface AnchorProps extends React.HTMLProps<HTMLAnchorElement> {}

const Anchor: React.FC<AnchorProps> = ({ children, className, ...props }) => (
  <a className={cn(styles.root, className)} {...props}>
    {children}
  </a>
);

export default Anchor;
