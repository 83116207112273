import { episodeQueryKeys } from 'api/hooks/queries';
import useGetPodcastEpisodeDetail from 'api/hooks/useGetPodcastEpisodeDetail';
import { GetPodcastEpisodeDetailResponse } from 'api/podcast';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { UseQueryOptions } from 'types';
import { useEpisodePage } from '../EpisodePageContext';
import {
  episodeDetailsSelector,
  episodePartSelector,
  episodePartsSelector,
} from './selectors';

function usePagePodcastEpisodeDetail<TData = GetPodcastEpisodeDetailResponse>(
  opts?: UseQueryOptions<
    GetPodcastEpisodeDetailResponse,
    ReturnType<typeof episodeQueryKeys.details>,
    AxiosError | Error,
    TData
  >,
) {
  const { episodeId, podcastId } = useEpisodePage();
  return useGetPodcastEpisodeDetail<TData>({ episodeId, podcastId }, opts);
}

export function useEpisodeDetails() {
  return usePagePodcastEpisodeDetail({ select: episodeDetailsSelector });
}

export function useEpisodeParts() {
  return usePagePodcastEpisodeDetail({ select: episodePartsSelector });
}

export function useEpisodeChapter(chapterId: number) {
  return usePagePodcastEpisodeDetail({
    select: useCallback(
      (d: GetPodcastEpisodeDetailResponse) => episodePartSelector(d, chapterId),
      [chapterId],
    ),
  });
}
