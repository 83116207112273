import axios from 'axios';
import { isEmpty, isNil, omitBy } from 'lodash';
import qs from 'query-string';

type PathReplacements = { [key: string]: any };
type QueryParams = Record<string, any>;

interface CreateUrlOptions {
  pathReplacements?: PathReplacements;
  queryParams?: QueryParams;
}

export function replacePathVariables(
  path: string,
  replacements: PathReplacements,
) {
  return Object.keys(replacements).reduce((modifiedPath, lookupValue) => {
    const replacement = replacements[lookupValue];
    return modifiedPath.replaceAll(
      `:${lookupValue}`,
      encodeURIComponent(String(replacement)),
    );
  }, path);
}

// TODO test
export function appendQueryParams(url: string, params: QueryParams): string {
  const filteredParams = omitBy(params, isNil);

  if (isEmpty(filteredParams)) {
    return url;
  }

  return qs.stringifyUrl({
    url,
    query: filteredParams,
  });
}

// TODO test
export function createUrl(path: string, opts?: CreateUrlOptions): string {
  if (!opts) {
    return path;
  }

  const { pathReplacements, queryParams } = opts;

  const baseUrl = !pathReplacements
    ? path
    : replacePathVariables(path, pathReplacements);

  return !queryParams ? baseUrl : appendQueryParams(baseUrl, queryParams);
}

export function is404(error: any) {
  return axios.isAxiosError(error) && error.response?.status === 404;
}

export function createFormattedAuthHeader(header: string) {
  const formattedAuthHeaders = {
    headers: {
      Authorization: header,
    },
  };

  return formattedAuthHeaders;
}
