import { createUrl } from 'api/utils';
import { request } from '../http';
import {
  GET_CHAPTER_SUGGESTION_URL_PATTERN,
  EVENT_TRACKING_URL_PATTERN,
} from './constants';

import { ChapterSuggestionsResponse, SendEventConfig } from './types';

export async function getChapterSuggestions(
  chapterId: number,
): Promise<ChapterSuggestionsResponse> {
  const { data } = await request.get(
    createUrl(GET_CHAPTER_SUGGESTION_URL_PATTERN, {
      pathReplacements: { chapterId },
    }),
  );

  return data;
}

export async function sendEvent(props: SendEventConfig) {
  await request.post(EVENT_TRACKING_URL_PATTERN, props);
}
