import { ModalName, useModal, useModalControls } from 'context/ModalContext';
import { useCallback } from 'react';
import { getMatchingFirstModal } from './utils';

export interface UseModalConfig {
  name?: ModalName;
  onRequestClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
  onAfterClose?: () => void;
}

export default function useConnectedModal({
  name,
  onRequestClose,
  onAfterClose,
}: UseModalConfig) {
  const { dispatch, state } = useModal();
  const { close } = useModalControls();

  const modal = getMatchingFirstModal(state, name);
  const show = !!modal?.show;
  const params = modal?.params;

  const handleRequestClose = useCallback(
    (e?: React.MouseEvent | React.KeyboardEvent) => {
      close();
      onRequestClose?.(e);
    },
    [close, onRequestClose],
  );

  const handleAfterClose = useCallback(() => {
    dispatch({ type: 'pop' });
    onAfterClose?.();
    modal?.onAfterClose?.();
  }, [dispatch, modal, onAfterClose]);

  return { show, params, handleRequestClose, handleAfterClose };
}
