import cn from 'classnames';
import AspectRatio from 'components/AspectRatio';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { OverridableComponentProps } from 'types/react';
import styles from './Thumbnail.module.scss';

interface Props {
  alt: string;
  children?: React.ReactNode;
  className?: string;
  ratio?: number;
  size?: 'small' | 'large';
  src?: string;
  style?: React.CSSProperties;
}

export type ThumbnailProps<As extends React.ElementType = 'div'> =
  OverridableComponentProps<Props, As>;

function Thumbnail<As extends React.ElementType = 'div'>(
  props: ThumbnailProps<As>,
) {
  const {
    as,
    alt,
    children,
    className,
    size,
    ratio = 1,
    src,
    style,
    ...restProps
  } = props;
  const Component = as ?? 'div';

  const isLoading = !src;

  return (
    <Component
      className={cn(
        styles.root,
        {
          [styles[`root__${size}`]]: !!size,
        },
        className,
      )}
      style={style}
      {...restProps}
    >
      <AspectRatio className={styles.ratio} ratio={ratio}>
        {isLoading ? (
          <Skeleton
            className={styles.skeleton}
            containerClassName={styles.skeletonContainer}
          />
        ) : (
          <img alt={alt} className={styles.image} src={src} />
        )}
      </AspectRatio>
      {!isLoading && children}
    </Component>
  );
}

export default Thumbnail;
