import { notifySuccess } from 'components/notification';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

const useCheckFeedFollowUnsubscribeStatus = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const unsubscribeState = useRef(searchParams.get('feed-follow-unsubscribe'));

  useEffect(() => {
    if (unsubscribeState.current === 'true') {
      searchParams.delete('feed-follow-unsubscribe');
      setSearchParams(searchParams);
      unsubscribeState.current = null;
      notifySuccess({ heading: "You've successfully unsubscribed." });
    }
  }, [searchParams, setSearchParams]);
};

export default useCheckFeedFollowUnsubscribeStatus;
