import cn from 'classnames';
import useKeyHandler from 'hooks/useKeyHandler';
import React from 'react';
import { OverridableComponentProps } from 'types/react';
import styles from './Spacer.module.scss';
import SpacerChild from './SpacerChild';
import { Orientation, Space } from './types';
import { getSpaceMultiplier } from './utils';

export interface Props<As extends React.ElementType = 'div'>
  extends Partial<Pick<React.ComponentPropsWithoutRef<As>, 'onClick'>> {
  align?: 'start' | 'end' | 'center';
  children?: React.ReactNode;
  className?: string;
  justify?: 'start' | 'end' | 'center' | 'between' | 'around';
  orientation?: Orientation;
  space?: Space;
  style?: React.CSSProperties;
}

export type SpacerProps<As extends React.ElementType = 'div'> =
  OverridableComponentProps<Props<As>, As>;

const ForwardedComponent = <As extends React.ElementType = 'div'>(
  {
    as,
    align = 'start',
    children,
    className,
    justify = 'start',
    onClick,
    orientation = 'horizontal',
    space = 'small',
    style,
    ...props
  }: SpacerProps<As>,
  ref: any,
) => {
  const Component = as ?? 'div';

  const handleKeyDown = useKeyHandler({
    handledCodes: ['Space'],
    callback: () => onClick,
  });

  const interactionProps = !onClick
    ? undefined
    : {
        onClick,
        onKeyDown: handleKeyDown,
        role: 'button',
        tabIndex: -1,
      };

  return (
    <Component
      className={cn(
        styles.root,
        {
          [styles[`root__${orientation}`]]: !!orientation,
          [styles[`root__align-${align}`]]: !!align,
          [styles[`root__justify-${justify}`]]: !!justify,
        },
        className,
      )}
      ref={ref}
      {...{ style, ...interactionProps }}
      {...props}
    >
      {React.Children.map(children, (child) => (
        <SpacerChild
          orientation={orientation}
          spaceMultiplier={getSpaceMultiplier(space)}
        >
          {child}
        </SpacerChild>
      ))}
    </Component>
  );
};

const Spacer = React.forwardRef(ForwardedComponent) as <
  As extends React.ElementType = 'div',
>(
  props: SpacerProps<As> & { ref?: React.Ref<React.ElementRef<As>> },
) => React.ReactElement | null;

export default Spacer;
