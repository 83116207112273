import { useBreakpoints } from 'context/BreakpointsContext';
import { useTransform, useViewportScroll } from 'framer-motion';
import { useEffect, useState } from 'react';
import { STYLE_CONSTANTS } from 'utils/constants';
import { TRANSITIONS } from './constants';
import {
  AnimationProps,
  AppHeaderAnimationContextType,
  HeaderState,
} from './types';

const artwork: AnimationProps = {
  variants: {
    [HeaderState.EXPANDED]: {
      opacity: 1,
      transition: TRANSITIONS.in.layer2,
    },
    [HeaderState.CONDENSED]: {
      opacity: 0,
      transition: TRANSITIONS.out.layer2,
    },
  },
};

const footer: AnimationProps = {
  variants: {
    [HeaderState.EXPANDED]: {
      opacity: 1,
      transition: TRANSITIONS.in.layer3,
    },
    [HeaderState.CONDENSED]: {
      opacity: 0,
      transition: TRANSITIONS.out.layer3,
    },
  },
};

const title: AnimationProps = {
  variants: {
    [HeaderState.EXPANDED]: {
      opacity: 1,
      transition: TRANSITIONS.in.layer3,
    },
    [HeaderState.CONDENSED]: {
      opacity: 0,
      transition: TRANSITIONS.out.layer3,
    },
  },
};

export default function useAnimationContextValue(): AppHeaderAnimationContextType {
  const [headerState, setHeaderState] = useState<HeaderState>();
  const { md } = useBreakpoints();
  const { scrollY } = useViewportScroll();
  const yRange = useTransform(scrollY, [0, 70], [0, 1]);

  useEffect(() => {
    yRange.onChange((v) => {
      setHeaderState(v >= 1 ? HeaderState.CONDENSED : HeaderState.EXPANDED);
    });
  }, [yRange]);

  const logo: AnimationProps = !md
    ? {}
    : {
        variants: {
          [HeaderState.EXPANDED]: {
            opacity: 1,
            transition: TRANSITIONS.in.layer2,
          },
          [HeaderState.CONDENSED]: {
            opacity: 0,
            transition: TRANSITIONS.out.layer2,
          },
        },
      };

  const subtitle: AnimationProps = {
    variants: {
      [HeaderState.EXPANDED]: {
        left: 'auto',
        top: 'auto',
        transition: TRANSITIONS.in.layer1,
      },
      [HeaderState.CONDENSED]: {
        // this is the height of 2 lines of text, which the episode title is limited
        // to.  the text must be centered in the header regardless of whether it's
        // 1 or 2 lines.  to accomplish this, set the box to be tall enough for 2
        // lines, then center the text.  the height can't be set in the expanded state
        // becuase if the title is only a single line, there will be too much
        // whitespace beneath it
        height: 44,
        left: !md ? -180 : 'auto',
        top: md ? -178 : -15,
        transition: TRANSITIONS.out.layer1,
      },
    },
  };

  const header: AnimationProps = {
    animate: headerState,
    initial: HeaderState.EXPANDED,
    key: String(md),
    variants: {
      [HeaderState.EXPANDED]: {
        height: md
          ? STYLE_CONSTANTS.headerHeightMobile
          : STYLE_CONSTANTS.headerHeightDesktop,
        paddingTop: md
          ? STYLE_CONSTANTS.headerTopMobile
          : STYLE_CONSTANTS.headerTopDesktop,
        transition: TRANSITIONS.in.layer1,
      },
      [HeaderState.CONDENSED]: {
        height: STYLE_CONSTANTS.headerHeightCondensed,
        paddingTop: md
          ? STYLE_CONSTANTS.headerTopMobileCondensed
          : STYLE_CONSTANTS.headerTopDesktopCondensed,
        transition: TRANSITIONS.out.layer1,
      },
    },
  };

  const topLeft: AnimationProps = !md
    ? {}
    : {
        variants: {
          [HeaderState.EXPANDED]: {
            opacity: 1,
            transition: TRANSITIONS.in.layer2,
          },
          [HeaderState.CONDENSED]: {
            opacity: 0,
            transition: TRANSITIONS.out.layer2,
            pointerEvents: 'none',
          },
        },
      };

  const topRight: AnimationProps = !md
    ? {}
    : {
        variants: {
          [HeaderState.EXPANDED]: {
            opacity: 1,
            transition: TRANSITIONS.in.layer2,
          },
          [HeaderState.CONDENSED]: {
            opacity: 0,
            transition: TRANSITIONS.out.layer2,
            pointerEvents: 'none',
          },
        },
      };

  const topCenter: AnimationProps = {
    variants: {
      [HeaderState.EXPANDED]: {
        opacity: 1,
        transition: TRANSITIONS.in.layer2,
      },
      [HeaderState.CONDENSED]: {
        opacity: 0,
        transition: TRANSITIONS.out.layer2,
        pointerEvents: 'none',
      },
    },
  };

  return {
    artwork,
    subtitle,
    header,
    logo,
    footer,
    title,
    topLeft,
    topCenter,
    topRight,
    activeVariant: headerState,
  };
}
