import FollowPodcastPrompt from 'components/FollowPodcastPrompt';
import Popover from 'components/Popover';
import { useFollowPodcastPrompt } from 'context/FollowPodcastContext';
import React from 'react';
import cssStyles from './FollowPodcastPopper.module.scss';
import useChangeEpisode from './useChangeEpisode';
import useFollowPromptOnListen from './useFollowPromptOnListen';
import useGetPodcastInformation from './useGetPodcastInformation';

export interface FollowPodcastPopperProps {
  styles: React.CSSProperties;
  attributes: { [key: string]: string } | undefined;
}

const FollowPodcastPopper = React.forwardRef<
  HTMLDivElement | null,
  FollowPodcastPopperProps
>(({ styles, attributes }, ref) => {
  const { feedId, podcastId } = useGetPodcastInformation();
  const { isOpen } = useFollowPodcastPrompt();

  useFollowPromptOnListen(feedId);
  useChangeEpisode();

  return (
    <Popover
      className={cssStyles.root}
      transitionDurationMillis={300}
      attributes={attributes}
      ref={ref}
      style={styles}
      show={isOpen}
    >
      {feedId && podcastId && (
        <FollowPodcastPrompt feedId={feedId} podcastId={podcastId} />
      )}
    </Popover>
  );
});
export default FollowPodcastPopper;
