import React, { useContext, useMemo } from 'react';
import { PodcastPageContextType, PodcastPageProviderProps } from './types';

const PodcastPageContext = React.createContext<
  PodcastPageContextType | undefined
>(undefined);

export const PodcastPageProvider: React.FC<PodcastPageProviderProps> = ({
  podcastId,
  children,
}) => (
  <PodcastPageContext.Provider
    value={useMemo(() => ({ podcastId }), [podcastId])}
  >
    {children}
  </PodcastPageContext.Provider>
);

export function usePodcastPage() {
  const context = useContext(PodcastPageContext);

  if (context === undefined) {
    throw new Error('usePodcastPage must be used within PodcastPageProvider');
  }

  return context;
}
