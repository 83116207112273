import useGetPodcastDetailFromFeed from 'api/hooks/useGetPodcastDetailFromFeed';
import { is404 } from 'api/utils';
import { useRoutingError } from 'pages/RoutingErrorPage';
import { useEffect } from 'react';
import useLegacyPageLocation from '../../hooks/useLegacyPageLocation';

interface UsePodcastPageLegacyResult {
  podcastId?: string;
  state: unknown;
  search?: string;
}

export default function usePodcastLegacyPage(): UsePodcastPageLegacyResult {
  const { setRoutingError } = useRoutingError();
  const {
    id: feedId,
    search,
    state,
  } = useLegacyPageLocation({
    pageType: 'podcast',
  });

  const { data } = useGetPodcastDetailFromFeed(feedId, {
    onError: (err) => {
      if (is404(err)) {
        setRoutingError(404);
      }
    },
  });

  useEffect(() => {
    if (!feedId) {
      setRoutingError(404);
    }
  }, [feedId, setRoutingError]);

  const podcastId = data?.podcastId;

  return { podcastId, search, state };
}
