import useGetHomePageHighlightVideoClips from 'api/hooks/useGetHomePageHighlightVideoClips';
import HighlightClipScroller, {
  useHighlightClipScroller,
} from 'components/HighlightClipScroller';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';
import { HomepageSliderPosition } from '../types';
import HomepageHighlightClip from './HomepageHighlightClip';
import styles from './HomepageHighlightClipScroller.module.scss';
import { FooterSlide, IntroSlide } from './infoSlides';

export interface HomepageHighlightClipScrollerProps {
  className?: string;
  onSlideChange?: (val: HomepageSliderPosition) => void;
}

const HomepageHighlightClipScroller: React.FC<
  HomepageHighlightClipScrollerProps
> = ({ onSlideChange }) => {
  const { md } = useBreakpoints();
  const { data, hasNextPage, fetchNextPage } =
    useGetHomePageHighlightVideoClips();
  const { mute } = useHighlightClipScroller();

  const clips = useMemo(
    () => data?.pages.flatMap((p) => p.videoClips) ?? [],
    [data?.pages],
  );

  // account for intro and outro slides
  const lastClipIndex = clips.length + 1;

  const handleSlideChange = (val: number) => {
    if (val === 0) {
      onSlideChange?.('intro');
    }
    if (val > 0 && val < lastClipIndex) {
      onSlideChange?.(val);
    }
    if (val === lastClipIndex) {
      onSlideChange?.('outro');
    }
    if (val === lastClipIndex - 1 && hasNextPage) {
      fetchNextPage();

      // fetching new data causes the component to rerender.
      // mobile browsers than throw an error and halt autoplay.
      // setting the volume back to mute allows autoplay to continue
      if (md) {
        mute();
      }
    }
  };

  const source = 'HomePage';
  return (
    <HighlightClipScroller
      initialSlide={0}
      centeredSlidesBounds
      className={styles.swiper}
      slideToClickedSlide={!md}
      source={source}
      touchReleaseOnEdges
      onSlideChange={handleSlideChange}
    >
      <SwiperSlide virtualIndex={0} className={styles.swiperSlide}>
        <IntroSlide />
      </SwiperSlide>

      {clips?.map((clip, index) => (
        <SwiperSlide key={clip.clipId} virtualIndex={index + 1}>
          <HomepageHighlightClip className={styles.clip} clipId={clip.clipId} />
        </SwiperSlide>
      ))}
      <SwiperSlide virtualIndex={lastClipIndex} className={styles.swiperSlide}>
        <FooterSlide />
      </SwiperSlide>
    </HighlightClipScroller>
  );
};

export default HomepageHighlightClipScroller;
