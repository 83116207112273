import cuid from 'cuid';
import { storageAvailable } from '../browserStorage';
import { SESSION_ID_KEY } from '../constants';

function getSessionId() {
  const value = `SS_${cuid()}`;
  if (storageAvailable('localStorage')) {
    const currentValue = localStorage.getItem(SESSION_ID_KEY);

    if (currentValue) {
      return currentValue;
    }

    localStorage.setItem(SESSION_ID_KEY, value);
    return value;
  }

  return value;
}

const UNIQUE_SESSION_ID = getSessionId();

export default UNIQUE_SESSION_ID;
