import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getTextContentFromHtmlString } from 'utils/dom';
import usePagePodcastDetail from '../usePagePodcastDetail';

export interface PodcastPageMetadataProps {}

const PodcastPageMetadata: React.FC<PodcastPageMetadataProps> = () => {
  const { data: podcastDetailsData } = usePagePodcastDetail();

  const title =
    podcastDetailsData && `${podcastDetailsData.title} on Headliner`;
  const description =
    podcastDetailsData &&
    getTextContentFromHtmlString(podcastDetailsData.description);

  return !podcastDetailsData ? null : (
    <Helmet>
      <title>{podcastDetailsData.title}</title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={podcastDetailsData.discoBackdropImageUrl}
      />
      <meta property="og:image:alt" content={title} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={podcastDetailsData.discoBackdropImageUrl}
      />
      <meta name="twitter:image:alt" content={title} />
    </Helmet>
  );
};

export default PodcastPageMetadata;
