import {
  getHighlightVideoClips,
  HighlightVideoClipsResponse,
} from 'api/podcast';
import { podcastClipSelector } from 'api/selectors/podcast';
import { isFinite } from 'lodash';
import usePagePodcastDetail from 'pages/PodcastPage/usePagePodcastDetail';
import { useCallback } from 'react';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryOptions } from 'types';
import { podcastHighlightsQueryKeys } from './queries';

export type UseHighlightVideoClipsOptions<TData = HighlightVideoClipsResponse> =
  UseInfiniteQueryOptions<
    HighlightVideoClipsResponse,
    ReturnType<typeof podcastHighlightsQueryKeys.highlights>,
    unknown,
    TData
  >;

export function useHighlightVideoClips<TData = HighlightVideoClipsResponse>(
  id?: number,
  opts?: UseHighlightVideoClipsOptions<TData>,
) {
  return useInfiniteQuery(
    podcastHighlightsQueryKeys.highlights(id),
    ({ pageParam, queryKey: [{ feedId }] }) =>
      getHighlightVideoClips(feedId as number, {
        nextClipUpdatedAtMillis: pageParam,
      }),
    {
      ...opts,
      enabled: (opts?.enabled ?? true) && isFinite(id),
      getNextPageParam: (lastPage) =>
        lastPage?.nextClipUpdatedAtMillis ?? undefined,
    },
  );
}

export function usePageHighlightVideoClips<TData = HighlightVideoClipsResponse>(
  opts?: UseInfiniteQueryOptions<
    HighlightVideoClipsResponse,
    ReturnType<typeof podcastHighlightsQueryKeys.highlights>,
    unknown,
    TData
  >,
) {
  const { data: podcastDetailsData } = usePagePodcastDetail();
  const feedId = podcastDetailsData?.podcastFeedId;
  return useHighlightVideoClips<TData>(feedId, opts);
}

export function usePageVideoClip(clipId: number) {
  const { data, ...rest } = usePageHighlightVideoClips({
    select: useCallback(
      (d: InfiniteData<HighlightVideoClipsResponse>) =>
        podcastClipSelector(d, clipId),
      [clipId],
    ),
  });

  return { data: data?.pages[0], ...rest };
}
