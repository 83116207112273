import cn from 'classnames';
import { PlayIcon } from 'components/icons';
import Spacer from 'components/Spacer';
import { Heading, Text } from 'components/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import EpisodePartSuggestions from '../EpisodePartSuggestions';
import styles from './EpisodePart.module.scss';
import useEpisodePart from './useEpisodePart';

export interface EpisodePartProps {
  className?: string;
  partId: number;
  style?: React.CSSProperties;
}

const EpisodePart = React.forwardRef<HTMLDivElement, EpisodePartProps>(
  ({ className, partId, style }, ref) => {
    const { animation, expanded, onClick, part, setHovering, unsetHovering } =
      useEpisodePart({ partId });

    return (
      <Spacer
        as={motion.div}
        className={cn(
          styles.root,
          { [styles.root__expanded]: expanded },
          className,
        )}
        onClick={onClick}
        onMouseEnter={setHovering}
        onMouseLeave={unsetHovering}
        orientation="vertical"
        ref={ref}
        space="small"
        style={style}
        {...animation.container.props}
      >
        <div className={styles.labelContainer}>
          <AnimatePresence>
            {animation.playButton.visible && (
              <motion.span
                style={{ position: 'absolute' }}
                {...animation.playButton.props}
              >
                <PlayIcon className={styles.playButton} style={{ width: 9 }} />
              </motion.span>
            )}
          </AnimatePresence>
          <Text
            as={motion.span}
            className={styles.label}
            letterSpacing="2px"
            transform="uppercase"
            type="label"
            {...animation.label.props}
          >
            {part ? <>chapter {part.index + 1}</> : <Skeleton width={100} />}
          </Text>
        </div>
        <Heading
          as={motion.h2}
          level={2}
          {...animation.headline.props}
          style={{ width: '100%' }}
        >
          {part ? part.headline : <Skeleton count={2} />}
        </Heading>
        <AnimatePresence>
          {animation.suggestionsContainer.visible && (
            <motion.div
              className={styles.suggestionsContainer}
              {...animation.suggestionsContainer.props}
            >
              <Heading className={styles.suggestionsLabel} level={2}>
                Similar from other episodes...
              </Heading>
              <EpisodePartSuggestions
                partId={partId}
                {...animation.suggestions.props}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Spacer>
    );
  },
);

export default EpisodePart;
