import NotFoundPage from 'pages/NotFoundPage';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { RoutingErrorProvider } from './RoutingErrorContext';
import { ErrorStatus } from './types';

export interface RoutingErrorLayoutPageProps {}

const PAGE_RENDERERS: Record<ErrorStatus | 0, () => React.ReactNode> = {
  0: () => <Outlet />,
  404: () => <NotFoundPage />,
};

const RoutingErrorPage: React.FC<RoutingErrorLayoutPageProps> = () => {
  const location = useLocation();
  const [status, setStatus] = useState<ErrorStatus | 0>(0);

  useEffect(() => {
    setStatus(0);
  }, [location.pathname]);

  return (
    <RoutingErrorProvider setRoutingError={setStatus}>
      {PAGE_RENDERERS[status]()}
    </RoutingErrorProvider>
  );
};

export default RoutingErrorPage;
