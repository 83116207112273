import EpisodeCard, { EpisodeCardProps } from 'components/EpisodeCard';
import LightningGradientCircle from 'components/icons/LightningGradientCircle';
import { useEventTracking } from 'context/EventTrackingContext';
import { QueueEpisode } from 'pages/AudioPlayerPage/state';
import React, { useCallback } from 'react';
import styles from './QueueEpisodeCard.module.scss';

type QueueEpisodeCardProps = Pick<EpisodeCardProps, 'onClick'> & {
  episode: QueueEpisode;
};

const QueueEpisodeCard: React.FC<QueueEpisodeCardProps> = ({
  episode,
  onClick,
}) => {
  const { trackSponsoredEpisodeClickThrough, trackSponsoredEpisodePlayed } =
    useEventTracking();
  const { isSponsored, widgetInfo } = episode;

  const handlePlayBadgeClick = useCallback(() => {
    if (widgetInfo) {
      trackSponsoredEpisodePlayed(widgetInfo);
    }
  }, [widgetInfo, trackSponsoredEpisodePlayed]);

  const handleCardClick = useCallback(() => {
    if (widgetInfo) {
      trackSponsoredEpisodeClickThrough(widgetInfo);
    }
  }, [widgetInfo, trackSponsoredEpisodeClickThrough]);

  const sponsoredEpisodeProps = isSponsored
    ? {
        onPlayBadgeClick: handlePlayBadgeClick,
        onCardClick: handleCardClick,
        badge: <LightningGradientCircle className={styles.icon} />,
      }
    : undefined;

  return (
    <EpisodeCard
      podcastId={episode.podcastId}
      episode={episode}
      pageType="Queue"
      className={styles.card}
      onClick={onClick}
      {...sponsoredEpisodeProps}
    />
  );
};
export default QueueEpisodeCard;
