import ReactShowMoreText, {
  ReactShowMoreTextProps,
} from 'react-show-more-text';
import styles from './ShowMoreText.module.scss';

export interface ShowMoreTextProps
  extends Pick<ReactShowMoreTextProps, 'width'> {
  children?: React.ReactNode;
  lines: number;
}

const ShowMoreText: React.FC<ShowMoreTextProps> = ({
  children,
  lines,
  ...props
}) => (
  <ReactShowMoreText
    anchorClass={styles.anchor}
    lines={lines}
    more="show more"
    less="show less"
    truncatedEndingComponent="... "
    {...props}
  >
    {children}
    <br />
  </ReactShowMoreText>
);

export default ShowMoreText;
