import {
  AccountMenu,
  AccountMenuProps,
  Menu,
  Mic,
  Link as BlockheadLink,
  LogoutIcon,
  Metrics,
} from '@sparemin/blockhead';
import { DASHBOARD_PLAY_URL, HEADLINER_URL } from 'config';
import { useEventTracking } from 'context/EventTrackingContext';

export type UserAccountProps = {
  email: string;
  onLogout: () => void;
};
const UserAccount: React.FC<UserAccountProps> = ({ email, onLogout }) => {
  const { trackPromotePodcast, trackViewPodcastAnalytics } = useEventTracking();

  const settings: AccountMenuProps['children'] = [
    <Menu.LinkItem
      linkComponent={BlockheadLink}
      inheritColor
      key="promote"
      href={HEADLINER_URL}
      target="_blank"
      icon={<Mic />}
    >
      Promote your podcast
    </Menu.LinkItem>,
    <Menu.LinkItem
      linkComponent={BlockheadLink}
      inheritColor
      key="data"
      href={DASHBOARD_PLAY_URL}
      target="_blank"
      icon={<Metrics />}
    >
      View podcast analytics
    </Menu.LinkItem>,

    <Menu.Item key="logout" icon={<LogoutIcon />}>
      Log out
    </Menu.Item>,
  ];
  return (
    <AccountMenu
      onAction={(key) => {
        if (key === 'logout') onLogout();
        if (key === 'data') trackViewPodcastAnalytics();
        if (key === 'promote') trackPromotePodcast();
      }}
      placement="bottom left"
      emailAddress={email}
    >
      {settings}
    </AccountMenu>
  );
};
export default UserAccount;
