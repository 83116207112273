import Popover from 'components/Popover';
import { Text } from 'components/Typography';
import React, { useCallback, useRef, useState } from 'react';
import Arrow from './Arrow';
import { UseTooltipConfig } from './types';
import useTooltip from './useTooltip';

export interface TooltipProps extends Omit<UseTooltipConfig, 'target'> {
  children?: React.ReactNode;
  contents?: React.ReactNode;
  show?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  contents,
  show: showOverride,
  ...config
}) => {
  const [target, setTarget] = useState<Element | undefined>(undefined);
  const timerRef = useRef<number | undefined>(undefined);
  const { arrowRef, attributes, popperRef, styles } = useTooltip({
    target,
    ...config,
  });

  const showTooltip = !!target;

  const handleMouseEnter = useCallback((e: React.MouseEvent) => {
    const element = e.currentTarget;
    timerRef.current = window.setTimeout(() => {
      setTarget(element);
    }, 500);
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
      timerRef.current = undefined;
    }
    setTarget(undefined);
  }, []);

  return (
    <>
      <Popover
        attributes={attributes.popper}
        ref={popperRef}
        show={showTooltip}
        style={{ ...styles.popper }}
      >
        {typeof contents === 'string' ? (
          <Text type="label">{contents}</Text>
        ) : (
          contents
        )}
        <Arrow ref={arrowRef} style={styles.arrow} />
      </Popover>
      {React.isValidElement(children)
        ? React.cloneElement(
            children,
            showOverride === false
              ? undefined
              : {
                  onMouseEnter: handleMouseEnter,
                  onMouseLeave: handleMouseLeave,
                },
          )
        : null}
    </>
  );
};

export default Tooltip;
