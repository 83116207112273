import cn from 'classnames';
import styles from './BlurredBackgroundImage.module.scss';

export interface BlurredBackgroundImageProps {
  alt?: string;
  blurValue?: string;
  className?: string;
  imageClassName?: string;
  src?: string;
}

const BlurredBackgroundImage: React.FC<BlurredBackgroundImageProps> = ({
  alt = 'background',
  blurValue = '5px',
  className,
  imageClassName,
  src,
}) => (
  // outer container is used to cut off overflow caused by the blur filter
  <div
    className={cn(styles.root, className)}
    style={{
      ['--blur-value' as any]: blurValue,
    }}
  >
    <img className={cn(styles.image, imageClassName)} {...{ alt, src }} />
  </div>
);

export default BlurredBackgroundImage;
