import 'swiper/css';
import 'swiper/css/keyboard';
import 'swiper/css/mousewheel';
import 'swiper/css/navigation';
import 'swiper/css/virtual';

import { Dialog, DialogCloseButton, DialogProps } from '@sparemin/blockhead';
import { HighlightVideoClipsResponse } from 'api/podcast';
import HighlightClipScroller from 'components/HighlightClipScroller';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useMemo } from 'react';
import { InfiniteData } from 'react-query';
import { SwiperSlide } from 'swiper/react';
import PodcastHighlightClip from './PodcastHighlightClip';
import styles from './PodcastHighlightClipScroller.module.scss';

export interface PodcastHighlightClipScrollerProps
  extends Pick<DialogProps, 'isOpen' | 'onClose'> {
  initialClipId?: number;
  data: InfiniteData<HighlightVideoClipsResponse> | undefined;
  className?: string;
}

const PodcastHighlightClipScroller: React.FC<
  PodcastHighlightClipScrollerProps
> = ({ initialClipId, isOpen, onClose, data }) => {
  const { md } = useBreakpoints();

  const clips = data?.pages.flatMap((p) => p.videoClips);

  const initialSlideIndex = useMemo(
    () =>
      initialClipId === undefined
        ? undefined
        : clips?.findIndex((clip) => clip.clipId === initialClipId),
    [clips, initialClipId],
  );

  const source = 'PodcastPage';

  return (
    <Dialog isOpen={isOpen} onClose={onClose} position="center">
      {md && <div className={styles.fade} />}
      <DialogCloseButton
        className={styles.dialogCloseButton}
        onPress={onClose}
      />

      <HighlightClipScroller
        source={source}
        initialSlideIndex={initialSlideIndex}
        className={styles.swiper}
      >
        {clips?.map((clip, index) => (
          <SwiperSlide key={clip.clipId} virtualIndex={index}>
            <PodcastHighlightClip
              clipId={clip.clipId}
              onContinueListening={onClose}
            />
          </SwiperSlide>
        ))}
      </HighlightClipScroller>
    </Dialog>
  );
};

export default PodcastHighlightClipScroller;
