import cn from 'classnames';
import { Heading } from 'components/Typography';
import { useBreakpoints } from 'context/BreakpointsContext';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import { useAppHeaderAnimation } from '../AppHeaderAnimationContext';
import { useAppHeaderDescription } from '../AppHeaderDescriptionContext';
import styles from './AppHeaderSubtitle.module.scss';

export interface AppHeaderSubtitleProps {
  children?: React.ReactNode;
}

const AppHeaderSubtitle: React.FC<AppHeaderSubtitleProps> = ({ children }) => {
  const animation = useAppHeaderAnimation();
  const { md } = useBreakpoints();
  const { isLoading } = useAppHeaderDescription();

  return !isLoading && !children ? null : (
    /*
     * this heading is wrapped in a div because it needs to be vertically
     * centered in a container (the div) using display:flex, but also needs
     * to truncate after a certain number of lines using display:-webkit-box.
     * flex is applied to the div, box is applied to the heading
     */
    <motion.div className={styles.root} {...animation.subtitle}>
      <Heading
        className={cn(styles.primaryText, styles.subtitle)}
        level={md ? 5 : 1}
      >
        {children || <Skeleton width="250px" />}
      </Heading>
    </motion.div>
  );
};

export default AppHeaderSubtitle;
