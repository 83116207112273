import React from 'react';

interface UseKeyBindingConfig {
  bindings: {
    keyCode: string;
    onKeyDown: () => void;
  }[];
}

const useKeyBinding = (config: UseKeyBindingConfig): void => {
  const { bindings } = config;

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      const binding = bindings.find(({ keyCode }) => e.code === keyCode);
      if (binding) {
        e.preventDefault();
        binding.onKeyDown();
      }
    },
    [bindings],
  );

  React.useEffect(() => {
    document.addEventListener('keyup', handleKeyDown);

    return () => {
      document.removeEventListener('keyup', handleKeyDown);
    };
  }, [handleKeyDown]);
};

export default useKeyBinding;
