import cn from 'classnames';
import VisuallyHidden from 'components/VisuallyHidden';
import useHover from 'hooks/useHover';
import useKeyHandler from 'hooks/useKeyHandler';
import useSingletonRenderValue from 'hooks/useSingletonRenderValue';
import useStaticCallback from 'hooks/useStaticCallback';
import { defaultTo } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { clamp, range } from 'utils/math';
import Segment from './Segment';
import styles from './Slider.module.scss';
import {
  ChangeHandler,
  SegmentConfiguration,
  TooltipFormatter,
  UnifiedPointerEvent,
} from './types';
import useTooltip from './useTooltip';
import { indexOfSegment } from './utils';

export interface SliderProps
  extends Pick<React.HTMLProps<HTMLDivElement>, 'onClick'> {
  className?: string;
  disabled?: boolean;
  enableTooltip?: boolean;
  formatTooltip?: TooltipFormatter;
  label?: string;
  max: number;
  min: number;
  onAfterChange?: ChangeHandler;
  onChange?: ChangeHandler;
  railClassName?: string;
  segments?: SegmentConfiguration[];
  thumbClassName?: string;
  trackClassName?: string;
  value: number;
  onBeforeChange?: () => void;
}

// This is basically a slider component that will support novel features like
// chapters.  for other sliders in the app, something like rc-slider should
// probably be used
const Slider: React.FC<SliderProps> = ({
  className,
  disabled,
  enableTooltip = true,
  formatTooltip = (val) => String(val),
  onChange,
  label,
  max,
  min,
  onAfterChange,
  onClick,
  railClassName,
  segments: segmentConfigs,
  thumbClassName,
  value,
  onBeforeChange,
}) => {
  const railRef = useRef<HTMLDivElement>();
  const [tooltipValue, setTooltipValue] = useState<number>(0);
  const [dragging, setDragging] = useState(false);
  const hasSegmentConfigs = segmentConfigs && segmentConfigs.length > 0;

  const segments: SegmentConfiguration[] = useMemo(
    () =>
      !hasSegmentConfigs
        ? [{ id: 'full', startValue: min, endValue: max }]
        : segmentConfigs,
    [hasSegmentConfigs, max, min, segmentConfigs],
  );

  const handleKeyDown = useKeyHandler({
    handledCodes: ['ArrowLeft', 'ArrowRight'],
    callback: (e) => {
      const newValue = e.code === 'ArrowLeft' ? value - 1 : value + 1;
      onAfterChange?.(newValue);
    },
  });

  const { ref: railResizeRef, width = 0 } = useResizeDetector();

  const setRailRef = useCallback(
    (el: HTMLDivElement) => {
      railRef.current = el;
      railResizeRef.current = el as any;
    },
    [railResizeRef],
  );

  const getRailRect = useSingletonRenderValue({
    calculateValue: () => railRef.current?.getBoundingClientRect(),
  });

  // find position of pointer within the rail.  for horizontal left is zero and for
  // vertical top is zero
  const calculatePointerOffset = useStaticCallback((e: UnifiedPointerEvent) => {
    const railRect = getRailRect();
    if (!railRect) {
      return undefined;
    }

    return clamp(e.clientX - railRect.left, 0, railRect.width);
  });

  // convert a position with the rail to a slider value
  const calculateSliderValue = useStaticCallback(
    (eventOrOffset: UnifiedPointerEvent | number | undefined) => {
      if (eventOrOffset === undefined) return undefined;
      const offset =
        typeof eventOrOffset === 'number'
          ? eventOrOffset
          : calculatePointerOffset(eventOrOffset);

      if (offset === undefined) {
        return undefined;
      }

      return range(0, width, min, max, offset);
    },
  );

  const fireChange = useStaticCallback(
    (e: UnifiedPointerEvent, cb: ChangeHandler | undefined) => {
      const sliderValue = calculateSliderValue(e);
      if (sliderValue !== undefined) {
        cb?.(sliderValue);
      }
    },
  );

  const handleTooltipEvent = useCallback(
    (e: UnifiedPointerEvent) => {
      const offset = calculatePointerOffset(e);
      const newTooltipValue = calculateSliderValue(offset);

      if (newTooltipValue !== undefined) {
        setTooltipValue(newTooltipValue);
      }
    },
    [calculatePointerOffset, calculateSliderValue],
  );

  const handlePointerDown = useCallback(
    (e: UnifiedPointerEvent) => {
      e.preventDefault();
      fireChange(e, onChange);
      setDragging(true);
      onBeforeChange?.();
    },
    [fireChange, onChange, onBeforeChange],
  );

  const handlePointerUp = useCallback(
    (e: UnifiedPointerEvent) => {
      setDragging(false);
      fireChange(e, onAfterChange);
    },
    [fireChange, onAfterChange],
  );

  const { hovering, props: hoverProps } = useHover({
    onMouseEnter: useCallback(
      (e) => {
        handleTooltipEvent(e);
      },
      [handleTooltipEvent],
    ),
  });

  const { tooltip, onMouseMove: onPopperMouseMove } = useTooltip({
    contents: formatTooltip(
      tooltipValue,
      // if parent didn't pass a `segments` prop, it won't make sense to pass
      // a segment index, which would be 0 since an array of a single segment
      // is created when no segments are provided via props
      !hasSegmentConfigs ? undefined : indexOfSegment(tooltipValue, segments),
      segments,
    ),
    show: enableTooltip && (hovering || dragging),
  });

  const handlePointerMove = useCallback(
    (e: UnifiedPointerEvent) => {
      // calling prevent default keeps the pointer from selecting text if the user starts
      // a drag action but the pointer leaves the track while dragging
      e.preventDefault();
      const rect = getRailRect();

      if (!rect) return;

      // if the user is dragging, fire change so that slider position updates
      // if user isn't dragging, tooltip just shows value at mouse position and the
      // slider value shouldn't change
      if (dragging) {
        fireChange(e, onChange);
      }

      if (enableTooltip) {
        handleTooltipEvent(e);
        onPopperMouseMove(e, rect);
      }
    },
    [
      dragging,
      enableTooltip,
      fireChange,
      getRailRect,
      handleTooltipEvent,
      onChange,
      onPopperMouseMove,
    ],
  );

  useEffect(() => {
    if (dragging) {
      // add global pointerup and pointermove handlers so that user can still drag
      // even if the pointer leaves the track while dragging
      document.addEventListener('pointermove', handlePointerMove);
      document.addEventListener('pointerup', handlePointerUp);

      return () => {
        document.removeEventListener('pointermove', handlePointerMove);
        document.removeEventListener('pointerup', handlePointerUp);
      };
    }
    return undefined;
  }, [dragging, handlePointerUp, handlePointerMove]);

  const length = { property: 'width', value: width };
  const trackLength = defaultTo(range(min, max, 0, length.value, value), 0);

  return (
    <div
      className={cn(
        styles.root,
        { [styles.root__disabled]: disabled },
        className,
      )}
      onClick={onClick}
      onPointerMove={handlePointerMove}
      role="presentation"
      {...hoverProps}
    >
      {tooltip}
      <div
        className={cn(styles.rail, railClassName)}
        onPointerDown={handlePointerDown}
        ref={setRailRef}
        role="presentation"
      >
        {segments.map(({ id, startValue }, index) => (
          <Segment
            {...{ min, max, startValue, value }}
            endValue={segments[index + 1]?.startValue ?? max}
            key={id ?? startValue}
          />
        ))}
      </div>
      <div
        aria-valuemax={max}
        aria-valuemin={min}
        aria-valuenow={value}
        className={cn(styles.thumb, thumbClassName)}
        onKeyDown={handleKeyDown}
        role="slider"
        style={{
          transform: `translateX(${trackLength}px)`,
        }}
        tabIndex={0}
      >
        {label && <VisuallyHidden>{label}</VisuallyHidden>}
      </div>
    </div>
  );
};

export default Slider;
