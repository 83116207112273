import { usePageHighlightVideoClips } from 'api/hooks/useHighlightVideoClips';
import { HighlightClipScrollerProvider } from 'components/HighlightClipScroller';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PodcastHighlightClipScroller from '../PodcastHighlightClipScroller';
import PodcastClipThumbnail from './PodcastClipThumbnail';
import styles from './PodcastClipThumbnails.module.scss';

export interface PodcastClipThumbnailsProps {}

const PodcastClipThumbnails: React.FC<PodcastClipThumbnailsProps> = () => {
  const [selectedClipId, setSelectedClipId] = useState<number>();
  const {
    data,
    fetchNextPage,
    hasNextPage = true,
  } = usePageHighlightVideoClips();
  const { controls } = useAudioPlayer();

  const handleThumbnailPress = useCallback(
    (clipId: number) => {
      setSelectedClipId(clipId);
      controls?.pause();
    },
    [controls],
  );

  const handleScrollerClose = useCallback(() => {
    setSelectedClipId(undefined);
  }, []);

  return (
    <HighlightClipScrollerProvider>
      <InfiniteScroll
        className={styles.root}
        dataLength={
          data?.pages
            .map((p) => p.videoClips.length)
            .reduce((acc, val) => acc + val, 0) ?? 0
        }
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader="...loading..."
      >
        {data?.pages.map((page) =>
          page.videoClips.map((clip) => (
            <PodcastClipThumbnail
              key={clip.clipId}
              clipId={clip.clipId}
              onPress={handleThumbnailPress}
              data={data}
            />
          )),
        )}
      </InfiniteScroll>
      <PodcastHighlightClipScroller
        initialClipId={selectedClipId}
        isOpen={selectedClipId !== undefined}
        onClose={handleScrollerClose}
        data={data}
      />
    </HighlightClipScrollerProvider>
  );
};

export default PodcastClipThumbnails;
