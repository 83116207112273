import { Spacer } from '@sparemin/blockhead';
import { useBreakpoints } from 'context/BreakpointsContext';
import Controls from 'pages/AudioPlayerPage/Controls';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import styles from './AudioPlayerControls.module.scss';

export interface AudioPlayerControlsProps {
  className?: string;
}

const AudioPlayerControls: React.FC<AudioPlayerControlsProps> = ({
  className,
}) => {
  const { md } = useBreakpoints();
  const { chapters } = useAudioPlayer();

  const hasChapters = chapters?.length !== 0;

  return (
    <Controls>
      <Spacer align="center" className={className} space="medium">
        {hasChapters && (
          <Controls.PreviousChapterButton
            className={styles.controls__secondary}
          />
        )}

        <Controls.SkipBackButton className={styles.controls__secondary} />
        <Controls.PlayButton
          size={md ? 'medium' : 'large'}
          className={styles.playButton}
        />
        <Controls.SkipForwardButton className={styles.controls__secondary} />

        {hasChapters && (
          <Controls.NextChapterButton className={styles.controls__secondary} />
        )}
      </Spacer>
    </Controls>
  );
};

export default AudioPlayerControls;
