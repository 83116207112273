import { TransitionConfig } from './types';

const LONG_DURATION_SEC = 0.2;
const SHORT_DURATION_SEC = LONG_DURATION_SEC / 2;

export const TRANSITIONS: TransitionConfig = {
  in: {
    layer1: {
      duration: LONG_DURATION_SEC,
    },
    layer2: {
      delay: SHORT_DURATION_SEC,
      duration: LONG_DURATION_SEC,
    },
    layer3: {
      delay: SHORT_DURATION_SEC,
      duration: SHORT_DURATION_SEC,
    },
  },
  out: {
    layer1: {
      delay: SHORT_DURATION_SEC,
      duration: LONG_DURATION_SEC,
    },
    layer2: {
      duration: LONG_DURATION_SEC,
    },
    layer3: {
      duration: SHORT_DURATION_SEC,
    },
  },
};
