import { MotionProps } from 'framer-motion';
import { ANIMATION_DURATION_LONG_SEC } from 'utils/constants';
import { Animation } from './types';

export const fade: Readonly<MotionProps> = {
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  initial: { opacity: 0 },
  transition: { duration: ANIMATION_DURATION_LONG_SEC },
};

const animations: Record<Animation, Readonly<MotionProps>> = { fade };

export default animations;
