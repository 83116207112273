import cn from 'classnames';
import React from 'react';
import { OverridableComponentProps } from 'types/react';
import styles from './IconButton.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  color?: 'dark' | 'primary';
  size?: 'medium' | 'large' | 'x-large';
  variant?: 'contained' | 'outlined' | 'solid' | 'transparent';
}

export type IconButtonProps<As extends React.ElementType = 'button'> =
  OverridableComponentProps<Props, As>;

const ForwardedComponent = <As extends React.ElementType = 'button'>(
  {
    as,
    className,
    color = 'primary',
    size = 'medium',
    variant = 'solid',
    ...props
  }: IconButtonProps<As>,
  ref: any,
) => {
  const Component = as ?? 'button';
  return (
    <Component
      className={cn(
        styles.root,
        {
          [styles[`root__${color}`]]: !!color,
          [styles[`root__${variant}`]]: !!variant,
          [styles[`root__${size}`]]: !!size,
        },
        className,
      )}
      ref={ref}
      type="submit"
      {...props}
    />
  );
};

const IconButton: typeof ForwardedComponent = React.forwardRef(
  ForwardedComponent,
) as any;

export default IconButton;
