import { ArrowCircle, IconButton, Spacer, Tooltip } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import { useHighlightClipScroller } from '../HighlightClipScrollerContext';
import { NEXT_BUTTON_CLASS_NAME, PREV_BUTTON_CLASS_NAME } from './constants';
import styles from './HighlightClipScrollerControls.module.scss';

export interface HighlightClipScrollerControlsProps {}

const HighlightClipScrollerControls: React.FC<
  HighlightClipScrollerControlsProps
> = () => {
  const { unmuteOnInteraction, toggleOffUnmuteOnInteraction, unmute } =
    useHighlightClipScroller();

  const handleInteraction = () => {
    if (unmuteOnInteraction) {
      toggleOffUnmuteOnInteraction();
      unmute();
    }
  };
  return (
    <Spacer className={styles.root} space="16px" orientation="vertical">
      <Tooltip
        className={styles.tooltip}
        contents="Previous clip"
        placement="left"
        strategy="fixed"
      >
        <IconButton className={PREV_BUTTON_CLASS_NAME} color="tertiary">
          <ArrowCircle />
        </IconButton>
      </Tooltip>
      <Tooltip
        className={styles.tooltip}
        contents="Next clip"
        placement="left"
        strategy="fixed"
      >
        <IconButton
          className={cn(NEXT_BUTTON_CLASS_NAME, styles.next)}
          color="tertiary"
          onPress={handleInteraction}
        >
          <ArrowCircle />
        </IconButton>
      </Tooltip>
    </Spacer>
  );
};

export default HighlightClipScrollerControls;
