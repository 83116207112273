import React, { useContext } from 'react';
import {
  AppHeaderAnimationContextType,
  AppHeaderAnimationProviderProps,
} from './types';
import useAnimationContextValue from './useAnimationContextValue';

const AppHeaderAnimationContext = React.createContext<
  AppHeaderAnimationContextType | undefined
>(undefined);

export const AppHeaderAnimationProvider: React.FC<
  AppHeaderAnimationProviderProps
> = ({ children }) => (
  <AppHeaderAnimationContext.Provider value={useAnimationContextValue()}>
    {children}
  </AppHeaderAnimationContext.Provider>
);

export function useAppHeaderAnimation() {
  const context = useContext(AppHeaderAnimationContext);

  if (context === undefined) {
    throw new Error(
      'useAppHeaderAnimation must be used within AppHeaderAnimationProvider',
    );
  }

  return context;
}
