import { GetPodcastEpisodeDetailResponse } from 'api/podcast';

export const playerDetailsSelector = (d: GetPodcastEpisodeDetailResponse) => ({
  src: d.originalAudioUrl,
  podcastTitle: d.podcast.title,
  episodeTitle: d.title,
});

export const podcastArtworkSelector = (d: GetPodcastEpisodeDetailResponse) =>
  d.thumbnailImageUrl;
