import { useState } from 'react';
import { useOutlet } from 'react-router-dom';

export interface AnimatedOutletProps {}

// https://github.com/remix-run/react-router/discussions/8008#discussioncomment-1280897
//
// This Outlet is used to animate route transitions that are wrapped in one or more
// layout routes.
//
// Most animation examples will show framer-motion's AnimatePresence wrapped
// around the <Routes /> component, however this example along with some constraints
// enforced by react-router about how <Route/> and <Routes /> are composed makes
// it impossible to animate Outlets.  Without this AnimatedOutlet, the layout
// route is also forced to reload in order to support the transition.
//
// In order for this Outlet to work properly, it should be given a key that changes
// when routes change, e.g.
//    <AnimatedOutlet key={location.pathname} />
//
const AnimatedOutlet: React.FC<AnimatedOutletProps> = () => {
  const [outlet] = useState(useOutlet());
  return outlet;
};

export default AnimatedOutlet;
