import { Button, IconButton, UserCircleFilled } from '@sparemin/blockhead';
import { useBreakpoints } from 'context/BreakpointsContext';

import styles from './RegisterButton.module.scss';

export interface RegisterButtonProps {
  onPress: () => void;
}

const RegisterButton: React.FC<RegisterButtonProps> = ({ onPress }) => {
  const { md } = useBreakpoints();

  if (md) {
    return (
      <IconButton variant="frosted" color="dark" onPress={onPress}>
        <UserCircleFilled className={styles.icon} />
      </IconButton>
    );
  }

  return (
    <Button onPress={onPress} size="small" color="dark" variant="frosted">
      sign up
    </Button>
  );
};

export default RegisterButton;
