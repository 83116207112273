import dayjs from 'dayjs';
import locales from 'dayjs/locale.json';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import findKey from 'lodash/findKey';
import isFinite from 'lodash/isFinite';

dayjs.extend(duration);
dayjs.extend(localizedFormat);

const ONE_MINUTE_MILLIS = 60 * 1000;
const ONE_HOUR_MILLIS = 60 * 60 * 1000;
const TWO_HOURS_MILLIS = ONE_HOUR_MILLIS * 2;

const LOCALES: Record<string, RegExp> = locales.reduce((acc, { key }) => {
  acc[key] = new RegExp(`^${key}`, 'i');
  return acc;
}, {} as Record<string, RegExp>);

function getDayjsLocale() {
  const lang = navigator.language;
  return findKey(LOCALES, (regex) => regex.test(lang));
}

export function formatDuration(
  seconds: number | undefined,
  placeholder: 'zero' | 'unknown' = 'unknown',
) {
  if (seconds === undefined || Number.isNaN(seconds)) {
    if (placeholder === 'unknown') {
      return '-:--:--';
    }

    if (placeholder === 'zero') {
      return '0:00:00';
    }
  }

  return dayjs.duration(seconds as number, 'seconds').format('H:mm:ss');
}

export function formatFriendlyDuration(millis: number) {
  const durationObj = dayjs.duration(millis, 'milliseconds');

  if (millis < ONE_MINUTE_MILLIS) {
    return durationObj.format('s [SEC]');
  }

  if (millis < ONE_HOUR_MILLIS) {
    return durationObj.format('m [MIN]');
  }

  if (millis >= ONE_HOUR_MILLIS && millis < TWO_HOURS_MILLIS) {
    return durationObj.format('H [HOUR] m [MIN]');
  }

  return durationObj.format('H [HOURS] m [MIN]');
}

export async function formatLocalDate(millis: number): Promise<string> {
  const locale = getDayjsLocale() ?? 'en';

  await import(`dayjs/locale/${locale}.js`);
  return dayjs(millis).locale(locale).format('ll');
}

export function millisToSec(value: number): number;
export function millisToSec(value: number | undefined): number | undefined;
export function millisToSec(value: any): number | undefined {
  if (!isFinite(value)) {
    return undefined;
  }

  return (value as number) / 1000;
}

export function secToMillis(value: number): number {
  return value * 1000;
}
