import { useGetPodcastTitle } from 'api/hooks/useGetPodcastDetail';
import { useIsBlockedEpisode } from 'api/hooks/useGetPodcastEpisodeDetail';
import cn from 'classnames';
import EpisodeCardBody from 'components/EpisodeCardBody';
import EpisodeCardFooter from 'components/EpisodeCardFooter';
import EpisodeCardHeader from 'components/EpisodeCardHeader';
import LinkCard from 'components/LinkCard';
import { useEventTracking } from 'context/EventTrackingContext';
import React, { useCallback } from 'react';
import { createEpisodePath } from 'utils/routes';
import styles from './EpisodeCard.module.scss';
import { EpisodeCardProps } from './types';

const EpisodeCard: React.FC<EpisodeCardProps> = ({
  className,
  episode,
  podcastId,
  style,
  pageType,
  onClick,
  badge,
  onCardClick,
  onPlayBadgeClick,
}) => {
  const remoteEpisodeId = episode?.remoteEpisodeId;
  const episodeName = episode?.title;

  const { data: podcastName } = useGetPodcastTitle(podcastId);

  const { data: isBlocked } = useIsBlockedEpisode({
    podcastId,
    episodeId: episode?.remoteEpisodeId,
  });

  const pageSource = pageType === 'Queue' ? 'queue' : undefined;

  const { trackPlayback, trackClickBlockedEpisodeCard, trackClickEpisodeCard } =
    useEventTracking();

  const isLoading = !episode;

  const [pathWithStartSec, pathOptsWithStartSec] =
    !remoteEpisodeId || !podcastId
      ? []
      : createEpisodePath(remoteEpisodeId, podcastId, {
          startSec: 0,
          source: pageSource,
        });

  const [path, pathOpts] =
    !remoteEpisodeId || !podcastId
      ? []
      : createEpisodePath(remoteEpisodeId, podcastId, {
          source: pageSource,
        });

  const handleCardClick = useCallback(() => {
    onClick?.();
    onCardClick?.();
    if (
      podcastId &&
      remoteEpisodeId &&
      podcastName &&
      episodeName &&
      pageType
    ) {
      trackClickEpisodeCard({
        podcastId,
        episodeId: remoteEpisodeId,
        podcastName,
        episodeName,
        source: pageType,
      });
    }
  }, [
    onClick,
    podcastId,
    remoteEpisodeId,
    podcastName,
    episodeName,
    pageType,

    trackClickEpisodeCard,
    onCardClick,
  ]);

  const handleBadgeClick = useCallback(() => {
    onClick?.();
    onPlayBadgeClick?.();
    if (isBlocked && podcastName && episodeName) {
      trackClickBlockedEpisodeCard(podcastName, episodeName);
    } else if (podcastId && remoteEpisodeId && podcastName && episodeName) {
      trackPlayback('play', {
        source: 'EpisodeCard',
        podcastName,
        episodeName,
        podcastId,
        remoteEpisodeId,
      });
    }
  }, [
    trackPlayback,
    podcastName,
    episodeName,
    podcastId,
    remoteEpisodeId,
    isBlocked,
    trackClickBlockedEpisodeCard,
    onClick,
    onPlayBadgeClick,
  ]);

  return (
    <LinkCard
      cardClassName={styles.card}
      className={cn(styles.root, className)}
      disabled={!episode}
      onClick={handleCardClick}
      style={style}
      to={path || ''}
      state={pathOpts?.state}
    >
      <LinkCard.BackgroundImage
        blurValue="30px"
        className={styles.backgroundImage}
        src={episode?.thumbnailImageUrl}
      />
      <EpisodeCardHeader
        isLoading={isLoading}
        thumbnailImageUrl={episode?.thumbnailImageUrl}
        title={episode?.title}
        isBlocked={isBlocked}
        podcastName={podcastName}
        badge={badge}
      />
      <EpisodeCardBody
        descriptionHtml={episode?.description}
        isLoading={isLoading}
      />
      <EpisodeCardFooter
        audioDurationMillis={episode?.audioDurationMillis}
        isLoading={isLoading}
        onClickBadge={handleBadgeClick}
        path={pathWithStartSec}
        pathOpts={pathOptsWithStartSec}
        publishedAtMillis={episode?.publishedAtMillis}
        isBlocked={isBlocked}
      />
    </LinkCard>
  );
};

export default EpisodeCard;
