import Divider from 'components/Divider';
import Spacer from 'components/Spacer';
import useTimeout from 'hooks/useTimeout';
import range from 'lodash/range';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { createRef, RefObject, useEffect, useRef } from 'react';
import { STYLE_CONSTANTS } from 'utils/constants';
import { smoothScrollIntoView } from 'utils/ui';
import { useEpisodeParts } from '../state/usePagePodcastEpisodeDetail';
import EpisodePart from './EpisodePart';
import EpisodePartsFooter from './EpisodePartsFooter';

export interface EpisodePartsProps {
  className?: string;
}

const EpisodeParts: React.FC<EpisodePartsProps> = ({ className }) => {
  const { data } = useEpisodeParts();

  const parts = data?.chapters;

  const { scrollChapterIndex, scrollOptions } = useAudioPlayer();

  const [startScroll, cancelScroll] = useTimeout({
    callback: (el: HTMLElement) =>
      smoothScrollIntoView(el, {
        block: 'start',
        duration: scrollOptions.duration,
        offsetY: -parseInt(STYLE_CONSTANTS.headerHeightCondensed, 10),
      }),
    delay: scrollOptions.delay * 1000,
  });

  const partRefs = useRef<Array<RefObject<HTMLDivElement>>>([]);

  useEffect(() => {
    if (scrollChapterIndex && parts && scrollOptions.scroll) {
      const element = partRefs.current[scrollChapterIndex]?.current;

      if (element) {
        startScroll(element);
      }
    }

    return () => {
      cancelScroll();
    };
  }, [
    scrollChapterIndex,
    parts,
    startScroll,
    cancelScroll,
    scrollOptions.scroll,
  ]);

  return (
    <Spacer space={0} orientation="vertical" className={className}>
      {!parts
        ? range(5).map((i) => [
            <EpisodePart key={i} partId={-1} />,
            <Divider key={`divider-${i}`} />,
          ])
        : parts.map((part, index) => {
            if (!partRefs.current[index]) {
              partRefs.current[index] = createRef();
            }

            return [
              <EpisodePart
                key={part.id}
                partId={part.id}
                ref={partRefs.current[index]}
              />,
              <Divider key={`${part.id}-divider`} />,
            ];
          })}
      {parts && <EpisodePartsFooter />}
    </Spacer>
  );
};

export default EpisodeParts;
