import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import FollowButton, { FollowButtonProps } from 'components/FollowButton';
import { useBreakpoints } from 'context/BreakpointsContext';
import styles from './AppHeaderUserButtons.module.scss';
import PodcatcherLink from './PodcatcherLink';

export interface AppHeaderUserButtonsProps
  extends Pick<
    FollowButtonProps,
    'feedId' | 'source' | 'podcastName' | 'className'
  > {
  itunesUrl?: string;
  spotifyUrl?: string;
  shareButton?: React.ReactNode;
}

const AppHeaderUserButtons: React.FC<AppHeaderUserButtonsProps> = ({
  source,

  feedId,
  itunesUrl,
  podcastName,
  spotifyUrl,
  className,
  shareButton,
}) => {
  const { md } = useBreakpoints();

  return (
    <Spacer
      className={cn(styles.buttonBox, className)}
      orientation={md ? 'horizontal' : 'vertical'}
    >
      <FollowButton
        className={styles.button}
        source={source}
        feedId={feedId}
        podcastName={podcastName}
      />
      <Spacer orientation="horizontal" align="center">
        <PodcatcherLink
          url={itunesUrl}
          source="Apple"
          podcastName={podcastName}
        />
        <PodcatcherLink
          url={spotifyUrl}
          source="Spotify"
          podcastName={podcastName}
        />
        {shareButton}
      </Spacer>
    </Spacer>
  );
};
export default AppHeaderUserButtons;
