import React, { useContext } from 'react';
import { EventTrackingContextType } from './types';

export const EventTrackingContext = React.createContext<
  EventTrackingContextType | undefined
>(undefined);

export function useEventTracking() {
  const context = useContext(EventTrackingContext);

  if (context === undefined) {
    throw new Error(
      'useEventTracking must be used within EventTrackingProvider',
    );
  }

  return context;
}
