import { CheckCircle, Toast, ErrorToast } from '@sparemin/blockhead';
import React from 'react';
import { toast, ToastOptions as ToastifyOptions } from 'react-toastify';
import {
  ToastOptions,
  NotifySuccessOptions,
  NotifyErrorOptions,
} from './types';

const defaultToastOptions: ToastifyOptions = {
  hideProgressBar: true,
  closeOnClick: true,

  closeButton: false,
};

const showNotification = (content: React.ReactNode, opts?: ToastOptions) => {
  toast(content, {
    ...defaultToastOptions,
    ...opts,
  });
};

export function notifyError({
  toastId,
  autoClose,
  ...toastProps
}: NotifyErrorOptions) {
  showNotification(<ErrorToast {...toastProps} />, { toastId, autoClose });
}

export function notifySuccess({
  toastId,
  autoClose,
  ...toastProps
}: NotifySuccessOptions) {
  showNotification(<Toast icon={<CheckCircle />} {...toastProps} />, {
    toastId,
    autoClose,
  });
}
