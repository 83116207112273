import cn from 'classnames';
import * as React from 'react';
import { OverridableComponentProps } from 'types/react';
import styles from './Chip.module.scss';

interface Props {
  fluid?: boolean;
  size?: 'large' | 'small';
  theme?: 'primary' | 'dark' | 'gradient';
}

export type ChipProps<As extends React.ElementType = 'button'> =
  OverridableComponentProps<Props, As>;

const ForwardedComponent = <As extends React.ElementType = 'button'>(
  {
    as,
    children,
    className,
    fluid,
    size = 'large',
    theme = 'primary',
    ...props
  }: ChipProps<As>,
  ref: any,
) => {
  const Component = as ?? 'button';

  return (
    <Component
      className={cn(
        styles.root,
        {
          [styles[`root__${size}`]]: true,
          [styles[`root__${theme}`]]: true,
          [styles.root__fluid]: fluid,
        },
        className,
      )}
      ref={ref}
      type="button"
      {...props}
    >
      {children}
    </Component>
  );
};

const Chip: typeof ForwardedComponent = React.forwardRef(
  ForwardedComponent,
) as any;

export default Chip;
