import cn from 'classnames';
import { Heading } from 'components/Typography';
import { motion } from 'framer-motion';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppHeaderAnimation } from '../AppHeaderAnimationContext';
import { useAppHeaderDescription } from '../AppHeaderDescriptionContext';
import styles from './AppHeaderTitle.module.scss';

export interface AppHeaderTitleProps {
  children?: React.ReactNode;
  className?: string;
}

const AppHeaderTitle = React.forwardRef<
  HTMLHeadingElement,
  AppHeaderTitleProps
>(({ children, className }, ref) => {
  const animation = useAppHeaderAnimation();
  const { isLoading } = useAppHeaderDescription();

  return !isLoading && !children ? null : (
    <Heading
      as={motion.h2}
      className={cn(styles.root, className)}
      level={2}
      ref={ref}
      {...animation.title}
    >
      {isLoading ? <Skeleton width={350} /> : children}
    </Heading>
  );
});

export default AppHeaderTitle;
