import cn from 'classnames';
import Chip from 'components/Chip';
import { Text } from 'components/Typography';
import styles from './BlockedEpisodeBadge.module.scss';

export interface BlockedEpisodeBadgeProps {
  className?: string;
}

const BlockedEpisodeBadge: React.FC<BlockedEpisodeBadgeProps> = ({
  className,
}) => (
  <Chip className={cn(styles.root, className)} size="small" theme="dark">
    <Text className={styles.text} type="details">
      private episode
    </Text>
  </Chip>
);

export default BlockedEpisodeBadge;
