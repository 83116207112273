import { Button, Search } from '@sparemin/blockhead';
import { useEventTracking } from 'context/EventTrackingContext';
import { useModalControls } from 'context/ModalContext';
import React from 'react';

import styles from './SearchButton.module.scss';
import useSearchModalKeyBinding from './useSearchModalKeyBinding';

const SearchButton: React.FunctionComponent = () => {
  const { open } = useModalControls();

  const { trackSearchBarExpanded } = useEventTracking();

  const handlePressSeach = React.useCallback(() => {
    trackSearchBarExpanded();
    open({ name: 'SearchModal' });
  }, [open, trackSearchBarExpanded]);

  useSearchModalKeyBinding();

  return (
    <Button
      className={styles.root}
      color="light"
      onPress={handlePressSeach}
      startIcon={<Search className={styles.root__icon} />}
      variant="frosted"
    >
      <span className={styles.root__label}>
        Search for your favorite podcasts
      </span>
    </Button>
  );
};

export default SearchButton;
