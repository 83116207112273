export function clamp(
  val: number,
  minVal: number = -Infinity,
  maxVal: number = Infinity,
) {
  if (val < minVal) {
    return minVal;
  }
  return val > maxVal ? maxVal : val;
}

/**
 * Linear Interpolation
 *
 * Returns the value between two numbers at a specified, decimal midpoint
 *
 * Source: https://www.trysmudford.com/blog/linear-interpolation-functions/
 */
export const lerp = (x: number, y: number, a: number): number =>
  x * (1 - a) + y * a;

/**
 * Inverse Linear Interpolation
 *
 * This works in the opposite way to the lerp. Instead of passing a decimal midpoint,
 * you pass any value, and it’ll return that decimal, wherever it falls on that spectrum
 *
 * Source: https://www.trysmudford.com/blog/linear-interpolation-functions/
 */
export const invlerp = (x: number, y: number, a: number) =>
  clamp((a - x) / (y - x), 0, 1);

/**
 * Range interpolation
 *
 * Converts a value from one data range to another
 * Eg.: range(10, 100, 2000, 20000, 50) => 10000
 *
 * Source: https://www.trysmudford.com/blog/linear-interpolation-functions/
 */
export const range = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  a: number,
) => lerp(x2, y2, invlerp(x1, y1, a));
