import Anchor from 'components/Anchor';
import { Heading } from 'components/Typography';
import { DISCO_HOMEPAGE_URL } from 'utils/constants';
import styles from './EpisodePartsFooter.module.scss';

export interface EpisodePartsFooterProps {}

const EpisodePartsFooter: React.FC<EpisodePartsFooterProps> = () => (
  <div className={styles.root}>
    <Heading className={styles.text} level={2} letterSpacing="0.5px">
      Want a page like this for your podcast?{' '}
      <Anchor href={DISCO_HOMEPAGE_URL} target="_blank">
        Sign up for Disco!
      </Anchor>
    </Heading>
  </div>
);

export default EpisodePartsFooter;
