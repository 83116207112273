import { ModalName, ShowableModal, State as ModalState } from './types';

export function getMatchingFirstModal(
  state: ModalState,
  name?: ModalName,
): ShowableModal | undefined {
  const first = state[0];
  if (name) {
    if (!first || first.name !== name) {
      return undefined;
    }
  }

  return first ?? undefined;
}
