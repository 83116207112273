import { notifySuccess } from 'components/notification';
import ShareButton from 'components/ShareButton';
import { useEventTracking } from 'context/EventTrackingContext';
import React from 'react';
import { copyToClipboard } from 'utils/clipboard';
import { prepareShareUrl } from 'utils/share';

type PodcastShareButtonProps = {};

const PodcastShareButton: React.FC<PodcastShareButtonProps> = () => {
  const { trackSharePodcast } = useEventTracking();

  const handleClick = () => {
    const url = window.location.href;
    const shareUrl = prepareShareUrl(url);
    copyToClipboard(shareUrl);
    notifySuccess({ heading: 'Podcast link copied to clipboard!' });
    trackSharePodcast();
  };

  return <ShareButton onPress={handleClick} />;
};
export default PodcastShareButton;
