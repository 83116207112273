import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';

import { BlockheadProvider, StyleSheetManager } from '@sparemin/blockhead';
import AxiosGlobalConfiguration from 'api/components/AxiosGlobalConfiguration';
import AppRouter, { AppRouterProvider } from 'components/AppRouter';
import ToastContainer from 'components/ToastContainer';
import WhenNotPreRendering from 'components/WhenNotPreRendering';
import { AuthProvider } from 'context/AuthContext';
import { BreakpointsProvider } from 'context/BreakpointsContext';
import { EventTrackingProvider } from 'context/EventTrackingContext';
import { ModalProvider } from 'context/ModalContext';
import { QueryClientProvider } from 'context/QueryClientContext';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ReactQueryDevtools } from 'react-query/devtools';

import { SKELETON_BASE_COLOR, SKELETON_HIGHLIGHT_COLOR } from 'utils/constants';
import GoogleAnalytics from './GoogleAnalytics';
import Metadata from './Metadata';
import { blockheadTheme } from './theme';

const Modals = React.lazy(() => import('./Modals'));

const App: React.FC = () => (
  <BreakpointsProvider>
    <AppRouterProvider>
      <QueryClientProvider>
        <EventTrackingProvider>
          <SkeletonTheme
            baseColor={SKELETON_BASE_COLOR}
            highlightColor={SKELETON_HIGHLIGHT_COLOR}
          >
            <ModalProvider>
              <HelmetProvider>
                <BlockheadProvider theme={blockheadTheme}>
                  <ToastContainer />
                  <Metadata />
                  <GoogleAnalytics />
                  <StyleSheetManager
                    target={document.getElementById('sc-inject')}
                  >
                    <AuthProvider>
                      <AxiosGlobalConfiguration />
                      <AppRouter />
                      <WhenNotPreRendering>
                        {() => <Modals />}
                      </WhenNotPreRendering>
                    </AuthProvider>
                  </StyleSheetManager>
                </BlockheadProvider>
              </HelmetProvider>
            </ModalProvider>
          </SkeletonTheme>
        </EventTrackingProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AppRouterProvider>
  </BreakpointsProvider>
);

export default App;
