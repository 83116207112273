import { Thumbnail, ThumbnailProps } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import LockCircle from './LockCircle';
import styles from './LockedThumbnail.module.scss';

interface LockedThumbnailProps extends ThumbnailProps {
  size?: 'small' | 'large';
}

const LockedThumbnail: React.FC<LockedThumbnailProps> = (props) => {
  const { className, size = 'large', ...rest } = props;

  return (
    <span className={cn(styles.root, className)}>
      <Thumbnail className={styles.thumbnail} {...rest} />
      <LockCircle size={size} />
    </span>
  );
};
export default LockedThumbnail;
