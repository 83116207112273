import cn from 'classnames';
import React from 'react';

import styles from './Header.module.scss';

export interface HeaderProps {
  action?: React.ReactNode;
  className?: string;
  icon: React.ReactNode;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({
  action,
  className,
  icon,
  subtitle,
  title,
}) => (
  <div className={cn(styles.root, className)}>
    {icon &&
      React.isValidElement(icon) &&
      React.cloneElement(icon, {
        className: cn(styles.icon, icon.props.className),
      })}
    <div className={styles.contentRow}>
      {!React.isValidElement(title) ? (
        <span className={styles.title}>{title}</span>
      ) : (
        React.cloneElement(title, {
          className: cn(title.props.className, styles.title),
        })
      )}
      {!React.isValidElement(subtitle) ? (
        <span className={styles.subtitle}>{subtitle}</span>
      ) : (
        React.cloneElement(subtitle, {
          className: cn(subtitle.props.className, styles.subtitle),
        })
      )}
    </div>
    {action &&
      React.isValidElement(action) &&
      React.cloneElement(action, {
        className: cn(styles.action, action.props.className),
      })}
  </div>
);

export default Header;
