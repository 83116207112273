import { useCallback, useEffect } from 'react';
import useHoverable from './useHoverable';
import useToggle from './useToggle';

interface UseHoverConfig<E = Element> {
  onMouseEnter?: (e: React.MouseEvent<E>) => void;
  onMouseLeave?: (e: React.MouseEvent<E>) => void;
}

interface UseHoverResult<E = Element> {
  hovering: boolean;
  props: {
    onMouseEnter: ((e: React.MouseEvent<E>) => void) | undefined;
    onMouseLeave: ((e: React.MouseEvent<E>) => void) | undefined;
  };
}

export default function useHover<E = Element>(
  config?: UseHoverConfig<E>,
): UseHoverResult<E> {
  const {
    value: hovering,
    toggleOn: setHovering,
    toggleOff: unsetHovering,
  } = useToggle();
  const { onMouseEnter, onMouseLeave } = config ?? {};
  const isHoverable = useHoverable();

  useEffect(() => {
    // if somehow isHoverable changes to false while a hover is in progress, clear
    // hover state
    if (!isHoverable) {
      unsetHovering();
    }
  }, [isHoverable, unsetHovering]);

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<E>) => {
      setHovering();
      onMouseEnter?.(e);
    },
    [onMouseEnter, setHovering],
  );

  const handleMouseLeave = useCallback(
    (e: React.MouseEvent<E>) => {
      unsetHovering();
      onMouseLeave?.(e);
    },
    [onMouseLeave, unsetHovering],
  );

  return {
    hovering,
    props: {
      onMouseEnter: isHoverable ? handleMouseEnter : undefined,
      onMouseLeave: isHoverable ? handleMouseLeave : undefined,
    },
  };
}
