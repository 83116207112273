import React, { useContext, useMemo } from 'react';
import { RoutingErrorContextType, RoutingErrorProviderProps } from './types';

const RoutingErrorContext = React.createContext<
  RoutingErrorContextType | undefined
>(undefined);

export const RoutingErrorProvider: React.FC<RoutingErrorProviderProps> = ({
  children,
  setRoutingError,
}) => (
  <RoutingErrorContext.Provider
    value={useMemo(() => ({ setRoutingError }), [setRoutingError])}
  >
    {children}
  </RoutingErrorContext.Provider>
);

export function useRoutingError() {
  const context = useContext(RoutingErrorContext);

  if (context === undefined) {
    throw new Error('useRoutingError must be used within RoutingErrorProvider');
  }

  return context;
}
