import { millisToSec } from './time';

// the end of a chapter has the same timestamp as the beginning of the next,
// so seeking to chapter.startSec is ambiguous as to whether we're seeking to
// the end of chapter n or the beginning of chapter n + 1.  Add 1 second to the
// seek time so that we ensure we're in the correct chapter.
export function getChapterStartSec(
  startTime: number,
  unit: 'sec' | 'millis' = 'sec',
): number {
  const startSec = unit === 'sec' ? startTime : millisToSec(startTime);
  return startSec + 1;
}
