import { motion } from 'framer-motion';
import animations from './animations';
import { Animation } from './types';

export interface AnimatedRouteContainerProps {
  animation?: Animation;
  children?: React.ReactNode;
  className?: string;
}

/**
 * To be used with AnimatedRouteLayout.
 *
 * The layout component renders <AnimatePresence>, which then triggers the
 * AnimatedRouteContainer component to transition in and out.
 */
const AnimatedRouteContainer: React.FC<AnimatedRouteContainerProps> = ({
  animation = 'fade',
  children,
  className,
}) => (
  <motion.div className={className} {...animations[animation]}>
    {children}
  </motion.div>
);

export default AnimatedRouteContainer;
