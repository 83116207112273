import { EpisodeChapter } from 'api';
import { MotionProps, Transition } from 'framer-motion';

export enum AnimationVariant {
  DEFAULT = 'default',
  EXPAND = 'expand',
  HOVER = 'hover',
}

export type AnimatableElement =
  | 'container'
  | 'headline'
  | 'label'
  | 'playButton'
  | 'suggestions'
  | 'suggestionsContainer';

export type AnimatePresenceElement =
  | 'playButton'
  | 'suggestions'
  | 'suggestionsContainer';

export interface UseAnimationConfig {
  disabled: boolean;
  isHoveringContainer: boolean;
  isExpanded: boolean;
}

export interface ChapterWithIndex extends EpisodeChapter {
  index: number;
}

export type AnimationProps = Omit<MotionProps, 'style'>;

export interface AnimationConfig {
  props: AnimationProps;
}

export interface AnimationConfigWithPresence extends AnimationConfig {
  visible: boolean;
}

export type UseAnimationResult = Record<
  Exclude<AnimatableElement, AnimatePresenceElement>,
  AnimationConfig
> &
  Record<AnimatePresenceElement, AnimationConfigWithPresence>;

export interface TransitionDescription {
  in: Record<string, Transition>;
  out: Record<string, Transition>;
}
