import AnimatedOutlet from 'components/AnimatedOutlet';
import { AnimatePresence, AnimatePresenceProps } from 'framer-motion';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export interface AnimatedRouteLayoutProps extends AnimatePresenceProps {
  // in case outlet pages use body scroll, reset it during the transition, at a time
  // when it won't be disruptive to the user
  scrollToTopOnTransition?: boolean;
}

/**
 * A component designed to be used as a "layout route".  Any child components
 * (nested routes) that have AnimatedRouteContainer at their root will transition
 * on route change
 */
const AnimatedRouteLayout: React.FC<AnimatedRouteLayoutProps> = ({
  scrollToTopOnTransition = true,
  onExitComplete,
  ...props
}) => {
  const location = useLocation();

  const handleExitComplete = useCallback(() => {
    if (scrollToTopOnTransition) {
      window.scrollTo(0, 0);
    }
    onExitComplete?.();
  }, [onExitComplete, scrollToTopOnTransition]);

  return (
    <AnimatePresence
      exitBeforeEnter
      initial={false}
      onExitComplete={handleExitComplete}
      {...props}
    >
      <AnimatedOutlet key={location.pathname} />
    </AnimatePresence>
  );
};

export default AnimatedRouteLayout;
