import useChapterSuggestions from 'api/hooks/useChapterSuggestions';
import { SuggestionList } from 'components/SuggestionCard';
import { motion, MotionProps } from 'framer-motion';
import SuggestionCard from '../SuggestionCard';

export interface EpisodePartSuggestionsProps
  extends Omit<MotionProps, 'style'> {
  partId: number;
}

const EpisodePartSuggestions: React.FC<EpisodePartSuggestionsProps> = ({
  partId,
  ...motionProps
}) => {
  const { data, isLoading } = useChapterSuggestions(partId);

  return (
    <SuggestionList
      as={motion.div}
      loading={isLoading}
      placeholderCount={3}
      {...motionProps}
    >
      {data?.data.slice(0, 3).map((suggestion) => (
        <SuggestionCard
          // TODO move partId to EpisodePartContext so we don't have to drill it down
          // through EpisodePartSuggestions and SuggestionCard
          chapterId={partId}
          key={suggestion.id}
          suggestionId={suggestion.id}
        />
      ))}
    </SuggestionList>
  );
};

export default EpisodePartSuggestions;
