import cn from 'classnames';
import Article from 'components/Article';
import React from 'react';
import styles from './AppBody.module.scss';

export interface AppBodyProps {
  children?: React.ReactNode;
  className?: string;
}

/**
 * Pages which use the AppHeader require bodies that are aware of the header
 */
const AppBody: React.FC<AppBodyProps> = ({ children, className }) => (
  <Article className={cn(styles.root, className)}>
    {React.isValidElement(children) &&
      React.cloneElement(children, {
        className: cn(styles.contents, children.props.className),
      })}
  </Article>
);

export default AppBody;
