import IconButton, { IconButtonProps } from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import useHoverable from 'hooks/useHoverable';
import * as React from 'react';
import { useAudioPlayerControls } from '../state/AudioPlayerControlsContext';

interface ControlButtonProps extends IconButtonProps {
  iconButton?: React.ReactNode;
  tooltip?: string;
  style?: React.CSSProperties;
}

const ControlButton: React.FC<ControlButtonProps> = ({
  className,
  disabled,
  iconButton,
  tooltip,
  style,
  ...props
}) => {
  const { loading } = useAudioPlayerControls();
  const isHoverable = useHoverable();

  return (
    <div className={className} style={style}>
      <Tooltip
        contents={isHoverable ? tooltip : undefined}
        show={tooltip === undefined || !isHoverable ? false : undefined}
      >
        {iconButton && React.isValidElement(iconButton) ? (
          React.cloneElement(iconButton, {
            disabled: iconButton.props.disabled || loading || disabled,
          })
        ) : (
          <IconButton
            color="dark"
            disabled={loading || disabled}
            variant="transparent"
            {...props}
          />
        )}
      </Tooltip>
    </div>
  );
};

export default ControlButton;
