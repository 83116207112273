import { AUTH_USER_SERVICE_URL } from 'config';

// 3 days
export const TOKEN_RENEWAL_THRESHOLD_MILLIS = 3 * 24 * 60 * 60 * 1000;

// 1 hour
export const TOKEN_CHECK_INTERVAL_MILLIS = 60 * 60 * 1000;

// a lot of old services required a token in the Authorization header without a
// Bearer prefix.  All of the newer services require a Bearer prefix, but for the
// old service, we need to remove the prefix.  There is an axios request interceptor
// which looks for requests going to any of the following services and removes the
// Bearer prefix from the Authorization header before issuing the request.
export const NO_BEARER_PREFIX_SERVICE_URLS = [
  AUTH_USER_SERVICE_URL,
] as string[];

export const SOURCE_APPLICATION_NAME = 'discoPlay';
