import cn from 'classnames';
import React from 'react';

import { getClassNameWithVariant } from 'utils/styles';

import styles from './AppTopbar.module.scss';

interface AppTopbarProps {
  topCenterElement?: React.ReactNode;
  topLeftElement?: React.ReactNode;
  topRightElement?: React.ReactNode;
  className?: string;
}

const AppTopbar: React.FunctionComponent<AppTopbarProps> = (props) => {
  const { topCenterElement, topLeftElement, topRightElement, className } =
    props;

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.innerContainer}>
        <div className={getClassNameWithVariant(styles, 'slot', 'left')}>
          {topLeftElement}
        </div>
        <div className={getClassNameWithVariant(styles, 'slot', 'center')}>
          {topCenterElement}
        </div>
        <div className={getClassNameWithVariant(styles, 'slot', 'right')}>
          {topRightElement}
        </div>
      </div>
    </div>
  );
};

export default AppTopbar;
