import { AxiosClient, configure as configureApi } from '@sparemin/api-sdk';
import { configure as configureAuth, sso } from '@sparemin/auth';
import { loadFonts } from '@sparemin/blockhead';
import { request } from 'api/http';
import { RUNTIME_ENV } from 'config';
import { APPLE_AUTH_REDIRECT_URI } from 'config/env';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { APP_ROOT_ID } from 'utils/constants';
import App from './components/App';

loadFonts();

const spareminLibraryEnv = (() => {
  if (process.env.NODE_ENV !== 'production') {
    return 'local';
  }

  if (RUNTIME_ENV === 'production') {
    return 'prod';
  }

  return 'dev';
})();

configureAuth({
  environment: spareminLibraryEnv,
  appleRedirectURI: APPLE_AUTH_REDIRECT_URI,
  application: 'discoPlay',
});

configureApi({
  client: new AxiosClient(request),
  environment: spareminLibraryEnv,
  getSpareminToken: sso.getToken,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById(APP_ROOT_ID),
);
