import cn from 'classnames';
import ShowMoreText from 'components/ShowMoreText';
import { Heading } from 'components/Typography';
import { useBreakpoints } from 'context/BreakpointsContext';
import Skeleton from 'react-loading-skeleton';
import { getTextContentFromHtmlString } from 'utils/dom';
import usePagePodcastDetail from '../usePagePodcastDetail';
import styles from './PodcastDescription.module.scss';

export interface PodcastDescriptionProps {
  className?: string;
  style?: React.CSSProperties;
}

const PodcastDescription: React.FC<PodcastDescriptionProps> = ({
  className,
  style,
}) => {
  const { data: podcastDetailsData, isLoading } = usePagePodcastDetail();
  const { md } = useBreakpoints();

  if (isLoading || podcastDetailsData?.description) {
    return (
      <Heading
        className={cn(styles.root, className)}
        level={2}
        letterSpacing={0.5}
        style={style}
      >
        {isLoading ? (
          <Skeleton width="100%" count={2} />
        ) : (
          <ShowMoreText lines={md ? 3 : 2}>
            {getTextContentFromHtmlString(podcastDetailsData?.description)}
          </ShowMoreText>
        )}
      </Heading>
    );
  }

  return <div className={className} style={style} />;
};

export default PodcastDescription;
