import { formatDuration } from 'utils/time';
import { SegmentConfiguration } from './Slider/types';

export function resolveProgressValue(
  seekDraftTime: number | undefined,
  currentTime: number | undefined,
) {
  if (seekDraftTime !== undefined) {
    return seekDraftTime;
  }

  if (currentTime !== undefined) {
    return currentTime;
  }

  return 0;
}

export function formatTooltip(
  value: number,
  segmentIndex: number | undefined,
  segmentConfigs: SegmentConfiguration[],
): React.ReactNode {
  const formattedDuration = formatDuration(value);

  return segmentIndex === undefined || !segmentConfigs.length ? (
    formattedDuration
  ) : (
    <>
      {formattedDuration}&nbsp;&nbsp;{segmentConfigs[segmentIndex].headline}
    </>
  );
}
