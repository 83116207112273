import {
  useGetPodcastEpisodes,
  UseGetPodcastEpisodesOptions,
} from 'api/hooks/useGetPodcastEpisodes';
import { GetPodcastEpisodesResponse } from 'api/podcast';
import { podcastEpisodesSelector } from 'api/selectors/podcast';
import { useCallback } from 'react';
import { InfiniteData } from 'react-query';
import { usePodcastPage } from './PodcastPageContext';

export default function usePagePodcastEpisodes<
  TData = GetPodcastEpisodesResponse,
>(opts?: UseGetPodcastEpisodesOptions<TData>) {
  const { podcastId } = usePodcastPage();
  return useGetPodcastEpisodes(podcastId, opts);
}

const episodeSelector = (
  d: InfiniteData<GetPodcastEpisodesResponse>,
  episodeId: string | undefined,
) => {
  if (!episodeId) {
    return { ...d, pages: [] };
  }

  const episodes = podcastEpisodesSelector(d);
  const episode = episodes.find((e) => e.remoteEpisodeId === episodeId);

  return {
    ...d,
    pages: [episode],
  };
};

export const usePagePodcastEpisode = (episodeId: string | undefined) => {
  const { data, ...rest } = usePagePodcastEpisodes({
    select: useCallback((d) => episodeSelector(d, episodeId), [episodeId]),
  });

  return {
    episode: data?.pages?.[0],
    ...rest,
  };
};
