import { MotionProps, PanInfo } from 'framer-motion';
import { ANIMATION_DURATION_MEDIUM_SEC } from 'utils/constants';
import useNowPlayingCard from '../state/NowPlayingCardContext';

interface UseNowPlayingCardanimationConfig {
  allowSwipeToClose?: boolean;
  blockSwipeToCloseClassName?: string;
}

export default function useNowPlayingCardCardAnimation({
  allowSwipeToClose = true,
  blockSwipeToCloseClassName,
}: UseNowPlayingCardanimationConfig): MotionProps {
  const { hide } = useNowPlayingCard();

  const closed = { y: '100%' };
  const open = { y: 0 };

  const handleDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ) => {
    const targetElement = event.target as HTMLElement;
    if (blockSwipeToCloseClassName) {
      if (targetElement.closest(`.${blockSwipeToCloseClassName}`)) {
        return;
      }
    }

    if (info.offset.y >= 20) {
      hide();
    }
  };

  return {
    animate: open,
    initial: closed,
    exit: closed,
    transition: { duration: ANIMATION_DURATION_MEDIUM_SEC },
    drag: allowSwipeToClose ? 'y' : undefined,
    style: { touchAction: 'none' },
    onDragEnd: handleDragEnd,
    dragConstraints: { top: 0, bottom: 0 },
  };
}
