import InfiniteScroll from 'react-infinite-scroll-component';
import EpisodeCard from '../EpisodeCard';
import usePagePodcastDetail from '../usePagePodcastDetail';
import usePagePodcastEpisodes from '../usePagePodcastEpisodes';
import styles from './PodcastEpisodes.module.scss';
import PodcastEpisodesSkeleton from './PodcastEpisodesSkeleton';

export interface PodcastEpisodesProps {}

const PodcastEpisodes: React.FC<PodcastEpisodesProps> = () => {
  const { data: podcastDetailsData } = usePagePodcastDetail();
  const {
    isFetched,
    data,
    fetchNextPage,
    hasNextPage = true,
  } = usePagePodcastEpisodes();

  if (!isFetched) {
    return <PodcastEpisodesSkeleton />;
  }

  return (
    <InfiniteScroll
      dataLength={
        data?.pages
          .map((p) => p.episodes.length)
          .reduce((acc, val) => acc + val, 0) ?? 0
      }
      next={fetchNextPage}
      hasMore={hasNextPage}
      loader={<PodcastEpisodesSkeleton />}
    >
      {data?.pages.map((page) =>
        page.episodes.map((episode) => (
          <EpisodeCard
            key={episode.id}
            className={styles.card}
            episodeId={episode.remoteEpisodeId}
            podcastId={podcastDetailsData?.podcastId}
          />
        )),
      )}
    </InfiniteScroll>
  );
};

export default PodcastEpisodes;
