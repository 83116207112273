import { Button, Heading, Spacer } from '@sparemin/blockhead';
import useActiveSlide from 'components/HighlightClipScroller/hooks/useActiveSlide';
import React from 'react';
import { useSwiper } from 'swiper/react';
import { HOMEPAGE_HIGHLIGHT_CLIP_SUBMISSION_FORM } from 'utils/constants';
import styles from './infoSlides.module.scss';

type FooterSlideProps = {
  onView?: (val: number) => void;
};

const FooterSlide: React.FC<FooterSlideProps> = ({ onView }) => {
  const swiper = useSwiper();
  useActiveSlide({ onView: () => onView?.(swiper.activeIndex) });

  return (
    <div className={styles.root}>
      <Spacer
        className={styles.container}
        orientation="vertical"
        align="center"
        justify="center"
      >
        <Heading className={styles.title} level={1}>
          {`You're all caught up!`}
        </Heading>
        <Heading level={2} className={styles.subtitle}>
          Please check back later to see if new clips are available.
        </Heading>
        <Button
          size="large"
          className={styles.link}
          as="a"
          href={HOMEPAGE_HIGHLIGHT_CLIP_SUBMISSION_FORM}
          target="_blank"
          rel="noopener"
        >
          add your podcast to this feed
        </Button>
      </Spacer>
    </div>
  );
};

export default FooterSlide;
