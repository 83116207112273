import { IconButton, IconButtonProps } from '@sparemin/blockhead';
import React from 'react';

interface IconLinkProps extends IconButtonProps {
  url?: string;
  icon: React.ReactNode;
  onClick: () => void;
  label: string;
}

const LinkOutIconButton: React.FC<IconLinkProps> = ({
  url,
  onClick,
  icon,
  label,
  ...rest
}) =>
  url ? (
    <IconButton
      as="a"
      href={url}
      onPress={onClick}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {icon}
    </IconButton>
  ) : (
    <IconButton onPress={onClick} aria-label={label} isDisabled {...rest}>
      {icon}
    </IconButton>
  );
export default LinkOutIconButton;
