import useStaticCallback from 'hooks/useStaticCallback';
import { useCallback, useEffect, useRef } from 'react';

interface UseSingletonRenderValueConfig<V> {
  calculateValue: () => V | undefined;
}

// lazily calculates a value and caches it for a single render
export default function useSingletonRenderValue<V>({
  calculateValue,
}: UseSingletonRenderValueConfig<V>) {
  const valueRef = useRef<V | undefined>();
  const staticCalculateValue = useStaticCallback(calculateValue);

  const getValue = useCallback(() => {
    if (valueRef.current === undefined) {
      valueRef.current = staticCalculateValue();
    }
    return valueRef.current;
  }, [staticCalculateValue]);

  useEffect(() => {
    valueRef.current = undefined;
  });

  return getValue;
}
