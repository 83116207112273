import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PageLocationOptions } from 'types';
import {
  EpisodeLocationKeys,
  PodcastLegacyLocationKeys,
} from 'utils/routes/constants';

interface UseLegacyPageLocationConfig {
  pageType: 'episode' | 'podcast';
}

export interface UseLegacyPageLocationResult extends PageLocationOptions {
  id: number;
}

/*
 * Many different kinds of query params can be added to the url -- this hook should pass
 * any existing search params or state values back along with the the param id converted to a number.
 */
export default function useLegacyPageLocation(
  props: UseLegacyPageLocationConfig,
): UseLegacyPageLocationResult {
  const { pageType } = props;

  const params = useParams();
  const [location] = useState(useLocation());

  const feedId = params[PodcastLegacyLocationKeys.FEED_ID] ?? '';
  const feedEpisodeId = params[EpisodeLocationKeys.EPISODE_ID] ?? '';

  const id = pageType === 'episode' ? feedEpisodeId : feedId;

  return {
    search: location.search,
    state: location.state,
    id: parseInt(id, 10),
  };
}
