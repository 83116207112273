import {
  ToastContainer as ToastifyContainer,
  ToastContainerProps,
} from 'react-toastify';
import styles from './ToastContainer.module.scss';

const ToastContainer: React.FC<ToastContainerProps> = (props) => (
  <ToastifyContainer
    {...props}
    className={styles['toast-container']}
    toastClassName={styles.toast}
    bodyClassName={styles.body}
  />
);

export default ToastContainer;
