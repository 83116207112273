import { AnimatedRouteContainer } from 'components/AnimatedRoute';
import { AppBody } from 'components/AppHeader';
import { PageContents } from 'components/Page';
import Spacer from 'components/Spacer';
import ClaimPodcastFlowContainer from './ClaimPodcastFlowContainer';
import Header from './Header';
import PodcastDescription from './PodcastDescription';
import PodcastMedia from './PodcastMedia';
import { PodcastPageProvider } from './PodcastPageContext';
import PodcastPageMetadata from './PodcastPageMetadata/PodcastPageMetadata';
import usePodcastPage from './usePodcastPage';

export interface PodcastPageProps {}

const PodcastPage: React.FC<PodcastPageProps> = () => {
  const { podcastId } = usePodcastPage();

  const hasData = !!podcastId;

  return !hasData ? null : (
    <PodcastPageProvider podcastId={podcastId}>
      <ClaimPodcastFlowContainer />
      <PageContents as={AnimatedRouteContainer}>
        <PodcastPageMetadata />
        <Header />
        <AppBody>
          <Spacer orientation="vertical" space={2}>
            <PodcastDescription />
            <PodcastMedia />
          </Spacer>
        </AppBody>
      </PageContents>
    </PodcastPageProvider>
  );
};

export default PodcastPage;
