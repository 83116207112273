import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FollowPodcastContextType, FollowPodcastProviderProps } from './types';

const FollowPodcastContext = React.createContext<
  FollowPodcastContextType | undefined
>(undefined);

export const FollowPodcastProvider: React.FC<FollowPodcastProviderProps> = (
  props,
) => {
  const { children } = props;

  const [showPopper, setShowPopper] = useState(false);

  const handleClose = useCallback(() => {
    setShowPopper(false);
  }, []);

  const handleOpen = useCallback(() => {
    setShowPopper(true);
  }, []);
  return (
    <FollowPodcastContext.Provider
      value={useMemo(
        () => ({
          close: handleClose,
          open: handleOpen,
          isOpen: showPopper,
        }),
        [showPopper, handleClose, handleOpen],
      )}
    >
      {children}
    </FollowPodcastContext.Provider>
  );
};

export function useFollowPodcastPrompt() {
  const context = useContext(FollowPodcastContext);

  if (context === undefined) {
    throw new Error(
      'useFollowPodcast must be used within a FollowPodcastProvider',
    );
  }

  return context;
}
