import { createAuthHeader, TokenResponse } from '@sparemin/auth';
import { SOURCE_APPLICATION_NAME } from 'api/constants';
import { AUTH_USER_SERVICE_URL } from 'config';
// eslint-disable-next-line import/no-cycle
import { request } from '../http';
import { createFormattedAuthHeader } from '../utils';
import { UserProfile, GetUserProfileArgs } from './types';

export async function getUserProfile(args: GetUserProfileArgs) {
  // during authentication, getUserProfile is called to access the
  // user's email address, before the sparemin token has been added to
  // the authentication context. If the token is passed in as an argument,
  // the endpoint sets the headers itself, otherwise it is set by the interceptors.

  if (args.token) {
    const { data } = await request.get<UserProfile>(
      `${AUTH_USER_SERVICE_URL}/api/v1/user/${args.userId}/profile`,
      createFormattedAuthHeader(createAuthHeader(args.token, false)),
    );
    return data;
  }

  const { data } = await request.get<UserProfile>(
    `${AUTH_USER_SERVICE_URL}/api/v1/user/${args.userId}/profile`,
  );
  return data;
}

export async function renewToken() {
  const { data } = await request.post<TokenResponse>(
    `${AUTH_USER_SERVICE_URL}/api/v1/auth/token`,
    { application: SOURCE_APPLICATION_NAME },
  );
  return data;
}
