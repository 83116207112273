import { Thumbnail, Spacer, Dialog, useToggle } from '@sparemin/blockhead';
import cn from 'classnames';
import Card from 'components/Card';
import OpenSponsoredEpisodeModalButton from 'components/OpenSponsoredEpisodeModalButton';
import { motion, AnimatePresence } from 'framer-motion';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import useNowPlayingCard from 'pages/AudioPlayerPage/state/NowPlayingCardContext';
import PlaybackTime from 'pages/AudioPlayerPage/StickyAudioPlayer/PlaybackTime';
import ProgressBar from 'pages/AudioPlayerPage/StickyAudioPlayer/ProgressBar';
import QueueButton from 'pages/AudioPlayerPage/StickyAudioPlayer/QueueButton';
import React from 'react';
import { useGetEpisodeDetails } from '../state';
import PlaybackRateMenu from '../StickyAudioPlayer/PlaybackRateMenu';
import EpisodeTitle from './EpisodeTitle';
import styles from './MobileNowPlayingCard.module.scss';
import NowPlayingCardControls from './NowPlayingCardControls';
import PodcastTitle from './PodcastTitle';
import useMobileNowPlayingCardAnimation from './useMobileNowPlayingCardAnimation';

export type MobileNowPlayingCardProps = {};

const MobileNowPlayingCard: React.FC<MobileNowPlayingCardProps> = () => {
  const { show, hide } = useNowPlayingCard();

  const {
    value: adjustingPlayback,
    toggleOn: onAdjustingPlayback,
    toggleOff: onAfterAdjustingPlayback,
  } = useToggle(false);

  const { chapters, podcastId, episodeId, isEpisodeSponsored } =
    useAudioPlayer();

  const { data } = useGetEpisodeDetails({ podcastId, episodeId });

  const sliderClassName = 'slider';

  const animation = useMobileNowPlayingCardAnimation({
    allowSwipeToClose: !adjustingPlayback,
    blockSwipeToCloseClassName: sliderClassName,
  });

  const {
    value: showDialog,
    toggleOn: openDialog,
    toggleOff: closeDialog,
  } = useToggle(false);

  if (show && !showDialog) {
    openDialog();
  }

  return (
    <Dialog
      isOpen={showDialog}
      isDismissable
      onClose={hide}
      className={styles.dialog}
      backdropClassName={styles.backdrop}
    >
      <AnimatePresence onExitComplete={closeDialog}>
        {show && (
          <motion.div className={styles.root} key="nowPlaying" {...animation}>
            <Card className={styles.card}>
              <Card.BackgroundImage
                alt="card background image"
                src={data?.thumbnailUrl}
                blurValue="30px"
              />
              <div className={styles.contents}>
                <Thumbnail
                  src={data?.thumbnailUrl}
                  alt="episode art"
                  ratio={1}
                  className={styles.artwork}
                />
                <Spacer align="center" justify="center" space="16px">
                  <Spacer space="3px" orientation="vertical">
                    <PodcastTitle />
                    <EpisodeTitle />
                  </Spacer>
                  {isEpisodeSponsored && (
                    <span>
                      <OpenSponsoredEpisodeModalButton />
                    </span>
                  )}
                </Spacer>
                <Spacer
                  orientation="vertical"
                  space="14px"
                  className={cn(styles.playback, styles.fullwidth)}
                >
                  <ProgressBar
                    className={sliderClassName}
                    chapters={chapters}
                    handleClassName={styles.handle}
                    onAdjustPlayback={onAdjustingPlayback}
                    onAfterAdjustPlayback={onAfterAdjustingPlayback}
                  />
                  <PlaybackTime className={cn(styles.time, styles.fullwidth)} />
                </Spacer>
                <NowPlayingCardControls />
                <Spacer justify="space-evenly" className={styles.fullwidth}>
                  <PlaybackRateMenu />
                  <QueueButton />
                </Spacer>
              </div>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </Dialog>
  );
};
export default MobileNowPlayingCard;
