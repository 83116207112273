import {
  BaseHeadingProps,
  Heading,
  Link as BlockheadLink,
} from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import { Link, NavigateOptions } from 'react-router-dom';
import { Path } from 'utils/routes/types';
import styles from './HeaderTextLink.module.scss';

type HeaderTextLinkProps = {
  className?: string;
  linkClassName?: string;
  level: BaseHeadingProps['level'];
  path: string | Path;
  pathOpts?: NavigateOptions;
  onPress?: () => void;
  title: string;
};

const HeaderTextLink: React.FC<HeaderTextLinkProps> = ({
  className,
  linkClassName,
  level,
  path,
  pathOpts,
  onPress,
  title,
}) => (
  <Heading level={level} className={cn(styles.root, className)}>
    <BlockheadLink
      as={Link}
      to={path}
      state={pathOpts}
      onPress={onPress}
      className={linkClassName}
    >
      {title}
    </BlockheadLink>
  </Heading>
);
export default HeaderTextLink;
