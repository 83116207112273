import cn from 'classnames';
import Card, { CardProps } from 'components/Card';
import { Link, LinkProps } from 'react-router-dom';

import styles from './LinkCard.module.scss';

export interface LinkCardProps extends Omit<CardProps, 'onClick'>, LinkProps {
  cardClassName?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

interface LinkCardComponent extends React.FC<LinkCardProps> {
  BackgroundImage: typeof Card.BackgroundImage;
  Body: typeof Card.Body;
  Footer: typeof Card.Footer;
  Header: typeof Card.Header;
}

const LinkCard: LinkCardComponent = ({
  cardClassName,
  children,
  className,
  disabled,
  variant,
  ...linkProps
}) => (
  <Link className={cn(styles.root, className)} {...linkProps}>
    <Card
      className={cn(
        styles.card,
        [styles[`card__${variant}`]],
        {
          [styles.card__disabled]: disabled,
        },
        cardClassName,
      )}
      variant={variant}
    >
      {children}
    </Card>
  </Link>
);

LinkCard.BackgroundImage = Card.BackgroundImage;
LinkCard.Body = Card.Body;
LinkCard.Footer = Card.Footer;
LinkCard.Header = Card.Header;

export default LinkCard;
