import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface ClaimPodcastQueryParams {
  claimStatus: string | null;
  owner: string | null;
}
export default function useClaimPodcastQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState<ClaimPodcastQueryParams>({
    claimStatus: null,
    owner: null,
  });

  useEffect(() => {
    if (searchParams.has('claim-status')) {
      const claimStatus = searchParams.get('claim-status');
      const owner = searchParams.get('requester-email');

      setParams({ claimStatus, owner });

      searchParams.delete('claim-status');
      searchParams.delete('requester-email');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return params;
}
