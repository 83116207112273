import { MediaActionButton, ThemeProvider } from '@sparemin/blockhead';
import { motion } from 'framer-motion';
import React from 'react';
import { useSwiperSlide } from 'swiper/react';
import { VolumeButtonProps } from './types';

const VolumeButton: React.FC<VolumeButtonProps> = ({
  onAnimationComplete,
  onPress,
  state,
  visible,
}) => {
  const { isActive } = useSwiperSlide();

  return !isActive ? null : (
    <ThemeProvider theme="light">
      <MediaActionButton
        containerAs={motion.div}
        containerProps={{
          animate: { opacity: visible ? 1 : 0 },
          initial: { opacity: 0 },
          onAnimationComplete,
          transition: { duration: 0.3 },
        }}
        onPress={onPress}
        state={state}
        tabIndex={-1}
      />
    </ThemeProvider>
  );
};

export default VolumeButton;
