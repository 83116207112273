import React from 'react';
import { Navigate } from 'react-router-dom';
import { createEpisodePath, createRedirectPath } from 'utils/routes';
import useEpisodeLegacyPage from './useEpisodeLegacyPage';

const EpisodeLegacyPage: React.FunctionComponent = () => {
  const { episodeId, podcastId, search, state } = useEpisodeLegacyPage();

  const hasData = !!episodeId && !!podcastId;

  if (!hasData) {
    return null;
  }

  const [path, { state: newState, replace }] = createRedirectPath({
    destination: createEpisodePath(episodeId, podcastId),
    search,
    state,
  });

  return <Navigate to={path} state={newState} replace={replace} />;
};

export default EpisodeLegacyPage;
