import { AppHeaderAnimationProvider } from 'components/AppHeader';
import { AnimationProvider } from './AnimationContext';
import Header from './Header';

export interface HeaderContainerProps {}

const HeaderContainer: React.FC<HeaderContainerProps> = () => (
  <AppHeaderAnimationProvider>
    <AnimationProvider>
      <Header />
    </AnimationProvider>
  </AppHeaderAnimationProvider>
);

export default HeaderContainer;
