import { range } from 'utils/math';
import styles from './Segment.module.scss';

export interface SegmentProps {
  endValue: number;
  max: number;
  min: number;
  startValue: number;
  value: number;
}

const Segment: React.FC<SegmentProps> = ({
  endValue,
  min,
  max,
  startValue,
  value,
}) => {
  const size = endValue - startValue;

  return (
    <div
      className={styles.rail}
      role="presentation"
      style={{
        ['--left' as any]: `${range(min, max, 0, 100, startValue)}%`,
        ['--width' as any]: `${range(min, max, 0, 100, size)}%`,
      }}
    >
      <div
        className={styles.track}
        style={{
          ['--width' as any]: `${range(startValue, endValue, 0, 100, value)}%`,
        }}
      />
    </div>
  );
};

export default Segment;
