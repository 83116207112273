import React from 'react';
import { AudioPlayerControlsProvider } from '../state';
import NextChapterButton from './NextChapterButton';
import PlayButton from './PlayButton';
import PreviousChapterButton from './PreviousChapterButton';
import SkipBackButton from './SkipBackButton';
import SkipForwardButton from './SkipForwardButton';

interface ControlsProps {
  children: React.ReactNode;
}

interface ControlsComponent extends React.FC<ControlsProps> {
  NextChapterButton: typeof NextChapterButton;
  PlayButton: typeof PlayButton;
  PreviousChapterButton: typeof PreviousChapterButton;
  SkipForwardButton: typeof SkipForwardButton;
  SkipBackButton: typeof SkipBackButton;
}

const Controls: ControlsComponent = ({ children }) => (
  <AudioPlayerControlsProvider>{children}</AudioPlayerControlsProvider>
);

Controls.NextChapterButton = NextChapterButton;
Controls.PlayButton = PlayButton;
Controls.PreviousChapterButton = PreviousChapterButton;
Controls.SkipBackButton = SkipBackButton;
Controls.SkipForwardButton = SkipForwardButton;

export default Controls;
