import WhenNotPreRendering from 'components/WhenNotPreRendering';
import React from 'react';

const GoogleAnalytics = React.lazy(() => import('./GoogleAnalytics'));

export interface GoogleAnalyticsContainerProps {}

const GoogleAnalyticsContainer: React.FC<
  GoogleAnalyticsContainerProps
> = () => (
  <WhenNotPreRendering>{() => <GoogleAnalytics />}</WhenNotPreRendering>
);

export default GoogleAnalyticsContainer;
