import { useEventTracking, PlayerLinkType } from 'context/EventTrackingContext';
import { useCallback } from 'react';
import { useAudioPlayer } from './AudioPlayerContext';

export default function useTrackPlayerLinkClick(linkType: PlayerLinkType) {
  const { isEpisodeSponsored, sponsoredEpisodeWidgetInfo } = useAudioPlayer();
  const { trackPersistentPlayerLinkClick, trackSponsoredEpisodeClickThrough } =
    useEventTracking();

  const handleLinkClick = useCallback(() => {
    trackPersistentPlayerLinkClick(linkType);
    if (isEpisodeSponsored && sponsoredEpisodeWidgetInfo) {
      trackSponsoredEpisodeClickThrough(sponsoredEpisodeWidgetInfo);
    }
  }, [
    isEpisodeSponsored,
    linkType,
    sponsoredEpisodeWidgetInfo,
    trackPersistentPlayerLinkClick,
    trackSponsoredEpisodeClickThrough,
  ]);

  return handleLinkClick;
}
