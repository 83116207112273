import cn from 'classnames';
import Spacer from 'components/Spacer';
import { AppHeaderDescriptionProvider } from '../AppHeaderDescriptionContext';
import styles from './AppHeaderDescription.module.scss';

export interface AppHeaderDescriptionProps {
  children?: React.ReactNode;
  className?: string;
  footer: React.ReactNode;
  isLoading?: boolean;
  subtitle: React.ReactNode;
  style?: React.CSSProperties;
  title: React.ReactNode;
}

const AppHeaderDescription: React.FC<AppHeaderDescriptionProps> = ({
  children,
  className,
  footer,
  isLoading,
  subtitle,
  style,
  title,
}) => (
  <AppHeaderDescriptionProvider isLoading={!!isLoading}>
    <Spacer align="center" className={cn(styles.root, className)} style={style}>
      <div className={styles.textContainer}>
        {title}
        {subtitle}
        {footer}
      </div>
      {children}
    </Spacer>
  </AppHeaderDescriptionProvider>
);
export default AppHeaderDescription;
