import Article from 'components/Article';
import { PageContents } from 'components/Page';
import React from 'react';
import styles from './EpisodePage.module.scss';
import { EpisodePageProvider } from './EpisodePageContext';
import EpisodeParts from './EpisodeParts';
import Header from './Header';

type EpisodePageSkeletonProps = {};

const EpisodePageSkeleton: React.FC<EpisodePageSkeletonProps> = () => (
  <PageContents className={styles.root}>
    <EpisodePageProvider episodeId="" podcastId="" source={undefined}>
      <Header />
      <Article>
        <EpisodeParts className={styles.skeletonContents} />
      </Article>
    </EpisodePageProvider>
  </PageContents>
);

export default EpisodePageSkeleton;
