import { useGetPodcastTitle } from 'api/hooks/useGetPodcastDetail';
import HeaderTextLink from 'components/HeaderTextLink';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import useNowPlayingCard from 'pages/AudioPlayerPage/state/NowPlayingCardContext';
import useTrackPlayerLinkClick from 'pages/AudioPlayerPage/state/useTrackPlayerLinkClick';
import React, { useCallback } from 'react';
import { createPodcastPath } from 'utils/routes';
import styles from './PodcastTitle.module.scss';

type PodcastTitleProps = {};

const PodcastTitle: React.FC<PodcastTitleProps> = () => {
  const { podcastId } = useAudioPlayer();
  const { data: podcastTitle } = useGetPodcastTitle(podcastId);
  const { hide } = useNowPlayingCard();
  const trackLinkClick = useTrackPlayerLinkClick('PodcastTitle');

  const [path = '', pathOpts] = !podcastId ? [] : createPodcastPath(podcastId);

  const handlePodcastLinkClick = useCallback(() => {
    trackLinkClick();
    hide();
  }, [hide, trackLinkClick]);

  return (
    <HeaderTextLink
      className={styles.root}
      linkClassName={styles.link}
      level={2}
      path={path}
      pathOpts={pathOpts}
      title={podcastTitle ?? ''}
      onPress={handlePodcastLinkClick}
    />
  );
};
export default PodcastTitle;
