import { useEventTracking } from 'context/EventTrackingContext';
import { mapValues } from 'lodash';
import { useExperiments } from './useExperiments';

export default function useRegisterExperiments() {
  const { trackExperiments } = useEventTracking();

  useExperiments({
    onSuccess: (data) => {
      const experimentVariants = mapValues(data, 'variantName');
      trackExperiments(experimentVariants);
    },
  });
}
