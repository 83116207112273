import { throttle } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import useStaticCallback from './useStaticCallback';

export interface UseKeyHandlerConfig {
  handledCodes?: string[];
  callback: (e: React.KeyboardEvent<any>) => void;
  timeoutMillis?: number;
}

export default function useKeyHandler({
  callback,
  handledCodes,
  timeoutMillis = 100,
}: UseKeyHandlerConfig) {
  const staticCallback = useStaticCallback(callback);
  const throttledHandler = useMemo(
    () => throttle(staticCallback, timeoutMillis),
    [staticCallback, timeoutMillis],
  );
  const handleKeyEvent = useCallback(
    (e: React.KeyboardEvent<any>) => {
      if (handledCodes) {
        if (handledCodes.includes(e.code)) {
          throttledHandler(e);
        }
      } else {
        throttledHandler(e);
      }
    },
    [handledCodes, throttledHandler],
  );

  return handleKeyEvent;
}
