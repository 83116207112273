import useToggle from 'hooks/useToggle';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { VOLUME } from './constants';
import {
  HighlightClipScrollerContextType,
  HighlightClipScrollerProviderProps,
} from './types';

const HighlightClipScrollerContext = React.createContext<
  HighlightClipScrollerContextType | undefined
>(undefined);

export const HighlightClipScrollerProvider: React.FC<
  HighlightClipScrollerProviderProps
> = ({ children }) => {
  const { controls, events } = useAudioPlayer();

  const {
    value: unmuteOnInteraction,
    toggleOff: toggleOffUnmuteOnInteraction,
  } = useToggle(true);

  const [volume, setVolume] = useState(VOLUME.muted);

  const handleMute = useCallback(() => {
    setVolume(VOLUME.muted);
  }, []);

  const handleUnmute = useCallback(() => {
    setVolume(VOLUME.unmuted);
    // if the persistent player is playing when the HighlightClipAudio unmutes, pause it

    controls?.pause();
  }, [controls]);

  const handleToggleMute = useCallback(() => {
    const newVolume = volume > VOLUME.muted ? VOLUME.muted : VOLUME.unmuted;
    if (newVolume === VOLUME.muted) {
      handleMute();
    } else {
      handleUnmute();
    }
    return newVolume;
  }, [volume, handleMute, handleUnmute]);

  // the HighlightClipScroller should listen for play events from the persistent
  // player and mute.
  useEffect(() => {
    events.registerEventListener('play', handleMute);

    return () => {
      events.unregisterEventListener('play', handleMute);
    };
  }, [events, handleMute]);

  return (
    <HighlightClipScrollerContext.Provider
      value={useMemo(
        () => ({
          mute: handleMute,
          toggleMute: handleToggleMute,
          unmute: handleUnmute,
          volume,
          unmuteOnInteraction,
          toggleOffUnmuteOnInteraction,
        }),
        [
          handleMute,
          handleToggleMute,
          handleUnmute,
          volume,
          unmuteOnInteraction,
          toggleOffUnmuteOnInteraction,
        ],
      )}
    >
      {children}
    </HighlightClipScrollerContext.Provider>
  );
};

export function useHighlightClipScroller() {
  const context = useContext(HighlightClipScrollerContext);

  if (context === undefined) {
    throw new Error(
      'useHighlightClipScroller must be used within HighlightClipScrollerProvider',
    );
  }

  return context;
}
