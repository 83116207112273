import useProfile from 'api/hooks/useProfile';
import RegisterButton from 'components/RegisterButton';
import { useAuth } from 'context/AuthContext';
import { useModalControls } from 'context/ModalContext';
import UserAccount from './UserAccount';

export type UserAccountControlProps = {};

const UserAccountControl: React.FC<UserAccountControlProps> = () => {
  const { isAuthenticated, logout } = useAuth();
  const modalName = 'Auth';
  const { open } = useModalControls();
  const { data } = useProfile();

  const email = data?.email;

  if (isAuthenticated && email) {
    return <UserAccount onLogout={logout} email={email} />;
  }
  return <RegisterButton onPress={() => open({ name: modalName })} />;
};
export default UserAccountControl;
