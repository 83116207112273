import { Spacer } from '@sparemin/blockhead';
import { HighlightVideoClip } from 'api/podcast';
import cn from 'classnames';
import { useBreakpoints } from 'context/BreakpointsContext';
import { HighlightClipSource } from 'context/EventTrackingContext/types';
import React from 'react';
import styles from './HighlightClip.module.scss';
import HighlightClipInfo from './HighlightClipInfo';
import { HighlightClipInfoProps } from './HighlightClipInfo/HighlightClipInfo';
import HighlightClipInfoOverlay from './HighlightClipInfoOverlay';
import HighlightClipPlayer from './HighlightClipPlayer';

export interface HighlightClipProps
  extends Pick<HighlightClipInfoProps, 'onContinueListening'> {
  clip?: HighlightVideoClip;
  className?: string;
  source: HighlightClipSource;
}

const HighlightClip: React.FC<HighlightClipProps> = ({
  clip,
  onContinueListening,
  className,
  source,
}) => {
  const { md } = useBreakpoints();

  return (
    <Spacer className={cn(styles.root, className)} space="30px">
      {md ? (
        <div className={styles.playerContainer}>
          <HighlightClipPlayer clip={clip} source={source} />
          <HighlightClipInfoOverlay
            {...{ clip, onContinueListening, source }}
          />
        </div>
      ) : (
        <>
          <div className={styles.playerContainer}>
            <HighlightClipPlayer clip={clip} source={source} />
          </div>
          <HighlightClipInfo {...{ clip, onContinueListening, source }} />
        </>
      )}
    </Spacer>
  );
};

export default HighlightClip;
