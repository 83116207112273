import { omit } from 'lodash';
import { getObject, setObject } from 'utils/browserStorage/localStorage';
import { LISTENING_PROGRESS_KEY } from 'utils/constants';

function getListeningProgress() {
  return getObject<Record<string, number>>(LISTENING_PROGRESS_KEY);
}

export function getEpisodeListeningProgress(episodeId: string) {
  return getListeningProgress()?.[episodeId];
}

function saveListeningProgress(val: Record<string, number>) {
  setObject(LISTENING_PROGRESS_KEY, val);
}

export function saveEpisodeListeningProgress(
  episodeId: string,
  timeInSec: number,
) {
  const episodeProgress = getListeningProgress() ?? {};

  episodeProgress[episodeId] = timeInSec;
  saveListeningProgress(episodeProgress);
}

export function deleteEpisodeFromListeningProgress(episodeId: string) {
  const episodeProgress = getListeningProgress() ?? {};
  saveListeningProgress(omit(episodeProgress, episodeId));
}
