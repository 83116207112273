import { PUBLIC_URL } from 'config';
import { Helmet } from 'react-helmet-async';
import { getTextContentFromHtmlString } from 'utils/dom';
import { useEpisodeDetails } from '../state';

export interface EpisodePageMetadataProps {}

const EpisodePageMetadata: React.FC<EpisodePageMetadataProps> = () => {
  const { data } = useEpisodeDetails();

  const description =
    data?.descriptionHtml && getTextContentFromHtmlString(data.descriptionHtml);
  const title = data && `${data.podcastTitle}: ${data.episodeTitle}`;

  return !data ? null : (
    <Helmet>
      <title>{data.episodeTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={data.discoBackdropImageUrl} />
      <meta property="og:image:alt" content={title} />

      <meta name="twitter:card" content="player" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={data.thumbnailImageUrl} />
      <meta name="twitter:image:alt" content={title} />
      <meta
        name="twitter:player"
        content={`${PUBLIC_URL}/player.html?src=${data.originalAudioUrl}`}
      />
      <meta name="twitter:player:width" content="480" />
      <meta name="twitter:player:height" content="75" />
    </Helmet>
  );
};

export default EpisodePageMetadata;
