import useTimeout from 'hooks/useTimeout';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAudioPlayer } from '../AudioPlayerContext';
import {
  AudioPlayerControlsContextType,
  AudioPlayerControlsProviderProps,
} from './types';

const AudioPlayerControlsContext = React.createContext<
  AudioPlayerControlsContextType | undefined
>(undefined);

export const AudioPlayerControlsProvider: React.FC<
  AudioPlayerControlsProviderProps
> = ({ children }) => {
  const { loading } = useAudioPlayer();
  const [delayedLoading, setDelayedLoading] = useState(false);
  const [startTimer, cancelTimer] = useTimeout({
    callback: () => setDelayedLoading(true),
    delay: 200,
  });

  useEffect(() => {
    if (loading) {
      startTimer();
    } else {
      setDelayedLoading(false);
    }

    return cancelTimer;
  }, [cancelTimer, loading, startTimer]);

  return (
    <AudioPlayerControlsContext.Provider
      value={useMemo(() => ({ loading: delayedLoading }), [delayedLoading])}
    >
      {children}
    </AudioPlayerControlsContext.Provider>
  );
};

export function useAudioPlayerControls() {
  const context = useContext(AudioPlayerControlsContext);

  if (context === undefined) {
    throw new Error(
      'useAudioPlayerControls must be used within AudioPlayerControlsContext',
    );
  }

  return context;
}
