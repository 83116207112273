import {
  AspectRatio,
  Thumbnail,
  ThumbnailProps,
  Heading,
  Spacer,
} from '@sparemin/blockhead';
import { CardVariant } from 'components/Card/types';
import LinkCard from 'components/LinkCard';
import LockedThumbnail from 'components/LockedThumbnail';
import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getClassNameWithVariant } from 'utils/styles';

import styles from './EpisodeCardHeader.module.scss';

interface EpisodeCardHeaderProps {
  isLoading?: boolean;
  podcastName?: string;
  thumbnailImageUrl?: string;
  title?: string;
  variant?: CardVariant;
  isBlocked?: boolean;
  badge?: React.ReactNode;
}

const EpisodeCardHeader: React.FunctionComponent<EpisodeCardHeaderProps> = (
  props,
) => {
  const {
    isLoading = false,
    podcastName,
    thumbnailImageUrl = '',
    title = '',
    variant = 'default',
    isBlocked = false,
    badge,
  } = props;

  const TitleComponent = React.useMemo(
    () => (
      <Spacer justify="space-between" className={styles.titleContainer}>
        <Spacer orientation="vertical" space="4px">
          <Heading
            className={getClassNameWithVariant(styles, 'subtitle', variant)}
            level={2}
          >
            {isLoading ? (
              <Skeleton width="100px" style={{ maxWidth: '100%' }} />
            ) : (
              podcastName
            )}
          </Heading>
          <Heading
            className={getClassNameWithVariant(styles, 'title', variant)}
            level={5}
          >
            {isLoading ? (
              <Skeleton width="400px" style={{ maxWidth: '100%' }} />
            ) : (
              title
            )}
          </Heading>
        </Spacer>
        {badge && <span>{badge}</span>}
      </Spacer>
    ),
    [isLoading, podcastName, title, variant, badge],
  );

  const ThumbnailComponent = useMemo(() => {
    const thumbnailProps: ThumbnailProps = {
      alt: 'episode artwork',
      basis: 'width',
      className: getClassNameWithVariant(styles, 'thumbnail', variant),
      ratio: 1,
      src: thumbnailImageUrl,
    };

    if (isLoading) {
      return (
        <AspectRatio className={styles.thumbnailRatio} ratio={1}>
          <Skeleton
            className={styles.thumbnailSkeleton}
            containerClassName={styles.thumbnailSkeletonContainer}
          />
        </AspectRatio>
      );
    }
    if (isBlocked) {
      return <LockedThumbnail {...thumbnailProps} size="small" />;
    }

    return <Thumbnail {...thumbnailProps} />;
  }, [isLoading, thumbnailImageUrl, variant, isBlocked]);

  return <LinkCard.Header icon={ThumbnailComponent} title={TitleComponent} />;
};

export default EpisodeCardHeader;
