import { IconButton, IconButtonProps, ShareIcon } from '@sparemin/blockhead';
import React from 'react';

export interface ShareButtonProps
  extends Omit<IconButtonProps, 'size' | 'color' | 'variant'> {}

const ShareButton: React.FC<ShareButtonProps> = (props) => (
  <IconButton {...props} size="large" color="dark" variant="frosted">
    <ShareIcon height="16px" width="16px" />
  </IconButton>
);
export default ShareButton;
