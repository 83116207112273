import { useStaticCallback } from '@sparemin/blockhead';
import { useEffect } from 'react';
import { BaseAudioPlayer } from 'utils/audio/types';

interface UseEpisodeCompleteOptions {
  onComplete?: () => void;
  player?: BaseAudioPlayer;
}

export default function useEpisodeComplete({
  onComplete,
  player,
}: UseEpisodeCompleteOptions) {
  const staticOnComplete = useStaticCallback(onComplete);

  useEffect(() => {
    if (!player) {
      return undefined;
    }

    player.on('ended', staticOnComplete);

    return () => player.off('ended', staticOnComplete);
  }, [player, staticOnComplete]);
}
