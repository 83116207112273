import useGetPodcastDetail from 'api/hooks/useGetPodcastDetail';
import { useHighlightVideoClips } from 'api/hooks/useHighlightVideoClips';
import { is404 } from 'api/utils';
import { useEventTracking } from 'context/EventTrackingContext';
import { useRoutingError } from 'pages/RoutingErrorPage';
import { useEffect, useMemo } from 'react';
import usePodcastPageLocation from './usePodcastPageLocation';

interface UsePodcastPageResult {
  podcastId: string;
}

export default function usePodcastPage(): UsePodcastPageResult {
  const { setRoutingError } = useRoutingError();
  const { podcastId } = usePodcastPageLocation();
  const { trackPodcastPageLoad } = useEventTracking();

  const { data: podcastData } = useGetPodcastDetail(podcastId, {
    onError: (err) => {
      if (is404(err)) {
        setRoutingError(404);
      }
    },
  });

  const { data } = useHighlightVideoClips(podcastData?.pages[0]?.podcastFeedId);

  const hasHighlightClips = useMemo(() => {
    if (!data?.pages) {
      return undefined;
    }

    return data.pages.flatMap((p) => p.videoClips).length > 0;
  }, [data?.pages]);

  useEffect(() => {
    if (!podcastId) {
      setRoutingError(404);
    }
  }, [podcastId, setRoutingError]);

  useEffect(() => {
    if (hasHighlightClips !== undefined) {
      trackPodcastPageLoad(hasHighlightClips);
    }
  }, [hasHighlightClips, trackPodcastPageLoad]);

  return { podcastId };
}
