import { useCallback, useMemo } from 'react';
import { BaseAudioPlayer } from 'utils/audio/types';
import { AudioPlayerEvents } from './types';

/*
 * This hook adds/removes event handlers from the audio player without the parent
 * having to interact with the BaseAudioPlayer
 */
export default function useAudioPlayerEvents(
  player: BaseAudioPlayer | undefined,
): AudioPlayerEvents {
  const registerEventListener = useCallback(
    <T extends keyof HTMLMediaElementEventMap>(
      type: T,
      listener: (
        this: HTMLMediaElement,
        ev: HTMLMediaElementEventMap[T],
      ) => any,
      opts?: boolean | AddEventListenerOptions,
    ) => player?.on(type, listener, opts),
    [player],
  );

  const unregisterEventListener = useCallback(
    <T extends keyof HTMLMediaElementEventMap>(
      type: T,
      listener: (
        this: HTMLMediaElement,
        ev: HTMLMediaElementEventMap[T],
      ) => any,
      opts?: boolean | AddEventListenerOptions,
    ) => player?.off(type, listener, opts),
    [player],
  );

  return useMemo(
    () => ({
      registerEventListener,
      unregisterEventListener,
    }),
    [registerEventListener, unregisterEventListener],
  );
}
