import { useFollowPodcastPrompt } from 'context/FollowPodcastContext';
import useStaticCallback from 'hooks/useStaticCallback';
import { useEpisodeAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext/EpisodeAudioPlayerContext';
import { useEffect } from 'react';

const useChangeEpisode = () => {
  const { close } = useFollowPodcastPrompt();
  const { episodeId } = useEpisodeAudioPlayer();
  const handleClose = useStaticCallback(close);

  useEffect(() => {
    handleClose();
  }, [episodeId, handleClose]);
};

export default useChangeEpisode;
