import { Spacer } from '@sparemin/blockhead';
import { Text } from 'components/Typography';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { useEffect, useState } from 'react';
import { formatDuration } from 'utils/time';

export interface PlaybackTimeProps {
  className?: string;
}

const PlaybackTime: React.FC<PlaybackTimeProps> = ({ className }) => {
  const { controls, duration, events } = useAudioPlayer();
  const [, setTick] = useState(0);

  const { md } = useBreakpoints();

  useEffect(() => {
    const handleTimeupdate = () => setTick((t) => t + 1);

    events.registerEventListener('timeupdate', handleTimeupdate);

    return () => {
      events.unregisterEventListener('timeupdate', handleTimeupdate);
    };
  }, [events]);

  if (!md) {
    return (
      <div className={className}>
        <Text type="mono">
          {formatDuration(controls?.currentTime(), 'zero')} |{' '}
          {formatDuration(duration, 'unknown')}
        </Text>
      </div>
    );
  }
  return (
    <Spacer justify="space-between" className={className}>
      <Text type="mono">{formatDuration(controls?.currentTime(), 'zero')}</Text>
      <Text type="mono">{formatDuration(duration, 'unknown')}</Text>
    </Spacer>
  );
};

export default PlaybackTime;
