import BaseEpisodeCard, {
  EpisodeCardProps as BaseEpisodeCardProps,
} from 'components/EpisodeCard';
import { usePagePodcastEpisode } from '../usePagePodcastEpisodes';

export interface EpisodeCardProps
  extends Pick<BaseEpisodeCardProps, 'className'> {
  episodeId?: string;
  podcastId?: string;
}

const EpisodeCard: React.FC<EpisodeCardProps> = (props) => {
  const { className, episodeId, podcastId = '' } = props;

  const { episode } = usePagePodcastEpisode(episodeId);

  return (
    <BaseEpisodeCard
      {...{ className, episode, podcastId }}
      pageType="PodcastPage"
    />
  );
};

export default EpisodeCard;
